import { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';

import { Colors, darkColors } from '@lichtblick/theme';
import { TrackingData, useTracker } from '@lichtblick/tracker';

import {
  Background,
  Container,
  Content,
  Frame,
  StyledLink,
  Media,
  StyledChip,
  StyledHeadline,
  StyledText,
} from './TeaserItem.styles';

type LinkProps = {
  href?: string;
  isDownloadLink?: boolean;
  shouldOpenInNewTab?: boolean;
};

export type TeaserItemProps = PropsWithChildren<{
  backgroundColor?: Colors;
  children: ReactNode;
  chip?: string;
  className?: string;
  frameColor?: Colors;
  headline?: string;
  isTextOutside?: boolean;
  link?: LinkProps;
  media?: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  text?: string | ReactNode;
  tracking?: TrackingData;
}>;

export const TeaserItem: FC<TeaserItemProps> = ({
  backgroundColor = Colors.White,
  children,
  chip,
  className,
  frameColor,
  headline,
  isTextOutside = false,
  link,
  media,
  onClick,
  text,
  tracking,
}) => {
  const { trackCustomEvent } = useTracker();

  const trackEvent = (trackingData?: TrackingData) => {
    trackCustomEvent(trackingData);

    if (link?.href?.includes('onelink')) {
      trackCustomEvent(
        {
          eventName: 'app_link',
          customProps: {
            link_type: 'Onelink',
          },
        },
        undefined,
        false,
      );
    }
  };

  const content = (
    <Content $color={darkColors.includes(backgroundColor) ? Colors.White : Colors.Black} $isTextOutside={isTextOutside}>
      {chip && <StyledChip label={chip} />}
      {headline && <StyledHeadline size="S">{headline}</StyledHeadline>}
      {text && <StyledText>{text}</StyledText>}
      {!isTextOutside && children}
    </Content>
  );

  return (
    <StyledLink
      className={className}
      data-testid="teaser-item"
      download={link?.isDownloadLink}
      href={link?.href ?? '#'}
      onClick={(event) => {
        trackEvent(tracking);
        onClick?.(event);
      }}
      target={link?.shouldOpenInNewTab ? '_blank' : undefined}
    >
      {!isTextOutside && <Background $backgroundColor={backgroundColor} />}
      <Container>
        <Frame $color={frameColor} />
        {media && <Media $isTextOutside={isTextOutside}>{media}</Media>}
        {!isTextOutside && content}
      </Container>
      {isTextOutside && content}
    </StyledLink>
  );
};
