import { UcDomains } from '@usercentrics/cmp-browser-sdk/dist/src/interfaces';

import { FallbackImplementation } from './FallbackImplementation';
import { reloadOnWithdrawal } from './reloadOnWithdrawal';
import { UcUiImplementation } from './UcUiImplementation';
import {
  CONSENT_STATUS_CHANGE,
  ConsentState,
  UC_UI_INITIALIZED,
  UserCentricsServiceName,
  UsercentricsSingleton,
} from './UsercentricsSingleton.types';

export class UsercentricsSingletonImpl implements UsercentricsSingleton {
  private fallbackInstance = new FallbackImplementation();
  private initializationPromise?: Promise<void>;
  private ucInstance = new UcUiImplementation();
  private useLocal = false;

  constructor() {
    this.init();
  }

  getConsentStatus(serviceName: string, changeEvent?: CustomEvent): boolean | undefined {
    return this.getInstance().getConsentStatus(serviceName, changeEvent);
  }

  getControllerId(): string | undefined {
    return this.getInstance().getControllerId();
  }

  async init(): Promise<void> {
    if (!this.initializationPromise) {
      this.initializationPromise = this.ucInstance.init().catch(() => this.initFallback());
    }

    return this.initializationPromise;
  }

  isInitialized(): boolean {
    return this.getInstance().isInitialized();
  }

  updateConsentStatus(serviceName: string, isAccepted: boolean): Promise<void> {
    return this.getInstance().updateConsentStatus(serviceName, isAccepted);
  }

  private getInstance(): UsercentricsSingleton {
    return this.useLocal ? this.fallbackInstance : this.ucInstance;
  }

  private async initFallback(): Promise<void> {
    this.useLocal = true;

    const onInitializedCallback = (): void => {
      if (window.UC_UI) {
        window.removeEventListener(UC_UI_INITIALIZED, onInitializedCallback);
        window.addEventListener(CONSENT_STATUS_CHANGE, reloadOnWithdrawal);
        this.useLocal = false;
      }
    };

    window.addEventListener(UC_UI_INITIALIZED, onInitializedCallback);

    return this.fallbackInstance.init();
  }
}

/**
 * Each bundle should (in case of an ad blocker with usercentrics.eu in its filter list) use the same instance of the
 * Usercentrics Cache, that's why we store it in the window object. Otherwise, controllers that are bundled
 * separately (e.g. ConsentController or YouTubeController) will create their own instance of the singleton.
 */

if (typeof window !== 'undefined' && !window.usercentricsSingleton) {
  window.UC_UI_DOMAINS = {
    crossDomainConsentSharingIFrame: 'https://www.lichtblick.de/cross-domain-bridge.html',
  } as UcDomains;
  window.usercentricsSingleton = new UsercentricsSingletonImpl();
}

const global = typeof window !== 'undefined' ? window : ({} as Partial<Window>);

export const getConsentState = (service: UserCentricsServiceName, changeEvent?: CustomEvent): ConsentState => {
  const isConsentGiven = global.usercentricsSingleton?.getConsentStatus(service, changeEvent);

  switch (isConsentGiven) {
    case true:
      return ConsentState.Given;
    case false:
      return ConsentState.Denied;
    default:
      return ConsentState.Unknown;
  }
};

export const setConsent = (service: UserCentricsServiceName, status: ConsentState.Given | ConsentState.Denied) =>
  global.usercentricsSingleton?.updateConsentStatus(service, status === ConsentState.Given) || Promise.resolve();

export const getControllerId = () => global.UC_UI?.getControllerId();
