import styled from '@lichtblick/styled';
import { BorderRadius, Breakpoints, Colors, MediaMediumAndAbove, Spacing, fontStyles } from '@lichtblick/theme';
import { Container } from '@lichtblick/ui-components';

import { Stage } from '../../OStageFull/OStageFull.styles';

const minInterfererHeight = 700;

export const Root = styled.div`
  position: relative;
  ${Container} {
    position: relative;
  }
  ${Stage} {
    align-items: flex-start;
    min-height: 70svh;
    padding-bottom: ${Spacing.Xxl};
    @media (min-height: ${minInterfererHeight}px) {
      align-items: flex-end;
    }
    ${MediaMediumAndAbove} {
      align-items: flex-start;
      /* stylelint-disable-next-line meowtec/no-px */
      max-height: 600px;
      min-height: auto;
    }
  }
`;

export const InterfererContainer = styled(Container).attrs({ as: 'div' })`
  position: relative;
`;

export const SolarInterferer = styled.div`
  @media (max-height: ${minInterfererHeight - 1}px) and (max-width: ${Breakpoints.Medium - 1}px) {
    display: none;
  }
  align-items: center;
  background: ${Colors.Orange};
  border-radius: 50%;
  display: flex;
  /* stylelint-disable-next-line meowtec/no-px */
  font-size: 10px;
  /* stylelint-disable-next-line meowtec/no-px */
  height: 94px;
  hyphenate-character: '';
  justify-content: center;
  left: ${Spacing.Xs};
  position: absolute;
  right: ${Spacing.Xs};
  text-align: center;
  top: ${Spacing.Xs};
  /* stylelint-disable-next-line meowtec/no-px */
  width: 94px;
  z-index: 2;
  ${MediaMediumAndAbove} {
    left: auto;
    right: ${Spacing.Xs};
    top: ${Spacing.Xl};
    transform: translateX(-50%);
  }
`;

export const SolarInterfererText = styled.p`
  line-height: 175%;
  ${fontStyles.headlines};
  font-feature-settings: 'zero' off;
`;

export const SolarInterfererTextBold = styled.b`
  display: block;
  /* stylelint-disable-next-line meowtec/no-px */
  font-size: 26px;
  font-weight: bold;
  line-height: 110%;
`;

export const SellingPointsSection = styled.section<{ $backgroundColor?: Colors }>`
  background: ${({ $backgroundColor }) => $backgroundColor || Colors.FadedGray};
`;

export const SellingPointsContainer = styled(Container)`
  height: 5.5rem;
  ${MediaMediumAndAbove} {
    height: 3rem;
  }
`;

export const SellingPointList = styled.ul`
  background: ${Colors.White};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-block: ${Spacing.Xxs};
  transform: translateY(-50%);

  ${MediaMediumAndAbove} {
    flex-direction: row;
  }
`;

export const SellingPointItem = styled.li`
  align-items: center;
  display: flex;
  * {
    font-size: 0.75rem;
    ${MediaMediumAndAbove} {
      font-size: 1rem;
    }
  }
  gap: ${Spacing.Xs};
  padding-block: ${Spacing.Xxs};
  padding-inline: ${Spacing.Xs};
  ${MediaMediumAndAbove} {
    justify-content: center;
    width: calc(100% / 3);
  }
  & > svg {
    flex-shrink: 0;
  }
`;
