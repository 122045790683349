// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import hugoRedirectTemplates from './.content/hugoRedirectTemplates.json';
import settings from './.content/settings.json';
import { SettingsType } from './types/storyblok';

/** @deprecated In preparation for ISR move to getStaticProps/fetch with revalidation */
export const CF_SETTINGS = settings as unknown as SettingsType;
/** @deprecated In preparation for ISR move to getStaticProps/fetch with revalidation */
export const CF_HUGO_REDIRECT_TEMPLATES = hugoRedirectTemplates as string[];
