import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: Date; output: Date };
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: number; output: number };
  /** The EmailAddress scalar type constitutes a valid email address, represented as a UTF-8 character sequence. The scalar follows the specification defined by the HTML Spec https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: any; output: any };
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any };
  UUID: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
};

export type AcceptCampaignOfferInput = {
  bankAccount?: InputMaybe<ChangeBankAccountInput>;
  code: Scalars['String']['input'];
  contractId: Scalars['Int']['input'];
  marketingText?: InputMaybe<Scalars['String']['input']>;
  optIn: Scalars['Boolean']['input'];
};

export type AcceptTariffChangeAuthenticatedInput = {
  bankAccount?: InputMaybe<ChangeBankAccountInput>;
  contractId: Scalars['Int']['input'];
  marketingText?: InputMaybe<Scalars['String']['input']>;
  offerId?: InputMaybe<Scalars['String']['input']>;
  optIn: Scalars['Boolean']['input'];
};

export type AcceptTariffChangeCodeForContractInput = {
  bankAccount?: InputMaybe<ChangeBankAccountInput>;
  contractId: Scalars['Int']['input'];
  marketingText?: InputMaybe<Scalars['String']['input']>;
  optIn: Scalars['Boolean']['input'];
  productOfferCode: Scalars['String']['input'];
};

export type AcceptTariffChangeCodeInput = {
  bankAccount?: InputMaybe<ChangeBankAccountInput>;
  customerId: Scalars['Int']['input'];
  marketingText?: InputMaybe<Scalars['String']['input']>;
  optIn: Scalars['Boolean']['input'];
  productOfferCode?: InputMaybe<Scalars['String']['input']>;
};

export enum ActivateHemGatewayErrorType {
  Duplicate = 'DUPLICATE',
  InvalidHemGateway = 'INVALID_HEM_GATEWAY',
  None = 'NONE',
  NotFound = 'NOT_FOUND',
  UnhandledError = 'UNHANDLED_ERROR',
}

export type ActivateHemGatewayInput = {
  hemGatewayId: Scalars['String']['input'];
};

export enum AddMeterReadingPayloadError {
  Contractnotfound = 'CONTRACTNOTFOUND',
  Fielderrors = 'FIELDERRORS',
  Futurereadingwithsmallervalue = 'FUTUREREADINGWITHSMALLERVALUE',
  Incorrectvalue = 'INCORRECTVALUE',
  Invaliddate = 'INVALIDDATE',
  Invalidprofilesum = 'INVALIDPROFILESUM',
  Noreadings = 'NOREADINGS',
  Obiscodemissing = 'OBISCODEMISSING',
  Obiscodesnotvalid = 'OBISCODESNOTVALID',
  Obiscodeunknown = 'OBISCODEUNKNOWN',
  Previousreadingwithhighervalue = 'PREVIOUSREADINGWITHHIGHERVALUE',
  Readingdatealreadyexists = 'READINGDATEALREADYEXISTS',
  Readingdateinbilledperiod = 'READINGDATEINBILLEDPERIOD',
  Readingdateinfuture = 'READINGDATEINFUTURE',
  Readingdateoutsidecontract = 'READINGDATEOUTSIDECONTRACT',
  Readingusagesumisnotplausible = 'READINGUSAGESUMISNOTPLAUSIBLE',
  Requestisnotallowed = 'REQUESTISNOTALLOWED',
  Toomanydigits = 'TOOMANYDIGITS',
  Undefined = 'UNDEFINED',
}

export enum AddProductActivationReminderErrorType {
  None = 'NONE',
  ProductActivationReminderAlreadyExists = 'PRODUCT_ACTIVATION_REMINDER_ALREADY_EXISTS',
}

export type AddProductActivationReminderInput = {
  deviceId: Scalars['String']['input'];
  deviceToken: Scalars['String']['input'];
  isNotificationEnabled: Scalars['Boolean']['input'];
  platform: Platform;
  productCode: Scalars['String']['input'];
};

export type AddReadingInput = {
  /** Contract id for this reading */
  contractId: Scalars['Int']['input'];
  /** Date on which this reading is been read at in International format (YYYY-MM-DD) */
  date: Scalars['DateTime']['input'];
  /** A flag to notify that this reading should allow implausible values */
  forceImplausibleReading?: InputMaybe<Scalars['Boolean']['input']>;
  /** A flag to notify that this reading forcefully triggers an Invoice */
  forceInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  /** The reason for implausible readings */
  implausibleReadingReason?: InputMaybe<Scalars['String']['input']>;
  /** Values of the reading */
  readings?: InputMaybe<Array<InputMaybe<RegisterReadingInput>>>;
};

export enum AddReadingPayloadError {
  /** Contract Id not found on authorized customer */
  ContractNotFound = 'CONTRACT_NOT_FOUND',
  /** Fehlerhafte Zählerstände. Fehlerdetails in FieldErrors. */
  Fielderrors = 'FIELDERRORS',
  /** Es existiert bereits ein niedrigerer Zählerstand in der Zukunft  */
  FutureReadingWithSmallerValue = 'FUTURE_READING_WITH_SMALLER_VALUE',
  /** Die Eingabe ist nicht korrekt */
  Incorrectvalue = 'INCORRECTVALUE',
  /** Ablesedatum liegt entweder außerhalb der Vertragsbelieferung oder Datum ist ungültig. Datum muss im Format YYYY-MM-DD sein. */
  Invaliddate = 'INVALIDDATE',
  /** Unplausible Daten */
  InvalidProfileSum = 'INVALID_PROFILE_SUM',
  /** No readings were committed or OBIS codes were empty */
  NoReadings = 'NO_READINGS',
  /** Obis-Codes der Zählwerke sind nicht korrekt */
  ObiscodesNotValid = 'OBISCODES_NOT_VALID',
  /** OBIS Code was not submitted but is present on the contract's counter */
  ObisCodeMissing = 'OBIS_CODE_MISSING',
  /** OBIS Code was submitted but is unknown for this contract */
  ObisCodeUnknown = 'OBIS_CODE_UNKNOWN',
  /** Es existiert bereits ein höherer in der Vergangenheit */
  PreviousReadingWithHigherValue = 'PREVIOUS_READING_WITH_HIGHER_VALUE',
  /** A reading for this reading date already exists */
  ReadingDateAlreadyExists = 'READING_DATE_ALREADY_EXISTS',
  /** The reading date is already covered by an invoice */
  ReadingDateInBilledPeriod = 'READING_DATE_IN_BILLED_PERIOD',
  /** No readings for the future may be committed */
  ReadingDateInFuture = 'READING_DATE_IN_FUTURE',
  /** The reading date is outside of StartDate-EndDate */
  ReadingDateOutsideContract = 'READING_DATE_OUTSIDE_CONTRACT',
  /** Der Verbrauch ist nicht plausibel */
  ReadingUsageSumIsNotPlausible = 'READING_USAGE_SUM_IS_NOT_PLAUSIBLE',
  /** Vorgang ist nicht erlaubt */
  RequestIsNotAllowed = 'REQUEST_IS_NOT_ALLOWED',
  /** This register has less significant digits than were submitted */
  TooManyDigits = 'TOO_MANY_DIGITS',
  /** Unbekannter Fehler */
  Undefined = 'UNDEFINED',
  /** Count of OBIS Codes not matching PowerCloud */
  WrongObisCodeCount = 'WRONG_OBIS_CODE_COUNT',
}

export type AddressFilterInput = {
  and?: InputMaybe<Array<AddressFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  companyName?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  houseNumber?: InputMaybe<StringOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AddressFilterInput>>;
  street?: InputMaybe<StringOperationFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type AddressSortInput = {
  city?: InputMaybe<SortEnumType>;
  companyName?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  houseNumber?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  street?: InputMaybe<SortEnumType>;
  zip?: InputMaybe<SortEnumType>;
};

export type AnswerOptionInput = {
  id: Scalars['String']['input'];
  input?: InputMaybe<Scalars['String']['input']>;
  isUserAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  option?: InputMaybe<Scalars['String']['input']>;
  requiresInput: Scalars['Boolean']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
  validationExpression?: InputMaybe<Scalars['String']['input']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export enum Areas {
  Diet = 'DIET',
  Energy = 'ENERGY',
  Household = 'HOUSEHOLD',
  Lifestyle = 'LIFESTYLE',
  Publicservices = 'PUBLICSERVICES',
  Transport = 'TRANSPORT',
  Unknown = 'UNKNOWN',
}

export type B2bAddressInput = {
  additionalAddress?: InputMaybe<Scalars['String']['input']>;
  careOf?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  /** @deprecated Won't be used anymore */
  name?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated The AddressType has a one-on-one relationship with the DeliverySite. For this we wil mapped directly on the deliverySite */
  type?: InputMaybe<Scalars['String']['input']>;
  until?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type B2bAddressSortInput = {
  additionalAddress?: InputMaybe<SortEnumType>;
  careOf?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  from?: InputMaybe<SortEnumType>;
  houseNumber?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  street?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  until?: InputMaybe<SortEnumType>;
  zip?: InputMaybe<SortEnumType>;
};

export type B2bBankAccountInput = {
  bank?: InputMaybe<Scalars['String']['input']>;
  bic: Scalars['String']['input'];
  holder?: InputMaybe<Scalars['String']['input']>;
  iban: Scalars['String']['input'];
};

export enum B2bCommunicationGroup {
  Invoice = 'INVOICE',
  InvoiceReminder = 'INVOICE_REMINDER',
  Other = 'OTHER',
}

export type B2bContactFormInput = {
  contactMethod?: InputMaybe<Scalars['String']['input']>;
  deliverySiteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  meterNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['String']['input']>;
};

export type B2bContractAccountDiffInput = {
  creditBankAccountId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  paymentBankAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type B2bCustomerDiffInput = {
  bankAccountsToAdd?: InputMaybe<Array<B2bBankAccountInput>>;
  bankAccountsToDeprecate?: InputMaybe<Array<Scalars['String']['input']>>;
  bankAccountsToUndeprecate?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumbersToAdd?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumbersToDelete?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum B2bDeliveryContractStatus {
  /** In Delivery */
  Active = 'ACTIVE',
  /** Will be terminated */
  Canceled = 'CANCELED',
  /** Delivery Pending */
  Pending = 'PENDING',
  /** In Progress */
  Processing = 'PROCESSING',
  /** Terminated */
  Terminated = 'TERMINATED',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export type B2bDeliverySiteDiffInput = {
  billingAddress?: InputMaybe<B2bAddressInput>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type B2bDeliverySiteSortInput = {
  address?: InputMaybe<B2bAddressSortInput>;
  category?: InputMaybe<SortEnumType>;
  contractStatus?: InputMaybe<SortEnumType>;
  creditBankAccountId?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  marketLocationId?: InputMaybe<SortEnumType>;
  meter?: InputMaybe<B2bMeterSortInput>;
  meterLocationId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  paymentBankAccountId?: InputMaybe<SortEnumType>;
  product?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  useBankTransfer?: InputMaybe<SortEnumType>;
};

export type B2bDocumentInputFilterInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  types?: InputMaybe<Array<B2bDocumentType>>;
};

export type B2bDocumentSortInput = {
  attachmentId?: InputMaybe<SortEnumType>;
  campaignStatus?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  downloadUrl?: InputMaybe<SortEnumType>;
  group?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  product?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export enum B2bDocumentType {
  Contract = 'CONTRACT',
  Correspondence = 'CORRESPONDENCE',
  Dunning = 'DUNNING',
  Invoice = 'INVOICE',
  Unknown = 'UNKNOWN',
}

export enum B2bMeterReaderType {
  Gas = 'GAS',
  Rlm = 'RLM',
  Slp = 'SLP',
  Unknown = 'UNKNOWN',
}

export enum B2bMeterReadingType {
  ByCustomer = 'BY_CUSTOMER',
  Estimated = 'ESTIMATED',
  NetProvider = 'NET_PROVIDER',
  Unknown = 'UNKNOWN',
}

export type B2bMeterSortInput = {
  id?: InputMaybe<SortEnumType>;
  meterType?: InputMaybe<SortEnumType>;
  serialNumber?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export enum B2bPaymentMethod {
  DirectDebit = 'DIRECT_DEBIT',
  Invoice = 'INVOICE',
}

export enum B2bProductType {
  Gas = 'GAS',
  Power = 'POWER',
}

export enum B2bReadingReasonType {
  CounterBegin = 'COUNTER_BEGIN',
  CounterEnd = 'COUNTER_END',
  Cycle = 'CYCLE',
  InBetween = 'IN_BETWEEN',
  MeterAssembly = 'METER_ASSEMBLY',
  MeterDisassembly = 'METER_DISASSEMBLY',
  MovingIn = 'MOVING_IN',
  MovingOut = 'MOVING_OUT',
  Unknown = 'UNKNOWN',
}

export type B2bRegisterAddReadingsInput = {
  readings: Array<B2bRegisterReadingInput>;
  registerId: Scalars['String']['input'];
};

export type B2bRegisterReadingInput = {
  createdAt: Scalars['DateTime']['input'];
  date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  reasonId?: InputMaybe<Scalars['String']['input']>;
  statusId: Scalars['String']['input'];
  statusTargetId?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['String']['input']>;
  typeTargetId: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
};

export enum B2bRemoveBankAccountCommandHandlerErrorType {
  UnexistingCreditBankAccount = 'UNEXISTING_CREDIT_BANK_ACCOUNT',
  UnexistingPaymentBankAccount = 'UNEXISTING_PAYMENT_BANK_ACCOUNT',
}

export type B2bRemoveBankAccountInput = {
  creditBankAccountId?: InputMaybe<Scalars['UUID']['input']>;
  paymentBankAccountId?: InputMaybe<Scalars['UUID']['input']>;
};

export enum B2bSendContactFormStatus {
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export enum B2bSetBankAccountCommandHandlerErrorType {
  InvalidCreditAccountIban = 'INVALID_CREDIT_ACCOUNT_IBAN',
  InvalidPaymentAccountIban = 'INVALID_PAYMENT_ACCOUNT_IBAN',
  MissingCreditAccountHolder = 'MISSING_CREDIT_ACCOUNT_HOLDER',
  MissingPaymentAccountHolder = 'MISSING_PAYMENT_ACCOUNT_HOLDER',
}

export type B2bSetBankAccountInput = {
  creditBankAccountHolder: Scalars['String']['input'];
  creditBankAccountIban: Scalars['String']['input'];
  creditBankAccountId?: InputMaybe<Scalars['UUID']['input']>;
  deliverySiteIds: Array<Scalars['UUID']['input']>;
  paymentBankAccountHolder: Scalars['String']['input'];
  paymentBankAccountIban: Scalars['String']['input'];
  paymentBankAccountId?: InputMaybe<Scalars['UUID']['input']>;
};

export enum B2bSetCustomerAddressCommandHandlerErrorType {
  MissingAddress = 'MISSING_ADDRESS',
  MissingCiy = 'MISSING_CIY',
  MissingHouseNumber = 'MISSING_HOUSE_NUMBER',
  MissingZipCode = 'MISSING_ZIP_CODE',
}

export type B2bSetCustomerAddressInput = {
  additionalAddress: Scalars['String']['input'];
  address: Scalars['String']['input'];
  addressId?: InputMaybe<Scalars['UUID']['input']>;
  city: Scalars['String']['input'];
  houseNumber: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export enum B2bSetDefaultBankAccountCommandHandlerErrorType {
  InvalidCreditAccountIban = 'INVALID_CREDIT_ACCOUNT_IBAN',
  InvalidPaymentAccountIban = 'INVALID_PAYMENT_ACCOUNT_IBAN',
  MissingCreditAccountHolder = 'MISSING_CREDIT_ACCOUNT_HOLDER',
  MissingPaymentAccountHolder = 'MISSING_PAYMENT_ACCOUNT_HOLDER',
}

export type B2bSetDefaultBankAccountInput = {
  creditBankAccountHolder: Scalars['String']['input'];
  creditBankAccountIban: Scalars['String']['input'];
  paymentBankAccountHolder: Scalars['String']['input'];
  paymentBankAccountIban: Scalars['String']['input'];
};

export type B2cLongRunningOperationHealthCheckFinishInput = {
  sessionId: Scalars['UUID']['input'];
};

export type B2cLongRunningOperationHealthCheckStartInput = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

export type BankAccountFilterInput = {
  alternativeAccountHolder?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<BankAccountFilterInput>>;
  bank?: InputMaybe<StringOperationFilterInput>;
  bic?: InputMaybe<StringOperationFilterInput>;
  contracts?: InputMaybe<ListComparableInt32OperationFilterInput>;
  directDebit?: InputMaybe<BooleanOperationFilterInput>;
  iban?: InputMaybe<StringOperationFilterInput>;
  ibanObfuscated?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BankAccountFilterInput>>;
};

export type BankAccountSortInput = {
  alternativeAccountHolder?: InputMaybe<SortEnumType>;
  bank?: InputMaybe<SortEnumType>;
  bic?: InputMaybe<SortEnumType>;
  directDebit?: InputMaybe<SortEnumType>;
  iban?: InputMaybe<SortEnumType>;
  ibanObfuscated?: InputMaybe<SortEnumType>;
};

export type BatteryInput = {
  capacity?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type BillingContactInput = {
  billingCountryAlpha2Code?: InputMaybe<Scalars['String']['input']>;
  careOfRecipient?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  salutation: Salutation;
  street?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum BonusCategory {
  Flex = 'FLEX',
  Instant = 'INSTANT',
  None = 'NONE',
  Regular = 'REGULAR',
}

export type BonusCategoryOperationFilterInput = {
  eq?: InputMaybe<BonusCategory>;
  in?: InputMaybe<Array<BonusCategory>>;
  neq?: InputMaybe<BonusCategory>;
  nin?: InputMaybe<Array<BonusCategory>>;
};

export type BonusFilterInput = {
  absolute?: InputMaybe<ComparableInt32OperationFilterInput>;
  and?: InputMaybe<Array<BonusFilterInput>>;
  category?: InputMaybe<BonusCategoryOperationFilterInput>;
  or?: InputMaybe<Array<BonusFilterInput>>;
  percent?: InputMaybe<ComparableInt32OperationFilterInput>;
  type?: InputMaybe<BonusTypeOperationFilterInput>;
  unit?: InputMaybe<BonusUnitOperationFilterInput>;
};

export type BonusSortInput = {
  absolute?: InputMaybe<SortEnumType>;
  category?: InputMaybe<SortEnumType>;
  percent?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<SortEnumType>;
};

export enum BonusType {
  FlexAbsolute = 'FLEX_ABSOLUTE',
  FlexPercentage = 'FLEX_PERCENTAGE',
  None = 'NONE',
  Regular = 'REGULAR',
  Unknown = 'UNKNOWN',
}

export type BonusTypeOperationFilterInput = {
  eq?: InputMaybe<BonusType>;
  in?: InputMaybe<Array<BonusType>>;
  neq?: InputMaybe<BonusType>;
  nin?: InputMaybe<Array<BonusType>>;
};

export enum BonusUnit {
  Euro = 'EURO',
  None = 'NONE',
  Percent = 'PERCENT',
}

export type BonusUnitOperationFilterInput = {
  eq?: InputMaybe<BonusUnit>;
  in?: InputMaybe<Array<BonusUnit>>;
  neq?: InputMaybe<BonusUnit>;
  nin?: InputMaybe<Array<BonusUnit>>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum BuildingType {
  Detached = 'DETACHED',
  MidTerrace = 'MID_TERRACE',
  SemiDetached = 'SEMI_DETACHED',
}

export type CalculateSolarSystemOfferCompactInput = {
  annualSmartMeteringFee?: InputMaybe<Scalars['Decimal']['input']>;
  batteryCapacity?: InputMaybe<Scalars['Decimal']['input']>;
  crmRecordId?: InputMaybe<Scalars['UUID']['input']>;
  includeHeatpump: Scalars['Boolean']['input'];
  includeSmartMeter: Scalars['Boolean']['input'];
  includeWallbox: Scalars['Boolean']['input'];
  isLichtBlickCustomer: Scalars['Boolean']['input'];
  location: LocationDetailsCompactInput;
  numberOfSolarPanels?: InputMaybe<Scalars['Int']['input']>;
  powerConsumption: PowerConsumptionInput;
  roof: RoofDetailsInput;
  solarPackage?: InputMaybe<Scalars['String']['input']>;
};

export type CalculateSolarSystemOfferInput = {
  annualSmartMeteringFee?: InputMaybe<Scalars['Decimal']['input']>;
  /** Information about desired battery */
  battery: BatteryInput;
  /** @deprecated Use property Battery instead */
  batteryCapacity?: InputMaybe<Scalars['Decimal']['input']>;
  crmRecordId?: InputMaybe<Scalars['UUID']['input']>;
  electricityTariff: ElectricityTariffInput;
  /** Additional hardware like Heatpump and Wallbox */
  include?: InputMaybe<Array<SolarHardwareComponentInput>>;
  includeHeatpump: Scalars['Boolean']['input'];
  includeScaffolding: Scalars['Boolean']['input'];
  includeSmartMeter: Scalars['Boolean']['input'];
  includeWallbox: Scalars['Boolean']['input'];
  isLichtBlickCustomer: Scalars['Boolean']['input'];
  location: LocationDetailsInput;
  /** Number of solar modules for selected module id */
  panelModule: SolarHardwareComponentInput;
  powerConsumption: PowerConsumptionInput;
  roof: RoofDetailsInput;
  /** Name of the sales partner */
  salesPartner?: InputMaybe<Scalars['String']['input']>;
  /** The name of the packet in the price list */
  solarPackage?: InputMaybe<Scalars['String']['input']>;
  solarPotential: SolarPotentialInput;
};

export enum CampaignOfferType {
  Contractextensionoffer = 'CONTRACTEXTENSIONOFFER',
  Crosssell = 'CROSSSELL',
  Retention = 'RETENTION',
  Unknown = 'UNKNOWN',
  Upsell = 'UPSELL',
  Vario = 'VARIO',
  Winback = 'WINBACK',
}

export type CampaignTariffOfferRequestInput = {
  basePriceGross: Scalars['Decimal']['input'];
  campaign?: InputMaybe<Scalars['String']['input']>;
  cityId: Scalars['Int']['input'];
  energy: Energy;
  includeInstantBonus?: InputMaybe<Scalars['Boolean']['input']>;
  includeInvalid?: InputMaybe<Scalars['Boolean']['input']>;
  productCode: Scalars['String']['input'];
  usage: Scalars['Int']['input'];
  usageNt?: InputMaybe<Scalars['Int']['input']>;
  workingPriceHtGross: Scalars['Decimal']['input'];
  workingPriceNtGross?: InputMaybe<Scalars['Decimal']['input']>;
};

export enum CancellationPeriodType {
  Days = 'DAYS',
  Months = 'MONTHS',
  Weeks = 'WEEKS',
}

export enum CancellationReason {
  Relocation = 'RELOCATION',
  Unknown = 'UNKNOWN',
}

export enum CancellationReasons {
  Betteroffer = 'BETTEROFFER',
  Death = 'DEATH',
  Differenttariffdesired = 'DIFFERENTTARIFFDESIRED',
  Moveexistingcontract = 'MOVEEXISTINGCONTRACT',
  /** @deprecated Use 'MoveExistingContract' instead */
  Movekeepcontract = 'MOVEKEEPCONTRACT',
  Movenodeliveryaddress = 'MOVENODELIVERYADDRESS',
  /** @deprecated Use 'MoveNoDeliveryAddress' instead */
  Movenodeliverypossible = 'MOVENODELIVERYPOSSIBLE',
  Noreason = 'NOREASON',
  Notsatisfied = 'NOTSATISFIED',
  Otherprovider = 'OTHERPROVIDER',
  Priceadjustment = 'PRICEADJUSTMENT',
}

export enum CancellationTypes {
  Regular = 'REGULAR',
  Relocation = 'RELOCATION',
  Special = 'SPECIAL',
}

export enum Capabilities {
  CreditCardPayable = 'CREDIT_CARD_PAYABLE',
  FreeParking = 'FREE_PARKING',
  Manual = 'MANUAL',
  NativeApp = 'NATIVE_APP',
  Nfc = 'NFC',
  Other = 'OTHER',
  QrCode = 'QR_CODE',
  ReducedParking = 'REDUCED_PARKING',
  ReferToCounter = 'REFER_TO_COUNTER',
  RemoteStartStop = 'REMOTE_START_STOP',
  RentalCarsOnly = 'RENTAL_CARS_ONLY',
  Reservable = 'RESERVABLE',
  Rfid = 'RFID',
  Roofed = 'ROOFED',
  SmsConfirmation = 'SMS_CONFIRMATION',
}

export type CapabilitiesOperationFilterInput = {
  eq?: InputMaybe<Capabilities>;
  in?: InputMaybe<Array<Capabilities>>;
  neq?: InputMaybe<Capabilities>;
  nin?: InputMaybe<Array<Capabilities>>;
};

export type CarFilterInput = {
  and?: InputMaybe<Array<CarFilterInput>>;
  battery_Capacity_Useable?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  charge_Plug?: InputMaybe<StringOperationFilterInput>;
  charge_Standard_Power?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  charge_Standard_Table?: InputMaybe<Charge_Standard_TableFilterInput>;
  efficiency_NEDC?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  efficiency_Real?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  efficiency_WLTP?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  fastcharge_Power_Max?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  or?: InputMaybe<Array<CarFilterInput>>;
  range_NEDC?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  range_Real?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  range_WLTP?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  vehicle_Make?: InputMaybe<StringOperationFilterInput>;
  vehicle_Model?: InputMaybe<StringOperationFilterInput>;
  vehicle_Model_Version?: InputMaybe<StringOperationFilterInput>;
};

export type CarSortInput = {
  battery_Capacity_Useable?: InputMaybe<SortEnumType>;
  charge_Plug?: InputMaybe<SortEnumType>;
  charge_Standard_Power?: InputMaybe<SortEnumType>;
  charge_Standard_Table?: InputMaybe<Charge_Standard_TableSortInput>;
  efficiency_NEDC?: InputMaybe<SortEnumType>;
  efficiency_Real?: InputMaybe<SortEnumType>;
  efficiency_WLTP?: InputMaybe<SortEnumType>;
  fastcharge_Power_Max?: InputMaybe<SortEnumType>;
  range_NEDC?: InputMaybe<SortEnumType>;
  range_Real?: InputMaybe<SortEnumType>;
  range_WLTP?: InputMaybe<SortEnumType>;
  vehicle_Make?: InputMaybe<SortEnumType>;
  vehicle_Model?: InputMaybe<SortEnumType>;
  vehicle_Model_Version?: InputMaybe<SortEnumType>;
};

export enum Categories {
  Article = 'ARTICLE',
  Dynamictariff = 'DYNAMICTARIFF',
  Eaas = 'EAAS',
  Fahrstrom = 'FAHRSTROM',
  Gas = 'GAS',
  Heatpump = 'HEATPUMP',
  Power = 'POWER',
  Power2Car = 'POWER2CAR',
  Service = 'SERVICE',
  Spot = 'SPOT',
  Storageheater = 'STORAGEHEATER',
  Unknown = 'UNKNOWN',
}

export type CategoriesOperationFilterInput = {
  eq?: InputMaybe<Categories>;
  in?: InputMaybe<Array<Categories>>;
  neq?: InputMaybe<Categories>;
  nin?: InputMaybe<Array<Categories>>;
};

export type CategorizedContactFormInput = {
  applicableToWebsiteForLead?: Scalars['Boolean']['input'];
  categoryKey?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contractId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailRepeat?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  isB2C: Scalars['Boolean']['input'];
  isServiceView: Scalars['Boolean']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  majaId: Scalars['Long']['input'];
  meterId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  williId?: InputMaybe<Scalars['String']['input']>;
};

export enum Category {
  Fast = 'FAST',
  Slow = 'SLOW',
  Unknown = 'UNKNOWN',
}

export type CategoryOperationFilterInput = {
  eq?: InputMaybe<Category>;
  in?: InputMaybe<Array<Category>>;
  neq?: InputMaybe<Category>;
  nin?: InputMaybe<Array<Category>>;
};

export type ChangeBankAccountInput = {
  /** Account holder for new IBAN if other than customer */
  alternativeAccountHolder?: InputMaybe<Scalars['String']['input']>;
  /** Contract to which new IBAN should be applied */
  contractId: Scalars['Int']['input'];
  /** New IBAN */
  iban?: InputMaybe<Scalars['String']['input']>;
};

export enum ChangeBankAccountPayloadError {
  /** Ungültige Vertragsnummer */
  Invalidcontractid = 'INVALIDCONTRACTID',
  /** Ungültige IBAN */
  Invalidiban = 'INVALIDIBAN',
  /** Unbekannter Fehler */
  Undefined = 'UNDEFINED',
}

export type ChangeBillingAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contractId: Scalars['Int']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum ChangeBillingAddressPayloadError {
  /** Fehler in einem der Felder */
  Fielderror = 'FIELDERROR',
  /** Wert hat die falsche Länge */
  Invalidlength = 'INVALIDLENGTH',
  /** Parameter hat ungültigen Wert */
  Invalidvalue = 'INVALIDVALUE',
  /** Unbekannter Fehler */
  Undefined = 'UNDEFINED',
  /** Wert fehlt */
  Valuenotset = 'VALUENOTSET',
}

export enum ChangeDepositPayloadError {
  /** Unbekannter Fehler */
  Undefined = 'UNDEFINED',
}

export enum ChangePasswordErrorType {
  InvalidUsernameOrPassword = 'INVALID_USERNAME_OR_PASSWORD',
  None = 'NONE',
}

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum ChangeTariffByOfferError {
  /** Wurde bereits akzeptiert */
  Alreadyaccepted = 'ALREADYACCEPTED',
  /** Angebot ist abgelaufen */
  Offerexpired = 'OFFEREXPIRED',
  /** Unbekannter Fehler */
  Undefined = 'UNDEFINED',
}

export enum ChangeTariffToProductError {
  /** Parameter hat ungültigen Wert */
  Invalidvalue = 'INVALIDVALUE',
  /** Dieser Vertrag kann keinen Tarifwechsel durchführen */
  Notariffchangeallowed = 'NOTARIFFCHANGEALLOWED',
  /** Unbekannter Fehler */
  Undefined = 'UNDEFINED',
  /** Speichern fehlgeschlagen */
  Updatefailed = 'UPDATEFAILED',
  /** Kein Wechsel zu diesem Produkt möglich */
  Wrongproductcode = 'WRONGPRODUCTCODE',
}

export type ChangeTariffToProductInput = {
  bankAccount?: InputMaybe<ChangeBankAccountInput>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  contractId: Scalars['Int']['input'];
  marketingText?: InputMaybe<Scalars['String']['input']>;
  optIn: Scalars['Boolean']['input'];
  productCode?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum ChargeLogStatus {
  FailedToStart = 'FAILED_TO_START',
  FailedToStop = 'FAILED_TO_STOP',
  Started = 'STARTED',
  Starting = 'STARTING',
  Stopped = 'STOPPED',
  Stopping = 'STOPPING',
  Unknown = 'UNKNOWN',
}

export type Charge_Standard_230V16A3XFilterInput = {
  and?: InputMaybe<Array<Charge_Standard_230V16A3XFilterInput>>;
  charge_Speed?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  charge_Time?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  or?: InputMaybe<Array<Charge_Standard_230V16A3XFilterInput>>;
};

export type Charge_Standard_230V16A3XSortInput = {
  charge_Speed?: InputMaybe<SortEnumType>;
  charge_Time?: InputMaybe<SortEnumType>;
};

export type Charge_Standard_TableFilterInput = {
  and?: InputMaybe<Array<Charge_Standard_TableFilterInput>>;
  charge_Standard_230V16A3X?: InputMaybe<Charge_Standard_230V16A3XFilterInput>;
  or?: InputMaybe<Array<Charge_Standard_TableFilterInput>>;
};

export type Charge_Standard_TableSortInput = {
  charge_Standard_230V16A3X?: InputMaybe<Charge_Standard_230V16A3XSortInput>;
};

export enum ChargingTransactionStatus {
  ChargingStarted = 'CHARGING_STARTED',
  Finished = 'FINISHED',
  Plugged = 'PLUGGED',
  Unknown = 'UNKNOWN',
}

export type ChargingTransactionStatusOperationFilterInput = {
  eq?: InputMaybe<ChargingTransactionStatus>;
  in?: InputMaybe<Array<ChargingTransactionStatus>>;
  neq?: InputMaybe<ChargingTransactionStatus>;
  nin?: InputMaybe<Array<ChargingTransactionStatus>>;
};

export type CheckEmobilityPermissionInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type CirranticFormatFilterInput = {
  amperage?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  and?: InputMaybe<Array<CirranticFormatFilterInput>>;
  format?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CirranticFormatFilterInput>>;
  power?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  powerType?: InputMaybe<StringOperationFilterInput>;
  standard?: InputMaybe<StringOperationFilterInput>;
};

export type CityFilterInput = {
  and?: InputMaybe<Array<CityFilterInput>>;
  cityId?: InputMaybe<ComparableInt64OperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CityFilterInput>>;
  streets?: InputMaybe<ListFilterInputTypeOfStreetFilterInput>;
};

export type CitySortInput = {
  cityId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export enum CollectStopChargingErrorFeedbackErrorType {
  ChargeDetailRecordNotFound = 'CHARGE_DETAIL_RECORD_NOT_FOUND',
  HtbChargeLogNotFound = 'HTB_CHARGE_LOG_NOT_FOUND',
  None = 'NONE',
  UserNotFound = 'USER_NOT_FOUND',
}

export type CombinedMessageFilterInput = {
  and?: InputMaybe<Array<CombinedMessageFilterInput>>;
  contractId?: InputMaybe<ComparableInt32OperationFilterInput>;
  date?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  hasBeenRead?: InputMaybe<BooleanOperationFilterInput>;
  hasDocument?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  invoiceAmount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  messageId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CombinedMessageFilterInput>>;
  productName?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<CombinedMessageTypeOperationFilterInput>;
};

export type CombinedMessageSortInput = {
  contractId?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  hasBeenRead?: InputMaybe<SortEnumType>;
  hasDocument?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  invoiceAmount?: InputMaybe<SortEnumType>;
  messageId?: InputMaybe<SortEnumType>;
  productName?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export enum CombinedMessageType {
  Communication = 'COMMUNICATION',
  Invoice = 'INVOICE',
}

export type CombinedMessageTypeOperationFilterInput = {
  eq?: InputMaybe<CombinedMessageType>;
  in?: InputMaybe<Array<CombinedMessageType>>;
  neq?: InputMaybe<CombinedMessageType>;
  nin?: InputMaybe<Array<CombinedMessageType>>;
};

export type CommunicationFilterInput = {
  and?: InputMaybe<Array<CommunicationFilterInput>>;
  archiveId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  externalIdentifiers?: InputMaybe<ExternalIdentifiersFilterInput>;
  hasBeenRead?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  messageId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CommunicationFilterInput>>;
  productName?: InputMaybe<StringOperationFilterInput>;
  sent?: InputMaybe<ComparableInt32OperationFilterInput>;
  sentAt?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
};

export type CommunicationSortInput = {
  archiveId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  externalIdentifiers?: InputMaybe<ExternalIdentifiersSortInput>;
  hasBeenRead?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  messageId?: InputMaybe<SortEnumType>;
  productName?: InputMaybe<SortEnumType>;
  sent?: InputMaybe<SortEnumType>;
  sentAt?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
};

export type ComparableDateTimeOffsetOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ComparableDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type ComparableDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<Scalars['Float']['input']>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<Scalars['UUID']['input']>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<Scalars['Int']['input']>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type ComparableInt64OperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<Scalars['Long']['input']>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<Scalars['Long']['input']>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export type ComparableNullableOfDateTimeOffsetOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type ComparableNullableOfSingleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type ComparableSingleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<Scalars['Float']['input']>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type CompensationContactInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  deviceId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  houseNumber: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  postCode: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export enum CompensationPaymentMethod {
  Stripe = 'STRIPE',
}

export type CompensationPublishOptionsInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  publish: Scalars['Boolean']['input'];
};

export type CompleteSurveyInput = {
  deviceId: Scalars['String']['input'];
  lastAnswerMetaInfo: MetaInfoInput;
};

export enum ConfigureSmartChargingErrorType {
  ConnectionError = 'CONNECTION_ERROR',
  Failed = 'FAILED',
  None = 'NONE',
  NotFound = 'NOT_FOUND',
  NotSupported = 'NOT_SUPPORTED',
}

export type ConfirmOrderInput = {
  confirmedAt?: InputMaybe<Scalars['DateTime']['input']>;
  marketingText?: InputMaybe<Scalars['String']['input']>;
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['Int']['input'];
};

export enum ConfirmOrderPayloadError {
  Undefined = 'UNDEFINED',
}

/** A plug is either a socket (user plugs a cable in) or a non-detachable cable the user takes to put into a socket mounted in the car. */
export type ConnectorFilterInput = {
  and?: InputMaybe<Array<ConnectorFilterInput>>;
  cirranticFormat?: InputMaybe<CirranticFormatFilterInput>;
  /** The format of the plug including standard, thether, power, and category. */
  format?: InputMaybe<FormatFilterInput>;
  htbFormat?: InputMaybe<HtbFormatFilterInput>;
  or?: InputMaybe<Array<ConnectorFilterInput>>;
  properties?: InputMaybe<StringOperationFilterInput>;
};

export enum ConnectorStatus {
  Available = 'AVAILABLE',
  Charging = 'CHARGING',
  Outoforder = 'OUTOFORDER',
  Reserved = 'RESERVED',
  Unknown = 'UNKNOWN',
}

export type ConnectorStatusOperationFilterInput = {
  eq?: InputMaybe<ConnectorStatus>;
  in?: InputMaybe<Array<ConnectorStatus>>;
  neq?: InputMaybe<ConnectorStatus>;
  nin?: InputMaybe<Array<ConnectorStatus>>;
};

export enum ConsumptionType {
  HeatPump = 'HEAT_PUMP',
  HouseholdElectricity = 'HOUSEHOLD_ELECTRICITY',
  HouseholdElectricityAndHeatPump = 'HOUSEHOLD_ELECTRICITY_AND_HEAT_PUMP',
  HouseholdElectricityAndStorageHeater = 'HOUSEHOLD_ELECTRICITY_AND_STORAGE_HEATER',
  None = 'NONE',
  NonCommodity = 'NON_COMMODITY',
  StorageHeater = 'STORAGE_HEATER',
  Universal = 'UNIVERSAL',
  Unknown = 'UNKNOWN',
}

export type ConsumptionTypeOperationFilterInput = {
  eq?: InputMaybe<ConsumptionType>;
  in?: InputMaybe<Array<ConsumptionType>>;
  neq?: InputMaybe<ConsumptionType>;
  nin?: InputMaybe<Array<ConsumptionType>>;
};

export type ContactFormInput = {
  /** ContractId. NULL if none selected */
  contractId?: InputMaybe<Scalars['Int']['input']>;
  /** Message from the customer */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Selected Topic for this form */
  topic?: InputMaybe<Scalars['String']['input']>;
};

export enum ContactFormReasonType {
  AdvancePayment = 'ADVANCE_PAYMENT',
  AppFeedbackWishes = 'APP_FEEDBACK_WISHES',
  CombinedHeatPowerOffshoreLevy = 'COMBINED_HEAT_POWER_OFFSHORE_LEVY',
  DataProtection = 'DATA_PROTECTION',
  Electromobility = 'ELECTROMOBILITY',
  GasAndElectricityPriceCap = 'GAS_AND_ELECTRICITY_PRICE_CAP',
  Invoice = 'INVOICE',
  MeterReading = 'METER_READING',
  MyData = 'MY_DATA',
  Other = 'OTHER',
  PriceAdjustment = 'PRICE_ADJUSTMENT',
  Relocation = 'RELOCATION',
  Reminder = 'REMINDER',
  SolarPackageAndPowerWallet = 'SOLAR_PACKAGE_AND_POWER_WALLET',
  SubmitFinalBill = 'SUBMIT_FINAL_BILL',
  TariffAndContract = 'TARIFF_AND_CONTRACT',
  TechProblems = 'TECH_PROBLEMS',
  Termination = 'TERMINATION',
}

export enum ContactFormReasonsStatus {
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export type ContactInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailBusiness?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneBusiness?: InputMaybe<Scalars['String']['input']>;
  salutation: Salutation;
  street?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPermissionByTokenInput = {
  contractRelatedProducts: Scalars['Boolean']['input'];
  customerId: Scalars['Int']['input'];
  emailEMobility: Scalars['Boolean']['input'];
  emailGas: Scalars['Boolean']['input'];
  emailMarketResearch: Scalars['Boolean']['input'];
  emailNewsletter: Scalars['Boolean']['input'];
  emailPower: Scalars['Boolean']['input'];
  emailPower2Heat: Scalars['Boolean']['input'];
  emailReferral: Scalars['Boolean']['input'];
  emailSolar: Scalars['Boolean']['input'];
};

export type ContactPermissionInput = {
  contractRelatedProducts: Scalars['Boolean']['input'];
  emailEMobility: Scalars['Boolean']['input'];
  emailGas: Scalars['Boolean']['input'];
  emailMarketResearch: Scalars['Boolean']['input'];
  emailNewsletter: Scalars['Boolean']['input'];
  emailPower: Scalars['Boolean']['input'];
  emailPower2Heat: Scalars['Boolean']['input'];
  emailReferral: Scalars['Boolean']['input'];
  emailSolar: Scalars['Boolean']['input'];
  phoneEMobility: Scalars['Boolean']['input'];
  phoneGas: Scalars['Boolean']['input'];
  phoneMarketResearch: Scalars['Boolean']['input'];
  phonePower: Scalars['Boolean']['input'];
  phonePower2Heat: Scalars['Boolean']['input'];
  phoneReferral: Scalars['Boolean']['input'];
  phoneSolar: Scalars['Boolean']['input'];
};

export enum ContactPermissionStatus {
  Error = 'ERROR',
  Success = 'SUCCESS',
  Unknown = 'UNKNOWN',
}

export type ContractCancellationCustomerInput = {
  address?: InputMaybe<AddressInput>;
  contractId: Scalars['Int']['input'];
  customerId: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
};

export enum ContractCancellationError {
  /** Wurde bereits gekündigt */
  Alreadycancelled = 'ALREADYCANCELLED',
  /** Kunde nicht gefunden */
  Customernotfound = 'CUSTOMERNOTFOUND',
  /** PDF konnte nicht erstellt werden */
  Pdfcreationfailed = 'PDFCREATIONFAILED',
  /** Unbekannter Fehler */
  Undefined = 'UNDEFINED',
  /** Ungültiges Kündigungsdatum */
  Wrongcancellationdate = 'WRONGCANCELLATIONDATE',
  /** Ungültiger Status */
  Wrongcontractstate = 'WRONGCONTRACTSTATE',
}

export type ContractCancellationInput = {
  /** Cancellationdate */
  cancellationDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Cancellationreason */
  cancellationReason: CancellationReasons;
  /** Cancellationtype */
  cancellationType: CancellationTypes;
};

export enum ContractDetailTypes {
  Electricity = 'ELECTRICITY',
  Gas = 'GAS',
}

export type ContractFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<ContractFilterInput>>;
  bankAccount?: InputMaybe<BankAccountFilterInput>;
  billingAddress?: InputMaybe<AddressFilterInput>;
  cancellationPeriod?: InputMaybe<PeriodFilterInput>;
  cancellationReason?: InputMaybe<NullableOfCancellationReasonOperationFilterInput>;
  communications?: InputMaybe<ListFilterInputTypeOfCommunicationFilterInput>;
  contractOffers?: InputMaybe<ListFilterInputTypeOfContractOfferFilterInput>;
  counter?: InputMaybe<CounterFilterInput>;
  customerSpecification?: InputMaybe<NullableOfCustomerSpecificationsOperationFilterInput>;
  deposit?: InputMaybe<DepositFilterInput>;
  depositCalculated?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  depositMax?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  depositMin?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  deposits?: InputMaybe<ListFilterInputTypeOfDepositFilterInput>;
  endDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  energy?: InputMaybe<StringOperationFilterInput>;
  extensionTime?: InputMaybe<PeriodFilterInput>;
  externalIdentifiers?: InputMaybe<ExternalIdentifiersFilterInput>;
  externalOrderId?: InputMaybe<StringOperationFilterInput>;
  futureDeposits?: InputMaybe<ListFilterInputTypeOfFutureDepositFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  invoicePeriodEndDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  invoices?: InputMaybe<ListFilterInputTypeOfInvoiceFilterInput>;
  isAllowedToChangeTariff?: InputMaybe<BooleanOperationFilterInput>;
  isCancellationRequested?: InputMaybe<BooleanOperationFilterInput>;
  minimumRuntime?: InputMaybe<PeriodFilterInput>;
  nextCancellationDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ContractFilterInput>>;
  orderId?: InputMaybe<StringOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  products?: InputMaybe<ListFilterInputTypeOfProductFilterInput>;
  relocationPossible?: InputMaybe<BooleanOperationFilterInput>;
  relocationPredecessorId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  startDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  status?: InputMaybe<NullableOfContractStatusOperationFilterInput>;
  statuses?: InputMaybe<ListFilterInputTypeOfContractStatusHistoryFilterInput>;
  tariff?: InputMaybe<TariffFilterInput>;
  tariffs?: InputMaybe<ListFilterInputTypeOfTariffFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
  usage?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

export type ContractOfferFilterInput = {
  and?: InputMaybe<Array<ContractOfferFilterInput>>;
  basePrice?: InputMaybe<PriceFilterInput>;
  bonus?: InputMaybe<ComparableSingleOperationFilterInput>;
  bonusPercentage?: InputMaybe<ComparableSingleOperationFilterInput>;
  campaignCode?: InputMaybe<StringOperationFilterInput>;
  confirmedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  contractId?: InputMaybe<ComparableInt32OperationFilterInput>;
  created?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customerId?: InputMaybe<ComparableInt32OperationFilterInput>;
  deposit?: InputMaybe<ComparableSingleOperationFilterInput>;
  depositWithBonus?: InputMaybe<ComparableSingleOperationFilterInput>;
  fixedPriceMonths?: InputMaybe<ComparableInt32OperationFilterInput>;
  lastEvent?: InputMaybe<EventTypeOperationFilterInput>;
  offerAccepted?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  offerId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContractOfferFilterInput>>;
  revokeRejectedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  revokedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  runTime?: InputMaybe<ComparableInt32OperationFilterInput>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  tariffDetails?: InputMaybe<TariffdetailsFilterInput>;
  type?: InputMaybe<ContractOfferTypeOperationFilterInput>;
  usageCustomer?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  usageCustomerNt?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  validUntil?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  workingPrice?: InputMaybe<PriceFilterInput>;
  workingPriceNT?: InputMaybe<PriceFilterInput>;
};

export type ContractOfferSortInput = {
  basePrice?: InputMaybe<PriceSortInput>;
  bonus?: InputMaybe<SortEnumType>;
  bonusPercentage?: InputMaybe<SortEnumType>;
  campaignCode?: InputMaybe<SortEnumType>;
  confirmedAt?: InputMaybe<SortEnumType>;
  contractId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  customerId?: InputMaybe<SortEnumType>;
  deposit?: InputMaybe<SortEnumType>;
  depositWithBonus?: InputMaybe<SortEnumType>;
  fixedPriceMonths?: InputMaybe<SortEnumType>;
  lastEvent?: InputMaybe<SortEnumType>;
  offerAccepted?: InputMaybe<SortEnumType>;
  offerId?: InputMaybe<SortEnumType>;
  revokeRejectedAt?: InputMaybe<SortEnumType>;
  revokedAt?: InputMaybe<SortEnumType>;
  runTime?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  tariffDetails?: InputMaybe<TariffdetailsSortInput>;
  type?: InputMaybe<SortEnumType>;
  usageCustomer?: InputMaybe<SortEnumType>;
  usageCustomerNt?: InputMaybe<SortEnumType>;
  validUntil?: InputMaybe<SortEnumType>;
  workingPrice?: InputMaybe<PriceSortInput>;
  workingPriceNT?: InputMaybe<PriceSortInput>;
};

export enum ContractOfferType {
  Clarificationcase = 'CLARIFICATIONCASE',
  Contractextensionoffer = 'CONTRACTEXTENSIONOFFER',
  Retention = 'RETENTION',
  Tariffchange = 'TARIFFCHANGE',
  Tariffchangebyproductcode = 'TARIFFCHANGEBYPRODUCTCODE',
  Tariffchangelegacy = 'TARIFFCHANGELEGACY',
  Unknown = 'UNKNOWN',
}

export type ContractOfferTypeOperationFilterInput = {
  eq?: InputMaybe<ContractOfferType>;
  in?: InputMaybe<Array<ContractOfferType>>;
  neq?: InputMaybe<ContractOfferType>;
  nin?: InputMaybe<Array<ContractOfferType>>;
};

export type ContractPriceInput = {
  priceType?: InputMaybe<Scalars['String']['input']>;
  quantityFrom?: InputMaybe<Scalars['Int']['input']>;
  tariffTimeFrames: ContractPriceTariffTimeFrame;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export enum ContractPriceTariffTimeFrame {
  Anytime = 'ANYTIME',
  Unknown = 'UNKNOWN',
}

export type ContractSortInput = {
  address?: InputMaybe<AddressSortInput>;
  bankAccount?: InputMaybe<BankAccountSortInput>;
  billingAddress?: InputMaybe<AddressSortInput>;
  cancellationPeriod?: InputMaybe<PeriodSortInput>;
  cancellationReason?: InputMaybe<SortEnumType>;
  counter?: InputMaybe<CounterSortInput>;
  customerSpecification?: InputMaybe<SortEnumType>;
  deposit?: InputMaybe<DepositSortInput>;
  depositCalculated?: InputMaybe<SortEnumType>;
  depositMax?: InputMaybe<SortEnumType>;
  depositMin?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  energy?: InputMaybe<SortEnumType>;
  extensionTime?: InputMaybe<PeriodSortInput>;
  externalIdentifiers?: InputMaybe<ExternalIdentifiersSortInput>;
  externalOrderId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  invoicePeriodEndDate?: InputMaybe<SortEnumType>;
  isAllowedToChangeTariff?: InputMaybe<SortEnumType>;
  isCancellationRequested?: InputMaybe<SortEnumType>;
  minimumRuntime?: InputMaybe<PeriodSortInput>;
  nextCancellationDate?: InputMaybe<SortEnumType>;
  orderId?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  relocationPossible?: InputMaybe<SortEnumType>;
  relocationPredecessorId?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  tariff?: InputMaybe<TariffSortInput>;
  type?: InputMaybe<SortEnumType>;
  usage?: InputMaybe<SortEnumType>;
};

export enum ContractStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Cancellationpreviousprovider = 'CANCELLATIONPREVIOUSPROVIDER',
  Cancelled = 'CANCELLED',
  Deliverynet = 'DELIVERYNET',
  Done = 'DONE',
  Expired = 'EXPIRED',
  Open = 'OPEN',
  Revoked = 'REVOKED',
  Terminated = 'TERMINATED',
  Unknown = 'UNKNOWN',
  Waiting = 'WAITING',
}

export type ContractStatusHistoryFilterInput = {
  and?: InputMaybe<Array<ContractStatusHistoryFilterInput>>;
  or?: InputMaybe<Array<ContractStatusHistoryFilterInput>>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  status?: InputMaybe<ContractStatusOperationFilterInput>;
};

export type ContractStatusHistorySortInput = {
  startDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export type ContractStatusOperationFilterInput = {
  eq?: InputMaybe<ContractStatus>;
  in?: InputMaybe<Array<ContractStatus>>;
  neq?: InputMaybe<ContractStatus>;
  nin?: InputMaybe<Array<ContractStatus>>;
};

export enum CostItemType {
  Energy = 'ENERGY',
  Service = 'SERVICE',
  UnKnown = 'UN_KNOWN',
}

export type CounterFilterInput = {
  activeFrom?: InputMaybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  activeUntil?: InputMaybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  and?: InputMaybe<Array<CounterFilterInput>>;
  counterNumber?: InputMaybe<StringOperationFilterInput>;
  counterType?: InputMaybe<CounterTypeOperationFilterInput>;
  id?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  or?: InputMaybe<Array<CounterFilterInput>>;
  registers?: InputMaybe<ListFilterInputTypeOfRegisterFilterInput>;
};

export type CounterSortInput = {
  activeFrom?: InputMaybe<SortEnumType>;
  activeUntil?: InputMaybe<SortEnumType>;
  counterNumber?: InputMaybe<SortEnumType>;
  counterType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export enum CounterType {
  Etz = 'ETZ',
  Ntz = 'NTZ',
  Unknown = 'UNKNOWN',
  Ztz = 'ZTZ',
}

export type CounterTypeOperationFilterInput = {
  eq?: InputMaybe<CounterType>;
  in?: InputMaybe<Array<CounterType>>;
  neq?: InputMaybe<CounterType>;
  nin?: InputMaybe<Array<CounterType>>;
};

export type CreateCompensationPaymentIntentInput = {
  amountOfCo2: Scalars['Decimal']['input'];
  amountOfMoney: Scalars['Decimal']['input'];
  contact: CompensationContactInput;
  paymentMethod: CompensationPaymentMethod;
  pricePerTon: Scalars['Decimal']['input'];
  publishOptions: CompensationPublishOptionsInput;
};

export type CreateMarketingLeadInput = {
  lead: MarketingLeadInput;
};

export type CreateOrderExtendedRequestInput = {
  alternativeAccountHolder?: InputMaybe<Scalars['String']['input']>;
  billingContact?: InputMaybe<BillingContactInput>;
  contact?: InputMaybe<ContactInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  delayUntil?: InputMaybe<Scalars['DateTime']['input']>;
  eventMetadata?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  extendedData?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  marketingData?: InputMaybe<MarketingDataInput>;
  nonCommodityOrders?: InputMaybe<Array<InputMaybe<NonCommodityOrderExtendedInput>>>;
  orders?: InputMaybe<Array<InputMaybe<OrderExtendedInput>>>;
  skipCustomerRegistration?: InputMaybe<Scalars['Boolean']['input']>;
  uvpCode?: InputMaybe<Scalars['String']['input']>;
  vat?: InputMaybe<Scalars['String']['input']>;
  vpCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderRequestInput = {
  alternativeAccountHolder?: InputMaybe<Scalars['String']['input']>;
  billingContact?: InputMaybe<BillingContactInput>;
  contact?: InputMaybe<ContactInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  eventMetadata?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  extendedData?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  marketingData?: InputMaybe<MarketingDataInput>;
  nonCommodityOrders?: InputMaybe<Array<InputMaybe<NonCommodityOrderInput>>>;
  orders?: InputMaybe<Array<InputMaybe<OrderInput>>>;
  uvpCode?: InputMaybe<Scalars['String']['input']>;
  vat?: InputMaybe<Scalars['String']['input']>;
  vpCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRelocationOrderInput = {
  /** Arrival date to which customer wishes to start this order in international format (YYYY-MM-DD). */
  arrivalDate: Scalars['DateTime']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  contractId: Scalars['Int']['input'];
  counterNumber?: InputMaybe<Scalars['String']['input']>;
  /** Date of departure in international format (YYYY-MM-DD). */
  departureDate: Scalars['DateTime']['input'];
  deposit?: InputMaybe<Scalars['Float']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  relocationAddressTransfer: RelocationAddressTransfer;
  street?: InputMaybe<Scalars['String']['input']>;
  /** New yearly usage in kWh (only as an alternative to HT/NT yearly usage). This field is mandatory if yearlyUsageHt/yearlyUsageNt is not given. */
  yearlyUsage?: InputMaybe<Scalars['Float']['input']>;
  /** New yearly HT-usage in kWh (only as an alternative to the yearlyUsage). */
  yearlyUsageHt?: InputMaybe<Scalars['Float']['input']>;
  /** New yearly NT-usage in kWh (only as an alternative to the yearlyUsage). Mandatory if yearlyUsageHt is given. */
  yearlyUsageNt?: InputMaybe<Scalars['Float']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum CreateRelocationOrderPayloadError {
  Contractcannotberelocated = 'CONTRACTCANNOTBERELOCATED',
  Fielderrors = 'FIELDERRORS',
  Invalidpastdate = 'INVALIDPASTDATE',
  Invalidusage = 'INVALIDUSAGE',
  Missingvalue = 'MISSINGVALUE',
  Unknown = 'UNKNOWN',
  Unknowncity = 'UNKNOWNCITY',
}

export type CreateSolarPanelInstallationLeadInput = {
  lead: SolarPanelInstallationLeadInput;
};

export enum CurrentEaasSubscriptionDataErrorType {
  IncompleteData = 'INCOMPLETE_DATA',
  None = 'NONE',
  UnhandledError = 'UNHANDLED_ERROR',
}

export enum CustomerContactFormStatus {
  Error = 'ERROR',
  Honeypot = 'HONEYPOT',
  Invalidparameters = 'INVALIDPARAMETERS',
  Success = 'SUCCESS',
}

export type CustomerContractDetailInput = {
  contractDetailType: ContractDetailTypes;
  counterNumber?: InputMaybe<Scalars['String']['input']>;
  previousProvider?: InputMaybe<Scalars['String']['input']>;
  productCode?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  relocationDate?: InputMaybe<Scalars['DateTime']['input']>;
  sourceOfHeat?: InputMaybe<Scalars['String']['input']>;
  terminatedInPerson: Scalars['Boolean']['input'];
  terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
  usage: Scalars['Long']['input'];
  usageNt?: InputMaybe<Scalars['Long']['input']>;
};

export type CustomerInput = {
  /** Optional date of Birth, if changed */
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** Optional phone mobile, if changed */
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  /** Optional phone mobile area code, if changed */
  phoneMobileAreaCode?: InputMaybe<Scalars['String']['input']>;
};

export enum CustomerSpecification {
  DesiredDate = 'DESIRED_DATE',
  EarliestPossibleDate = 'EARLIEST_POSSIBLE_DATE',
  RelocationAt = 'RELOCATION_AT',
  TerminatedInPerson = 'TERMINATED_IN_PERSON',
}

export enum CustomerSpecifications {
  Basicsupplyregistration = 'BASICSUPPLYREGISTRATION',
  Desireddate = 'DESIREDDATE',
  Earliestpossibledate = 'EARLIESTPOSSIBLEDATE',
  Relocationat = 'RELOCATIONAT',
  Relocationwithoutmako = 'RELOCATIONWITHOUTMAKO',
  Substitutesupplyregistration = 'SUBSTITUTESUPPLYREGISTRATION',
  Terminatedinperson = 'TERMINATEDINPERSON',
  Unknown = 'UNKNOWN',
}

export enum DeleteUserAccountStatus {
  Ok = 'OK',
}

export type DepositFilterInput = {
  activeFrom?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  activeUntil?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  and?: InputMaybe<Array<DepositFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  createdByOrigin?: InputMaybe<OriginsOperationFilterInput>;
  grossAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  netAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  nextExecutionAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  nextExecutionFor?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DepositFilterInput>>;
  taxAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  taxRate?: InputMaybe<ComparableDecimalOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updatedByOrigin?: InputMaybe<OriginsOperationFilterInput>;
};

export type DepositInput = {
  /** Date from which this deposit change is active */
  activeFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Day of month on which the deposit is payable */
  alternativeDayOfMonth?: InputMaybe<Scalars['Int']['input']>;
  /** Contract id for this deposit */
  contractId: Scalars['Int']['input'];
  /** New monthly deposit value */
  value: Scalars['Float']['input'];
};

export type DepositSortInput = {
  activeFrom?: InputMaybe<SortEnumType>;
  activeUntil?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdByOrigin?: InputMaybe<SortEnumType>;
  grossAmount?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  netAmount?: InputMaybe<SortEnumType>;
  nextExecutionAt?: InputMaybe<SortEnumType>;
  nextExecutionFor?: InputMaybe<SortEnumType>;
  taxAmount?: InputMaybe<SortEnumType>;
  taxRate?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedByOrigin?: InputMaybe<SortEnumType>;
};

export enum DesiredTimeOfInstallation {
  In1_3Months = 'IN1_3_MONTHS',
  In4_6Months = 'IN4_6_MONTHS',
  In6MonthsAtTheEarliest = 'IN6_MONTHS_AT_THE_EARLIEST',
  NextAvailableTime = 'NEXT_AVAILABLE_TIME',
}

export enum DisbursementType {
  Onetime = 'ONETIME',
  Permanent = 'PERMANENT',
}

export type DispatchLeadEventInput = {
  lead: SolarPanelInstallationLeadDispatchingEventRequestInput;
};

export type DispatchLeadInput = {
  lead: SolarPanelInstallationLeadDispatchingRequestInput;
};

export enum DispatchStatus {
  Deprecated = 'DEPRECATED',
  Failed = 'FAILED',
  Transfered = 'TRANSFERED',
  Transfering = 'TRANSFERING',
}

export type EaasCurrentSubscriptionDataInput = {
  subscriptionId: Scalars['String']['input'];
};

export type EaasEvsesInput = {
  fetchUpdated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EaasEvsesPayloadFilterInput = {
  and?: InputMaybe<Array<EaasEvsesPayloadFilterInput>>;
  /** Represents the charging mode. */
  chargingMode?: InputMaybe<EvseChargingModeOperationFilterInput>;
  /** The Id of the wallbox. */
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Represents the latest charging transation related to the wallbox. */
  latestChargingTransaction?: InputMaybe<LatestEaasChargingTransactionFilterInput>;
  /** Represents the name of the manufacturer. */
  manufacturer?: InputMaybe<StringOperationFilterInput>;
  /** Represents the model of the wallbox. */
  model?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EaasEvsesPayloadFilterInput>>;
  /** Represents the serial number of the wallbox. */
  serialNumber?: InputMaybe<StringOperationFilterInput>;
  /** Represents the internal LB status. */
  status?: InputMaybe<LbEvseStatusOperationFilterInput>;
};

export type EaasHistoricIndependenceInput = {
  numberOfDays?: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
};

export enum EaasProductCategory {
  Energy = 'ENERGY',
  Hardware = 'HARDWARE',
  None = 'NONE',
  Stromwallet = 'STROMWALLET',
}

export type EaasProductInput = {
  powerCloudContractId: Scalars['Int']['input'];
  productCodes: Array<Scalars['String']['input']>;
};

export enum EaasSubscriptionStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Unknown = 'UNKNOWN',
}

export enum EaasUserType {
  ActivatedEaasUser = 'ACTIVATED_EAAS_USER',
  NotActivatedEaasUser = 'NOT_ACTIVATED_EAAS_USER',
  NotEaasUser = 'NOT_EAAS_USER',
}

export type ElectricityTariffInput = {
  basePrice?: InputMaybe<Scalars['Decimal']['input']>;
  workPrice?: InputMaybe<Scalars['Decimal']['input']>;
};

export enum EmobilityTariffType {
  BlockingDurationFast = 'BLOCKING_DURATION_FAST',
  BlockingDurationSlow = 'BLOCKING_DURATION_SLOW',
  BlockingFeeFast = 'BLOCKING_FEE_FAST',
  BlockingFeeSlow = 'BLOCKING_FEE_SLOW',
  Fast = 'FAST',
  Slow = 'SLOW',
}

export type EmobilityTariffTypeOperationFilterInput = {
  eq?: InputMaybe<EmobilityTariffType>;
  in?: InputMaybe<Array<EmobilityTariffType>>;
  neq?: InputMaybe<EmobilityTariffType>;
  nin?: InputMaybe<Array<EmobilityTariffType>>;
};

export enum EmobilityType {
  Advantage = 'ADVANTAGE',
  Error = 'ERROR',
  None = 'NONE',
  Standard = 'STANDARD',
}

export enum Energy {
  Electricity = 'ELECTRICITY',
  Gas = 'GAS',
  None = 'NONE',
}

export enum EnergyCostAidStatus {
  Calculated = 'CALCULATED',
  Notapplicable = 'NOTAPPLICABLE',
  Unknown = 'UNKNOWN',
}

export enum EnergySystemHardwareType {
  Accessory = 'ACCESSORY',
  BatteryAndAccessory = 'BATTERY_AND_ACCESSORY',
  Cable = 'CABLE',
  EnergyManagement = 'ENERGY_MANAGEMENT',
  Heatpump = 'HEATPUMP',
  Inverter = 'INVERTER',
  Other = 'OTHER',
  SolarModule = 'SOLAR_MODULE',
  Wallbox = 'WALLBOX',
}

export enum ErrorType {
  CalculationFailed = 'CALCULATION_FAILED',
  IncompleteData = 'INCOMPLETE_DATA',
  IncorrectGoogleAddress = 'INCORRECT_GOOGLE_ADDRESS',
  UnhandledPartnerApiResponse = 'UNHANDLED_PARTNER_API_RESPONSE',
}

export enum EventType {
  Confirmed = 'CONFIRMED',
  None = 'NONE',
  Revoked = 'REVOKED',
  Revokerejected = 'REVOKEREJECTED',
}

export type EventTypeOperationFilterInput = {
  eq?: InputMaybe<EventType>;
  in?: InputMaybe<Array<EventType>>;
  neq?: InputMaybe<EventType>;
  nin?: InputMaybe<Array<EventType>>;
};

export enum EvseChargingMode {
  Changing = 'CHANGING',
  Managed = 'MANAGED',
  PlugAndCharge = 'PLUG_AND_CHARGE',
  Unknown = 'UNKNOWN',
}

export type EvseChargingModeOperationFilterInput = {
  eq?: InputMaybe<EvseChargingMode>;
  in?: InputMaybe<Array<EvseChargingMode>>;
  neq?: InputMaybe<EvseChargingMode>;
  nin?: InputMaybe<Array<EvseChargingMode>>;
};

export type ExperimentInput = {
  name: Scalars['String']['input'];
  variant: Scalars['String']['input'];
};

export type ExpertModeInput = {
  batterySize?: InputMaybe<Scalars['Decimal']['input']>;
  didUseExpertMode?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfPanels?: InputMaybe<Scalars['Int']['input']>;
};

export type ExtendedDataGroupFilterInput = {
  and?: InputMaybe<Array<ExtendedDataGroupFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ExtendedDataGroupFilterInput>>;
  technicalKey?: InputMaybe<StringOperationFilterInput>;
  values?: InputMaybe<ListFilterInputTypeOfExtendedDataValueFilterInput>;
};

export type ExtendedDataValueFilterInput = {
  and?: InputMaybe<Array<ExtendedDataValueFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ExtendedDataValueFilterInput>>;
  technicalKey?: InputMaybe<StringOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export enum ExtensionTimeType {
  Days = 'DAYS',
  Months = 'MONTHS',
  Weeks = 'WEEKS',
}

export type ExternalIdentifiersFilterInput = {
  and?: InputMaybe<Array<ExternalIdentifiersFilterInput>>;
  easyArchiveGuid?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  or?: InputMaybe<Array<ExternalIdentifiersFilterInput>>;
  powerCloudId?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ExternalIdentifiersSortInput = {
  easyArchiveGuid?: InputMaybe<SortEnumType>;
  powerCloudId?: InputMaybe<SortEnumType>;
};

export enum FixedPriceType {
  Fixedpricetypeenergy = 'FIXEDPRICETYPEENERGY',
  Fixedpricetypefix = 'FIXEDPRICETYPEFIX',
  Fixedpricetypegoodwill = 'FIXEDPRICETYPEGOODWILL',
  Guaranteedate = 'GUARANTEEDATE',
  Guaranteedatefix = 'GUARANTEEDATEFIX',
  Guaranteedatefullpriceexcludingvat = 'GUARANTEEDATEFULLPRICEEXCLUDINGVAT',
  Guaranteedatefullpriceincludingvatandnewtaxes = 'GUARANTEEDATEFULLPRICEINCLUDINGVATANDNEWTAXES',
  Guaranteedategoodwill = 'GUARANTEEDATEGOODWILL',
  Guaranteemonths = 'GUARANTEEMONTHS',
  Guaranteemonthsfix = 'GUARANTEEMONTHSFIX',
  Guaranteemonthsfullpriceexcludingvat = 'GUARANTEEMONTHSFULLPRICEEXCLUDINGVAT',
  Guaranteemonthsfullpriceincludingvatandnewtaxes = 'GUARANTEEMONTHSFULLPRICEINCLUDINGVATANDNEWTAXES',
  Guaranteemonthsgoodwill = 'GUARANTEEMONTHSGOODWILL',
  None = 'NONE',
  Unknown = 'UNKNOWN',
}

export type FixedPriceTypeOperationFilterInput = {
  eq?: InputMaybe<FixedPriceType>;
  in?: InputMaybe<Array<FixedPriceType>>;
  neq?: InputMaybe<FixedPriceType>;
  nin?: InputMaybe<Array<FixedPriceType>>;
};

export type FlexBonusInput = {
  amount: Scalars['Decimal']['input'];
  considerValidFromAndValidUntil: Scalars['Boolean']['input'];
  disbursementType: DisbursementType;
  durationFromMonths: Scalars['Int']['input'];
  durationInMonths: Scalars['Int']['input'];
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentAfterMonths: Scalars['Int']['input'];
  usageFrom?: InputMaybe<Scalars['DateTime']['input']>;
  usageUntil?: InputMaybe<Scalars['DateTime']['input']>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

/** The format describes the physical (electrical and mechanical) conditions a car must met to start charging. */
export type FormatFilterInput = {
  and?: InputMaybe<Array<FormatFilterInput>>;
  /** The category for quick filtering. Maps to limits of the power field. */
  category?: InputMaybe<CategoryOperationFilterInput>;
  /** The maximum (not guaranteed) power in kW */
  maxPower?: InputMaybe<ComparableDecimalOperationFilterInput>;
  or?: InputMaybe<Array<FormatFilterInput>>;
  /** The standard (plug type) that a customer needs to charge. */
  plugType?: InputMaybe<StandardOperationFilterInput>;
  /** Information about the side of the cable (true = station provides cable, false = customer must provide a cable) */
  tethered?: InputMaybe<BooleanOperationFilterInput>;
};

export type FutureContractPricesInput = {
  contractPrices?: InputMaybe<Array<InputMaybe<ContractPriceInput>>>;
  productCode?: InputMaybe<Scalars['String']['input']>;
  productVersion: Scalars['Int']['input'];
};

export type FutureDepositFilterInput = {
  and?: InputMaybe<Array<FutureDepositFilterInput>>;
  dueDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  grossAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  or?: InputMaybe<Array<FutureDepositFilterInput>>;
};

export type GetLastSurveyResultInput = {
  deviceId: Scalars['String']['input'];
};

export type GetQuestionInput = {
  deviceId: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
};

export type GetYearlyCompensationAmountInput = {
  deviceId: Scalars['String']['input'];
};

export enum HardwareTypeEnum {
  Accessory = 'ACCESSORY',
  BatteryAndAccessory = 'BATTERY_AND_ACCESSORY',
  Cable = 'CABLE',
  EnergyManagement = 'ENERGY_MANAGEMENT',
  HeatPump = 'HEAT_PUMP',
  Inverter = 'INVERTER',
  Other = 'OTHER',
  SolarModule = 'SOLAR_MODULE',
  Undefined = 'UNDEFINED',
  Wallbox = 'WALLBOX',
}

export enum HighConsuptionTime {
  AllDay = 'ALL_DAY',
  MorningAndAfternoon = 'MORNING_AND_AFTERNOON',
}

export enum HouseType {
  ApartmentBuilding = 'APARTMENT_BUILDING',
  CommercialBuilding = 'COMMERCIAL_BUILDING',
  Detached = 'DETACHED',
  Terraced = 'TERRACED',
  TwoFamily = 'TWO_FAMILY',
}

export type HtbFormatFilterInput = {
  and?: InputMaybe<Array<HtbFormatFilterInput>>;
  chargePointType?: InputMaybe<StringOperationFilterInput>;
  chargingFacilityType?: InputMaybe<StringOperationFilterInput>;
  maxPower?: InputMaybe<ComparableNullableOfSingleOperationFilterInput>;
  or?: InputMaybe<Array<HtbFormatFilterInput>>;
  plugType?: InputMaybe<StringOperationFilterInput>;
  standard?: InputMaybe<StringOperationFilterInput>;
};

export type IbanValidationFilterInput = {
  and?: InputMaybe<Array<IbanValidationFilterInput>>;
  bankName?: InputMaybe<StringOperationFilterInput>;
  iban?: InputMaybe<StringOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<IbanValidationFilterInput>>;
};

export type IbanValidationSortInput = {
  bankName?: InputMaybe<SortEnumType>;
  iban?: InputMaybe<SortEnumType>;
  isValid?: InputMaybe<SortEnumType>;
};

export type InvoiceFilterInput = {
  and?: InputMaybe<Array<InvoiceFilterInput>>;
  archiveId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  creditAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  externalIdentifiers?: InputMaybe<ExternalIdentifiersFilterInput>;
  hasBeenRead?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  invoiceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  invoiceNumber?: InputMaybe<StringOperationFilterInput>;
  invoiceType?: InputMaybe<InvoiceTypeOperationFilterInput>;
  invoicingDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  invoicingPeriodEnd?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  invoicingPeriodStart?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  isCancelled?: InputMaybe<BooleanOperationFilterInput>;
  isPaid?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<InvoiceFilterInput>>;
  payableAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  paymentMethod?: InputMaybe<PaymentMethodOperationFilterInput>;
  subsequentPaymentAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  tariff?: InputMaybe<TariffFilterInput>;
  usage?: InputMaybe<ComparableDecimalOperationFilterInput>;
};

export type InvoiceSortInput = {
  archiveId?: InputMaybe<SortEnumType>;
  creditAmount?: InputMaybe<SortEnumType>;
  externalIdentifiers?: InputMaybe<ExternalIdentifiersSortInput>;
  hasBeenRead?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  invoiceAmount?: InputMaybe<SortEnumType>;
  invoiceNumber?: InputMaybe<SortEnumType>;
  invoiceType?: InputMaybe<SortEnumType>;
  invoicingDate?: InputMaybe<SortEnumType>;
  invoicingPeriodEnd?: InputMaybe<SortEnumType>;
  invoicingPeriodStart?: InputMaybe<SortEnumType>;
  isCancelled?: InputMaybe<SortEnumType>;
  isPaid?: InputMaybe<SortEnumType>;
  payableAt?: InputMaybe<SortEnumType>;
  paymentMethod?: InputMaybe<SortEnumType>;
  subsequentPaymentAmount?: InputMaybe<SortEnumType>;
  tariff?: InputMaybe<TariffSortInput>;
  usage?: InputMaybe<SortEnumType>;
};

export enum InvoiceType {
  Inbetween = 'INBETWEEN',
  Last = 'LAST',
  Turnus = 'TURNUS',
  Unknown = 'UNKNOWN',
}

export type InvoiceTypeOperationFilterInput = {
  eq?: InputMaybe<InvoiceType>;
  in?: InputMaybe<Array<InvoiceType>>;
  neq?: InputMaybe<InvoiceType>;
  nin?: InputMaybe<Array<InvoiceType>>;
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type LatestEaasChargingTransactionFilterInput = {
  and?: InputMaybe<Array<LatestEaasChargingTransactionFilterInput>>;
  /** Represents the average power consumption during the charging transaction in watts. */
  averageChargePowerWatt?: InputMaybe<ComparableDoubleOperationFilterInput>;
  /** Represents the average energy consumed during the charging transaction in watt-hours. */
  averageConsumedWh?: InputMaybe<ComparableDoubleOperationFilterInput>;
  /** Represents the duration of the charging transaction in seconds. */
  chargingDurationInSeconds?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Represents the date and time when the transaction ended. */
  endedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  /** The Id of the charging transaction. */
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Represents the date and time when the charging transaction information was last updated. */
  lastUpdatedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<LatestEaasChargingTransactionFilterInput>>;
  /** Represents the date and time when the transaction started. */
  startedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  /** Represents the status of the charging transaction. */
  status?: InputMaybe<ChargingTransactionStatusOperationFilterInput>;
};

export enum LbEvseStatus {
  Available = 'AVAILABLE',
  Charging = 'CHARGING',
  Completed = 'COMPLETED',
  Connected = 'CONNECTED',
  Error = 'ERROR',
  Fake = 'FAKE',
  Starting = 'STARTING',
  Stopping = 'STOPPING',
  Unavailable = 'UNAVAILABLE',
}

export type LbEvseStatusOperationFilterInput = {
  eq?: InputMaybe<LbEvseStatus>;
  in?: InputMaybe<Array<LbEvseStatus>>;
  neq?: InputMaybe<LbEvseStatus>;
  nin?: InputMaybe<Array<LbEvseStatus>>;
};

export type ListCapabilitiesOperationFilterInput = {
  all?: InputMaybe<CapabilitiesOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CapabilitiesOperationFilterInput>;
  some?: InputMaybe<CapabilitiesOperationFilterInput>;
};

export type ListComparableDoubleOperationFilterInput = {
  all?: InputMaybe<ComparableDoubleOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ComparableDoubleOperationFilterInput>;
  some?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type ListComparableInt32OperationFilterInput = {
  all?: InputMaybe<ComparableInt32OperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ComparableInt32OperationFilterInput>;
  some?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ListFilterInputTypeOfBonusFilterInput = {
  all?: InputMaybe<BonusFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<BonusFilterInput>;
  some?: InputMaybe<BonusFilterInput>;
};

export type ListFilterInputTypeOfCommunicationFilterInput = {
  all?: InputMaybe<CommunicationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CommunicationFilterInput>;
  some?: InputMaybe<CommunicationFilterInput>;
};

export type ListFilterInputTypeOfConnectorFilterInput = {
  all?: InputMaybe<ConnectorFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConnectorFilterInput>;
  some?: InputMaybe<ConnectorFilterInput>;
};

export type ListFilterInputTypeOfContractOfferFilterInput = {
  all?: InputMaybe<ContractOfferFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ContractOfferFilterInput>;
  some?: InputMaybe<ContractOfferFilterInput>;
};

export type ListFilterInputTypeOfContractStatusHistoryFilterInput = {
  all?: InputMaybe<ContractStatusHistoryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ContractStatusHistoryFilterInput>;
  some?: InputMaybe<ContractStatusHistoryFilterInput>;
};

export type ListFilterInputTypeOfDepositFilterInput = {
  all?: InputMaybe<DepositFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DepositFilterInput>;
  some?: InputMaybe<DepositFilterInput>;
};

export type ListFilterInputTypeOfExtendedDataGroupFilterInput = {
  all?: InputMaybe<ExtendedDataGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ExtendedDataGroupFilterInput>;
  some?: InputMaybe<ExtendedDataGroupFilterInput>;
};

export type ListFilterInputTypeOfExtendedDataValueFilterInput = {
  all?: InputMaybe<ExtendedDataValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ExtendedDataValueFilterInput>;
  some?: InputMaybe<ExtendedDataValueFilterInput>;
};

export type ListFilterInputTypeOfFutureDepositFilterInput = {
  all?: InputMaybe<FutureDepositFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FutureDepositFilterInput>;
  some?: InputMaybe<FutureDepositFilterInput>;
};

export type ListFilterInputTypeOfInvoiceFilterInput = {
  all?: InputMaybe<InvoiceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<InvoiceFilterInput>;
  some?: InputMaybe<InvoiceFilterInput>;
};

export type ListFilterInputTypeOfPeriodFilterInput = {
  all?: InputMaybe<PeriodFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PeriodFilterInput>;
  some?: InputMaybe<PeriodFilterInput>;
};

export type ListFilterInputTypeOfProductFilterInput = {
  all?: InputMaybe<ProductFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProductFilterInput>;
  some?: InputMaybe<ProductFilterInput>;
};

export type ListFilterInputTypeOfReadingFilterInput = {
  all?: InputMaybe<ReadingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ReadingFilterInput>;
  some?: InputMaybe<ReadingFilterInput>;
};

export type ListFilterInputTypeOfRegisterFilterInput = {
  all?: InputMaybe<RegisterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RegisterFilterInput>;
  some?: InputMaybe<RegisterFilterInput>;
};

export type ListFilterInputTypeOfStationFilterInput = {
  all?: InputMaybe<StationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<StationFilterInput>;
  some?: InputMaybe<StationFilterInput>;
};

export type ListFilterInputTypeOfStreetFilterInput = {
  all?: InputMaybe<StreetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<StreetFilterInput>;
  some?: InputMaybe<StreetFilterInput>;
};

export type ListFilterInputTypeOfTariffFilterInput = {
  all?: InputMaybe<TariffFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TariffFilterInput>;
  some?: InputMaybe<TariffFilterInput>;
};

export type ListFilterInputTypeOfTariffInformationFilterInput = {
  all?: InputMaybe<TariffInformationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TariffInformationFilterInput>;
  some?: InputMaybe<TariffInformationFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type LocationBoxInput = {
  max: LocationPointInput;
  min: LocationPointInput;
};

export type LocationClusterFilterInput = {
  and?: InputMaybe<Array<LocationClusterFilterInput>>;
  available?: InputMaybe<BooleanOperationFilterInput>;
  ids?: InputMaybe<ListStringOperationFilterInput>;
  latitude?: InputMaybe<ComparableDoubleOperationFilterInput>;
  longitude?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<LocationClusterFilterInput>>;
  total?: InputMaybe<ComparableInt32OperationFilterInput>;
  unavailable?: InputMaybe<BooleanOperationFilterInput>;
  unknown?: InputMaybe<BooleanOperationFilterInput>;
};

export type LocationClusterFiltersInput = {
  availableOnly: Scalars['Boolean']['input'];
  categories: Array<Category>;
  freeOnly: Scalars['Boolean']['input'];
  standards: Array<Standard>;
  twentyFourSevenOnly: Scalars['Boolean']['input'];
};

export type LocationClusterInput = {
  box: LocationBoxInput;
  clusterFilter?: InputMaybe<LocationClusterFiltersInput>;
};

export type LocationClusterSortInput = {
  available?: InputMaybe<SortEnumType>;
  latitude?: InputMaybe<SortEnumType>;
  longitude?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  unavailable?: InputMaybe<SortEnumType>;
  unknown?: InputMaybe<SortEnumType>;
};

export type LocationDetailsCompactInput = {
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  postalCode: Scalars['String']['input'];
};

export type LocationDetailsInput = {
  /**
   * Operational area
   * @deprecated Not used anymore. Use SolarPackage instead.
   */
  inSe?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  postalCode: Scalars['String']['input'];
};

export type LocationPointInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

/** A location is a physical point and holds one or more stations with one or more plugs. */
export type LocationSearchModelFilterInput = {
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<LocationSearchModelFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  /** New field for the best text representing the charge point for the UI */
  displayName?: InputMaybe<StringOperationFilterInput>;
  hasBlockingFee?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  isChargingDisabled?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<PointModelFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  openingTimes?: InputMaybe<OpeningTimesFilterInput>;
  operatorCode?: InputMaybe<StringOperationFilterInput>;
  operatorEmail?: InputMaybe<StringOperationFilterInput>;
  operatorName?: InputMaybe<StringOperationFilterInput>;
  operatorPhone?: InputMaybe<StringOperationFilterInput>;
  operatorUrl?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocationSearchModelFilterInput>>;
  stations?: InputMaybe<ListFilterInputTypeOfStationFilterInput>;
  tariffInformation?: InputMaybe<ListFilterInputTypeOfTariffInformationFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

/** A location is a physical point and holds one or more stations with one or more plugs. */
export type LocationSearchModelSortInput = {
  address?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  /** New field for the best text representing the charge point for the UI */
  displayName?: InputMaybe<SortEnumType>;
  hasBlockingFee?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isChargingDisabled?: InputMaybe<SortEnumType>;
  location?: InputMaybe<PointModelSortInput>;
  name?: InputMaybe<SortEnumType>;
  openingTimes?: InputMaybe<OpeningTimesSortInput>;
  operatorCode?: InputMaybe<SortEnumType>;
  operatorEmail?: InputMaybe<SortEnumType>;
  operatorName?: InputMaybe<SortEnumType>;
  operatorPhone?: InputMaybe<SortEnumType>;
  operatorUrl?: InputMaybe<SortEnumType>;
  zip?: InputMaybe<SortEnumType>;
};

export type MarketingDataInput = {
  allowAdvertisementOverMail?: InputMaybe<Scalars['Boolean']['input']>;
  allowAdvertisementOverPhone?: InputMaybe<Scalars['Boolean']['input']>;
  allowAdvertisementOverPost?: InputMaybe<Scalars['Boolean']['input']>;
  optin: Scalars['Boolean']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type MarketingLeadInput = {
  contentId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  legalText: Scalars['String']['input'];
  requestId: Scalars['UUID']['input'];
  target: Scalars['String']['input'];
  webCampaignId?: InputMaybe<Scalars['String']['input']>;
};

export enum MessageType {
  All = 'ALL',
  Communication = 'COMMUNICATION',
  Invoice = 'INVOICE',
}

export type MetaInfoInput = {
  busCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  carCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  clothesCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  commuteCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  country: Scalars['String']['input'];
  dietCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  domesticAppliancesCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  electronicApplianceCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  flightsCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  heatingCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  lang: Scalars['String']['input'];
  otherExclDomesticCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  powerConsumptionCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  smartPhoneCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  stateAndGovCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  subwayCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  token: Scalars['String']['input'];
  trainCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  wasteCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
  waterCO2Footprint?: InputMaybe<Scalars['Float']['input']>;
};

export enum MinimumRuntimeType {
  Days = 'DAYS',
  Months = 'MONTHS',
}

export type NcsCalculateSolarPotentialForAddressInput = {
  buildingInsulations?: InputMaybe<Scalars['String']['input']>;
  buildingType?: InputMaybe<Scalars['Int']['input']>;
  city: Scalars['String']['input'];
  energyConsumption?: InputMaybe<Scalars['Int']['input']>;
  fuelType?: InputMaybe<Scalars['Int']['input']>;
  heatingConsumption?: InputMaybe<Scalars['Decimal']['input']>;
  houseNumber: Scalars['String']['input'];
  livingSpace?: InputMaybe<Scalars['Decimal']['input']>;
  moduleId?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  productionTargetFactor?: InputMaybe<Scalars['Decimal']['input']>;
  roofAge?: InputMaybe<RoofAge>;
  roofAreaSize?: InputMaybe<Scalars['Decimal']['input']>;
  roofInclination?: InputMaybe<RoofIndication>;
  roofOrientation?: InputMaybe<RoofOrientation>;
  street: Scalars['String']['input'];
  yearOfConstruction?: InputMaybe<Scalars['Int']['input']>;
  yearOfInstallation?: InputMaybe<Scalars['Int']['input']>;
};

export type NcsCalculateSolarPotentialInput = {
  buildingInsulations?: InputMaybe<Scalars['String']['input']>;
  buildingType?: InputMaybe<Scalars['Int']['input']>;
  energyConsumption?: InputMaybe<Scalars['Int']['input']>;
  fuelType?: InputMaybe<Scalars['Int']['input']>;
  heatingConsumption?: InputMaybe<Scalars['Decimal']['input']>;
  latitude: Scalars['Decimal']['input'];
  livingSpace?: InputMaybe<Scalars['Decimal']['input']>;
  longitude: Scalars['Decimal']['input'];
  moduleId?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  productionTargetFactor?: InputMaybe<Scalars['Decimal']['input']>;
  roofAge?: InputMaybe<RoofAge>;
  roofAreaSize?: InputMaybe<Scalars['Decimal']['input']>;
  roofInclination?: InputMaybe<RoofIndication>;
  roofOrientation?: InputMaybe<RoofOrientation>;
  yearOfConstruction?: InputMaybe<Scalars['Int']['input']>;
  yearOfInstallation?: InputMaybe<Scalars['Int']['input']>;
};

export type NcsGetRadiationImageInput = {
  latitude?: InputMaybe<Scalars['Decimal']['input']>;
  leadId: Scalars['String']['input'];
  longitude?: InputMaybe<Scalars['Decimal']['input']>;
};

export type NcsLeadAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type NcsParkLeadInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseType?: InputMaybe<HouseType>;
  installationAddress: NcsLeadAddressInput;
  isHouseOwner: Scalars['Boolean']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  legalText: Scalars['String']['input'];
  marketingLegalConsentText?: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['UUID']['input'];
  roofMaterial?: InputMaybe<RoofMaterial>;
};

export type NonCommodityAddOnInput = {
  amount: Scalars['Int']['input'];
  productCode?: InputMaybe<Scalars['String']['input']>;
};

export type NonCommodityOrderExtendedInput = {
  activateOrder?: InputMaybe<Scalars['Boolean']['input']>;
  addOns?: InputMaybe<Array<InputMaybe<NonCommodityAddOnInput>>>;
  amount: Scalars['Int']['input'];
  business?: InputMaybe<Scalars['Boolean']['input']>;
  campaignIdentifier?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  customerSpecification: CustomerSpecification;
  externalId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  productCode?: InputMaybe<Scalars['String']['input']>;
  signatureDate: Scalars['DateTime']['input'];
};

export type NonCommodityOrderInput = {
  activateOrder?: InputMaybe<Scalars['Boolean']['input']>;
  addOns?: InputMaybe<Array<InputMaybe<NonCommodityAddOnInput>>>;
  amount: Scalars['Int']['input'];
  business?: InputMaybe<Scalars['Boolean']['input']>;
  campaignIdentifier?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  customerSpecification: CustomerSpecification;
  price?: InputMaybe<Scalars['Float']['input']>;
  productCode?: InputMaybe<Scalars['String']['input']>;
  signatureDate: Scalars['DateTime']['input'];
};

export type NonCommodityProductFilterInput = {
  agb?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<NonCommodityProductFilterInput>>;
  extendedDataGroups?: InputMaybe<ListFilterInputTypeOfExtendedDataGroupFilterInput>;
  or?: InputMaybe<Array<NonCommodityProductFilterInput>>;
  productCode?: InputMaybe<StringOperationFilterInput>;
  productName?: InputMaybe<StringOperationFilterInput>;
};

export type NullableOfCancellationReasonOperationFilterInput = {
  eq?: InputMaybe<CancellationReason>;
  in?: InputMaybe<Array<InputMaybe<CancellationReason>>>;
  neq?: InputMaybe<CancellationReason>;
  nin?: InputMaybe<Array<InputMaybe<CancellationReason>>>;
};

export type NullableOfContractStatusOperationFilterInput = {
  eq?: InputMaybe<ContractStatus>;
  in?: InputMaybe<Array<InputMaybe<ContractStatus>>>;
  neq?: InputMaybe<ContractStatus>;
  nin?: InputMaybe<Array<InputMaybe<ContractStatus>>>;
};

export type NullableOfCustomerSpecificationsOperationFilterInput = {
  eq?: InputMaybe<CustomerSpecifications>;
  in?: InputMaybe<Array<InputMaybe<CustomerSpecifications>>>;
  neq?: InputMaybe<CustomerSpecifications>;
  nin?: InputMaybe<Array<InputMaybe<CustomerSpecifications>>>;
};

export type NullableOfReadingQualifierOperationFilterInput = {
  eq?: InputMaybe<ReadingQualifier>;
  in?: InputMaybe<Array<InputMaybe<ReadingQualifier>>>;
  neq?: InputMaybe<ReadingQualifier>;
  nin?: InputMaybe<Array<InputMaybe<ReadingQualifier>>>;
};

export type NullableOfRegisterTypeOperationFilterInput = {
  eq?: InputMaybe<RegisterType>;
  in?: InputMaybe<Array<InputMaybe<RegisterType>>>;
  neq?: InputMaybe<RegisterType>;
  nin?: InputMaybe<Array<InputMaybe<RegisterType>>>;
};

/** Opening times and 24/7 hint. */
export type OpeningTimesFilterInput = {
  and?: InputMaybe<Array<OpeningTimesFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  exceptions?: InputMaybe<ListFilterInputTypeOfPeriodFilterInput>;
  or?: InputMaybe<Array<OpeningTimesFilterInput>>;
  regular?: InputMaybe<ListFilterInputTypeOfPeriodFilterInput>;
  twentyFourSeven?: InputMaybe<BooleanOperationFilterInput>;
};

/** Opening times and 24/7 hint. */
export type OpeningTimesSortInput = {
  description?: InputMaybe<SortEnumType>;
  twentyFourSeven?: InputMaybe<SortEnumType>;
};

export type OrderExtendedInput = {
  business?: InputMaybe<Scalars['Boolean']['input']>;
  campaignIdentifier?: InputMaybe<Scalars['String']['input']>;
  cancellationPeriod?: InputMaybe<Scalars['Float']['input']>;
  cancellationPeriodType?: InputMaybe<CancellationPeriodType>;
  company?: InputMaybe<Scalars['String']['input']>;
  counterNumber?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  customerSpecification: CustomerSpecification;
  desiredDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  energy: Energy;
  extensionTime?: InputMaybe<Scalars['Float']['input']>;
  extensionTimeType?: InputMaybe<ExtensionTimeType>;
  externalContractId?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  flexBonus?: InputMaybe<FlexBonusInput>;
  isRlm: Scalars['Boolean']['input'];
  marketLocationId?: InputMaybe<Scalars['String']['input']>;
  minimumRuntime?: InputMaybe<Scalars['Float']['input']>;
  minimumRuntimeType?: InputMaybe<MinimumRuntimeType>;
  previousProviderCodeNumber?: InputMaybe<Scalars['String']['input']>;
  productBasePrice?: InputMaybe<Scalars['Float']['input']>;
  productBonus?: InputMaybe<Scalars['Float']['input']>;
  productCode?: InputMaybe<Scalars['String']['input']>;
  productInstantBonus?: InputMaybe<Scalars['Float']['input']>;
  productWorkingPrice?: InputMaybe<Scalars['Float']['input']>;
  relocationDate?: InputMaybe<Scalars['DateTime']['input']>;
  signatureDate: Scalars['DateTime']['input'];
  terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
  usage?: InputMaybe<UsageInput>;
  waitForConfirmationUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrderFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<OrderFilterInput>>;
  bankAccount?: InputMaybe<BankAccountFilterInput>;
  basePriceGross?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  basePriceNet?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  billingAddress?: InputMaybe<AddressFilterInput>;
  billingEmail?: InputMaybe<StringOperationFilterInput>;
  campaign?: InputMaybe<StringOperationFilterInput>;
  confirmedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  contractPeriod?: InputMaybe<PeriodFilterInput>;
  contractRenewalPeriod?: InputMaybe<PeriodFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  monthlyBasePriceGross?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  or?: InputMaybe<Array<OrderFilterInput>>;
  orderedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  priceGuarantee?: InputMaybe<PeriodFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  status?: InputMaybe<OrderStatusOperationFilterInput>;
  usage?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  waitForConfirmationUntil?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  workingPriceGross?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  workingPriceNet?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
};

export type OrderInput = {
  business?: InputMaybe<Scalars['Boolean']['input']>;
  campaignIdentifier?: InputMaybe<Scalars['String']['input']>;
  cancellationPeriod?: InputMaybe<Scalars['Float']['input']>;
  cancellationPeriodType?: InputMaybe<CancellationPeriodType>;
  company?: InputMaybe<Scalars['String']['input']>;
  counterNumber?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  customerSpecification: CustomerSpecification;
  desiredDate?: InputMaybe<Scalars['DateTime']['input']>;
  energy: Energy;
  extensionTime?: InputMaybe<Scalars['Float']['input']>;
  extensionTimeType?: InputMaybe<ExtensionTimeType>;
  futureContractPrices?: InputMaybe<FutureContractPricesInput>;
  marketLocationId?: InputMaybe<Scalars['String']['input']>;
  minimumRuntime?: InputMaybe<Scalars['Float']['input']>;
  minimumRuntimeType?: InputMaybe<MinimumRuntimeType>;
  previousProviderCodeNumber?: InputMaybe<Scalars['String']['input']>;
  productCode?: InputMaybe<Scalars['String']['input']>;
  relocationDate?: InputMaybe<Scalars['DateTime']['input']>;
  signatureDate: Scalars['DateTime']['input'];
  terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
  usage: Scalars['Int']['input'];
  usageNt?: InputMaybe<Scalars['Int']['input']>;
  waitForConfirmationUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrderSortInput = {
  address?: InputMaybe<AddressSortInput>;
  bankAccount?: InputMaybe<BankAccountSortInput>;
  basePriceGross?: InputMaybe<SortEnumType>;
  basePriceNet?: InputMaybe<SortEnumType>;
  billingAddress?: InputMaybe<AddressSortInput>;
  billingEmail?: InputMaybe<SortEnumType>;
  campaign?: InputMaybe<SortEnumType>;
  confirmedAt?: InputMaybe<SortEnumType>;
  contractPeriod?: InputMaybe<PeriodSortInput>;
  contractRenewalPeriod?: InputMaybe<PeriodSortInput>;
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  monthlyBasePriceGross?: InputMaybe<SortEnumType>;
  orderedAt?: InputMaybe<SortEnumType>;
  priceGuarantee?: InputMaybe<PeriodSortInput>;
  product?: InputMaybe<ProductSortInput>;
  status?: InputMaybe<SortEnumType>;
  usage?: InputMaybe<SortEnumType>;
  waitForConfirmationUntil?: InputMaybe<SortEnumType>;
  workingPriceGross?: InputMaybe<SortEnumType>;
  workingPriceNet?: InputMaybe<SortEnumType>;
};

export enum OrderStatus {
  Blocked = 'BLOCKED',
  Cancelled = 'CANCELLED',
  Check = 'CHECK',
  Confirmed = 'CONFIRMED',
  Done = 'DONE',
  Expired = 'EXPIRED',
  Ok = 'OK',
  Open = 'OPEN',
  Rejected = 'REJECTED',
  Revoked = 'REVOKED',
  Unknown = 'UNKNOWN',
  Waiting = 'WAITING',
}

export type OrderStatusOperationFilterInput = {
  eq?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  neq?: InputMaybe<OrderStatus>;
  nin?: InputMaybe<Array<OrderStatus>>;
};

export enum Origin {
  Adb2C = 'ADB2C',
  Error = 'ERROR',
  Legacy = 'LEGACY',
  Unknown = 'UNKNOWN',
}

export enum Origins {
  Admin = 'ADMIN',
  Anonymize = 'ANONYMIZE',
  Backgroundservices = 'BACKGROUNDSERVICES',
  Customerservice = 'CUSTOMERSERVICE',
  Monitoring = 'MONITORING',
  Others = 'OTHERS',
  Unknown = 'UNKNOWN',
  Webservicegateway = 'WEBSERVICEGATEWAY',
}

export type OriginsOperationFilterInput = {
  eq?: InputMaybe<Origins>;
  in?: InputMaybe<Array<Origins>>;
  neq?: InputMaybe<Origins>;
  nin?: InputMaybe<Array<Origins>>;
};

export type ParagraphFilterInput = {
  and?: InputMaybe<Array<ParagraphFilterInput>>;
  body?: InputMaybe<StringOperationFilterInput>;
  bodyMd?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ParagraphFilterInput>>;
  title?: InputMaybe<StringOperationFilterInput>;
};

export type ParagraphSortInput = {
  body?: InputMaybe<SortEnumType>;
  bodyMd?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
};

export type PartnerAvailabilityInput = {
  zipCode: Scalars['String']['input'];
};

export enum PaymentMethod {
  Directdebit = 'DIRECTDEBIT',
  Sepamandat = 'SEPAMANDAT',
  Unknown = 'UNKNOWN',
}

export type PaymentMethodOperationFilterInput = {
  eq?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  neq?: InputMaybe<PaymentMethod>;
  nin?: InputMaybe<Array<PaymentMethod>>;
};

export type PeriodFilterInput = {
  and?: InputMaybe<Array<PeriodFilterInput>>;
  begin?: InputMaybe<StringOperationFilterInput>;
  end?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PeriodFilterInput>>;
  unit?: InputMaybe<PeriodUnitOperationFilterInput>;
  value?: InputMaybe<ComparableInt32OperationFilterInput>;
  weekday?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type PeriodSortInput = {
  unit?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export enum PeriodUnit {
  Days = 'DAYS',
  Months = 'MONTHS',
  Unknown = 'UNKNOWN',
  Weeks = 'WEEKS',
  Years = 'YEARS',
}

export type PeriodUnitOperationFilterInput = {
  eq?: InputMaybe<PeriodUnit>;
  in?: InputMaybe<Array<PeriodUnit>>;
  neq?: InputMaybe<PeriodUnit>;
  nin?: InputMaybe<Array<PeriodUnit>>;
};

export enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Unknown = 'UNKNOWN',
}

export type PointModelFilterInput = {
  and?: InputMaybe<Array<PointModelFilterInput>>;
  coordinates?: InputMaybe<ListComparableDoubleOperationFilterInput>;
  latitude?: InputMaybe<ComparableDoubleOperationFilterInput>;
  longitude?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<PointModelFilterInput>>;
  type?: InputMaybe<StringOperationFilterInput>;
};

export type PointModelSortInput = {
  latitude?: InputMaybe<SortEnumType>;
  longitude?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export type PowerConsumptionInput = {
  heatpump?: InputMaybe<Scalars['Int']['input']>;
  household: Scalars['Int']['input'];
  wallbox?: InputMaybe<Scalars['Int']['input']>;
};

export type PriceCapDataFilterInput = {
  and?: InputMaybe<Array<PriceCapDataFilterInput>>;
  description?: InputMaybe<ParagraphFilterInput>;
  monthlyPrice?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  monthlyPriceWithBonus?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  or?: InputMaybe<Array<PriceCapDataFilterInput>>;
  status?: InputMaybe<PriceCapStatusOperationFilterInput>;
  totalPrice?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  totalPriceWithBonus?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  totalWorkPrice?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  workingPriceWeighed?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
};

export type PriceCapDataSortInput = {
  description?: InputMaybe<ParagraphSortInput>;
  monthlyPrice?: InputMaybe<SortEnumType>;
  monthlyPriceWithBonus?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  totalPrice?: InputMaybe<SortEnumType>;
  totalPriceWithBonus?: InputMaybe<SortEnumType>;
  totalWorkPrice?: InputMaybe<SortEnumType>;
  workingPriceWeighed?: InputMaybe<SortEnumType>;
};

export enum PriceCapStatus {
  HighUsage = 'HIGH_USAGE',
  LowWorkingPrice = 'LOW_WORKING_PRICE',
  None = 'NONE',
  PriceCap = 'PRICE_CAP',
}

export type PriceCapStatusOperationFilterInput = {
  eq?: InputMaybe<PriceCapStatus>;
  in?: InputMaybe<Array<PriceCapStatus>>;
  neq?: InputMaybe<PriceCapStatus>;
  nin?: InputMaybe<Array<PriceCapStatus>>;
};

export type PriceFilterInput = {
  and?: InputMaybe<Array<PriceFilterInput>>;
  or?: InputMaybe<Array<PriceFilterInput>>;
  priceBrutto?: InputMaybe<ComparableSingleOperationFilterInput>;
  priceNetto?: InputMaybe<ComparableSingleOperationFilterInput>;
  priceTaxRate?: InputMaybe<ComparableSingleOperationFilterInput>;
  priceType?: InputMaybe<StringOperationFilterInput>;
};

export type PriceSortInput = {
  priceBrutto?: InputMaybe<SortEnumType>;
  priceNetto?: InputMaybe<SortEnumType>;
  priceTaxRate?: InputMaybe<SortEnumType>;
  priceType?: InputMaybe<SortEnumType>;
};

export enum PriceType {
  Multi = 'MULTI',
  None = 'NONE',
  Single = 'SINGLE',
  Unknown = 'UNKNOWN',
}

export type PriceTypeOperationFilterInput = {
  eq?: InputMaybe<PriceType>;
  in?: InputMaybe<Array<PriceType>>;
  neq?: InputMaybe<PriceType>;
  nin?: InputMaybe<Array<PriceType>>;
};

export type ProductFilterInput = {
  activeFrom?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  activeUntil?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  addOnOf?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ProductFilterInput>>;
  category?: InputMaybe<CategoriesOperationFilterInput>;
  contractProductStatus?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  descriptionHtml?: InputMaybe<StringOperationFilterInput>;
  mandatoryAddOn?: InputMaybe<StringOperationFilterInput>;
  nextProductCodes?: InputMaybe<ListStringOperationFilterInput>;
  or?: InputMaybe<Array<ProductFilterInput>>;
  productCode?: InputMaybe<StringOperationFilterInput>;
  productName?: InputMaybe<StringOperationFilterInput>;
  productType?: InputMaybe<StringOperationFilterInput>;
  productVersion?: InputMaybe<ComparableInt32OperationFilterInput>;
  quantity?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  sortRank?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  usageNtPercentage?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

export type ProductSortInput = {
  activeFrom?: InputMaybe<SortEnumType>;
  activeUntil?: InputMaybe<SortEnumType>;
  addOnOf?: InputMaybe<SortEnumType>;
  category?: InputMaybe<SortEnumType>;
  contractProductStatus?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  descriptionHtml?: InputMaybe<SortEnumType>;
  mandatoryAddOn?: InputMaybe<SortEnumType>;
  productCode?: InputMaybe<SortEnumType>;
  productName?: InputMaybe<SortEnumType>;
  productType?: InputMaybe<SortEnumType>;
  productVersion?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  sortRank?: InputMaybe<SortEnumType>;
  usageNtPercentage?: InputMaybe<SortEnumType>;
};

export type ProviderFilterInput = {
  and?: InputMaybe<Array<ProviderFilterInput>>;
  codeNumber?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isPrimary?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProviderFilterInput>>;
};

export type ProviderSortInput = {
  codeNumber?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isPrimary?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export enum PushNotificationErrorType {
  Failed = 'FAILED',
  None = 'NONE',
}

export type QrCodeUrlInput = {
  qrCode: Scalars['String']['input'];
};

export enum QuestionAnswerType {
  InputOnly = 'INPUT_ONLY',
  OptionsAndInput = 'OPTIONS_AND_INPUT',
  OptionsOnly = 'OPTIONS_ONLY',
}

export enum ReachabilityForContact {
  AllDay = 'ALL_DAY',
  PreferredEvening = 'PREFERRED_EVENING',
  PreferredMorning = 'PREFERRED_MORNING',
  PreferredNoon = 'PREFERRED_NOON',
}

export type ReadingFilterInput = {
  and?: InputMaybe<Array<ReadingFilterInput>>;
  /** Date reading was submitted in format yyyy-mm-dd */
  createdAt?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  implausibleReason?: InputMaybe<StringOperationFilterInput>;
  implausibleReasonExplanation?: InputMaybe<StringOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ReadingFilterInput>>;
  origin?: InputMaybe<ReadingOriginOperationFilterInput>;
  qualifier?: InputMaybe<NullableOfReadingQualifierOperationFilterInput>;
  /** Reading Date in international format yyyy-mm-dd */
  readingDate?: InputMaybe<StringOperationFilterInput>;
  readingType?: InputMaybe<ReadingTypesOperationFilterInput>;
  value?: InputMaybe<ComparableDecimalOperationFilterInput>;
};

export enum ReadingOrigin {
  Bycarrier = 'BYCARRIER',
  Bycustomer = 'BYCUSTOMER',
  Calculated = 'CALCULATED',
  Other = 'OTHER',
}

export type ReadingOriginOperationFilterInput = {
  eq?: InputMaybe<ReadingOrigin>;
  in?: InputMaybe<Array<ReadingOrigin>>;
  neq?: InputMaybe<ReadingOrigin>;
  nin?: InputMaybe<Array<ReadingOrigin>>;
};

export enum ReadingQualifier {
  Estimated = 'ESTIMATED',
  Other = 'OTHER',
  Read = 'READ',
  Unknown = 'UNKNOWN',
}

export type ReadingSortInput = {
  /** Date reading was submitted in format yyyy-mm-dd */
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  implausibleReason?: InputMaybe<SortEnumType>;
  implausibleReasonExplanation?: InputMaybe<SortEnumType>;
  isValid?: InputMaybe<SortEnumType>;
  origin?: InputMaybe<SortEnumType>;
  qualifier?: InputMaybe<SortEnumType>;
  /** Reading Date in international format yyyy-mm-dd */
  readingDate?: InputMaybe<SortEnumType>;
  readingType?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export enum ReadingTypes {
  Counterbegin = 'COUNTERBEGIN',
  Counterend = 'COUNTEREND',
  Cycle = 'CYCLE',
  Estimation = 'ESTIMATION',
  Inbetween = 'INBETWEEN',
  Meterassembly = 'METERASSEMBLY',
  Meterdisassembly = 'METERDISASSEMBLY',
  Movingin = 'MOVINGIN',
  Movingout = 'MOVINGOUT',
  Notspecified = 'NOTSPECIFIED',
  Unknown = 'UNKNOWN',
}

export type ReadingTypesOperationFilterInput = {
  eq?: InputMaybe<ReadingTypes>;
  in?: InputMaybe<Array<ReadingTypes>>;
  neq?: InputMaybe<ReadingTypes>;
  nin?: InputMaybe<Array<ReadingTypes>>;
};

export type RegisterCustomerInput = {
  /** Customer id */
  customerId: Scalars['Int']['input'];
  /** Customer email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Marketing-Text */
  marketingText?: InputMaybe<Scalars['String']['input']>;
  /** Opt-In for email */
  optIn: Scalars['Boolean']['input'];
  /** Registration code */
  token?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterCustomerInputWithMailInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  contractId: Scalars['Int']['input'];
  customerId: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  marketingText?: InputMaybe<Scalars['String']['input']>;
  optIn: Scalars['Boolean']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum RegisterCustomerStatus {
  Duplicateemail = 'DUPLICATEEMAIL',
  Duplicateemailinad = 'DUPLICATEEMAILINAD',
  Duplicateemailinpcl = 'DUPLICATEEMAILINPCL',
  Error = 'ERROR',
  Invalidparameters = 'INVALIDPARAMETERS',
  Invalidtoken = 'INVALIDTOKEN',
  Success = 'SUCCESS',
}

export enum RegisterCustomerWithEmailStatus {
  Duplicateemail = 'DUPLICATEEMAIL',
  Duplicateemailinad = 'DUPLICATEEMAILINAD',
  Duplicateemailinpcl = 'DUPLICATEEMAILINPCL',
  Error = 'ERROR',
  Invalidparameters = 'INVALIDPARAMETERS',
  Isb2B = 'ISB2B',
  Notfound = 'NOTFOUND',
  Success = 'SUCCESS',
}

export type RegisterFilterInput = {
  activeFrom?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  activeUntil?: InputMaybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  and?: InputMaybe<Array<RegisterFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  digits?: InputMaybe<ComparableInt32OperationFilterInput>;
  obis?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RegisterFilterInput>>;
  precision?: InputMaybe<ComparableInt32OperationFilterInput>;
  readings?: InputMaybe<ListFilterInputTypeOfReadingFilterInput>;
  registerType?: InputMaybe<NullableOfRegisterTypeOperationFilterInput>;
};

export type RegisterReadingInput = {
  /** OBIS-Code for the register */
  obisCode?: InputMaybe<Scalars['String']['input']>;
  /** Value of the register */
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum RegisterType {
  Ht = 'HT',
  Nt = 'NT',
  Unknown = 'UNKNOWN',
}

export enum RelocationAddressTransfer {
  Instant = 'INSTANT',
  None = 'NONE',
  Onarrival = 'ONARRIVAL',
}

export type ReplyInput = {
  answer: Array<AnswerOptionInput>;
  meta: MetaInfoInput;
};

export type ReplyQuestionInput = {
  answer: ReplyInput;
  questionId: Scalars['String']['input'];
};

export type ResumeSurveyInput = {
  deviceId: Scalars['String']['input'];
};

export enum RoofAge {
  DontKnow = 'DONT_KNOW',
  No = 'NO',
  Yes = 'YES',
}

export type RoofDetailsInput = {
  /** Indicates either the roof was changed in the last 5 years */
  age?: InputMaybe<Scalars['Int']['input']>;
  areaSize?: InputMaybe<Scalars['Decimal']['input']>;
  inclination?: InputMaybe<Scalars['Int']['input']>;
  orientation?: InputMaybe<Scalars['Int']['input']>;
};

export enum RoofIndication {
  Easy = 'EASY',
  Flat = 'FLAT',
  Moderate = 'MODERATE',
  Normal = 'NORMAL',
  Strong = 'STRONG',
  VeryStrong = 'VERY_STRONG',
}

export enum RoofMaterial {
  Asbestos = 'ASBESTOS',
  Beavertail = 'BEAVERTAIL',
  Brick = 'BRICK',
  CardboardSkein = 'CARDBOARD_SKEIN',
  CorrugatedIron = 'CORRUGATED_IRON',
  GravelRoof = 'GRAVEL_ROOF',
  GreenRoof = 'GREEN_ROOF',
  Miscellaneous = 'MISCELLANEOUS',
  Plastic = 'PLASTIC',
  RoofingMembraneBitumen = 'ROOFING_MEMBRANE_BITUMEN',
  Slate = 'SLATE',
  StandingSeamSheet = 'STANDING_SEAM_SHEET',
  TrapezoidalSheetMetal = 'TRAPEZOIDAL_SHEET_METAL',
}

export enum RoofOrientation {
  East = 'EAST',
  North = 'NORTH',
  NorthEast = 'NORTH_EAST',
  NorthWest = 'NORTH_WEST',
  South = 'SOUTH',
  SouthEast = 'SOUTH_EAST',
  SouthWest = 'SOUTH_WEST',
  West = 'WEST',
}

export enum RoofSolarPotentialCalculationStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
}

export enum Salutation {
  Familie = 'FAMILIE',
  Frau = 'FRAU',
  Herr = 'HERR',
  Ohne = 'OHNE',
}

export enum Salutations {
  Firma = 'FIRMA',
  Frau = 'FRAU',
  Herr = 'HERR',
  Unknown = 'UNKNOWN',
}

export enum SaveFavoriteStationsErrorType {
  None = 'NONE',
  UserNotFound = 'USER_NOT_FOUND',
}

export type SaveFavoriteStationsInput = {
  chargePointIds: Array<Scalars['String']['input']>;
};

export enum ScheduleMissingChargeLogsImportForUserErrorType {
  UserNotValid = 'USER_NOT_VALID',
}

export type SetMessageReadStatusInput = {
  hasBeenRead: Scalars['Boolean']['input'];
  /** One or more Message-Ids or Invoice-Id */
  messageIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specifies the Message Type to which the given MessageIds belong */
  messageType: MessageType;
};

export type SolarHardwareComponentInput = {
  /** Hardware component Id */
  id: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type SolarPanelInstallationLeadDispatchingEventRequestInput = {
  leadId: Scalars['String']['input'];
  partnerTransferRequestId: Scalars['String']['input'];
};

export type SolarPanelInstallationLeadDispatchingRequestInput = {
  channelName?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  contactAddress?: InputMaybe<NcsLeadAddressInput>;
  desiredTimeOfInstallation?: InputMaybe<Scalars['Int']['input']>;
  electricCarExists?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  funnelInterest?: InputMaybe<Scalars['String']['input']>;
  heatPumpInterest?: InputMaybe<Scalars['Boolean']['input']>;
  highConsumptionTime?: InputMaybe<Scalars['Int']['input']>;
  indicativeOfferUrl?: InputMaybe<Scalars['String']['input']>;
  installationAddress: NcsLeadAddressInput;
  lastName: Scalars['String']['input'];
  leadId: Scalars['String']['input'];
  legalText?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['String']['input'];
  partnerName: Scalars['String']['input'];
  partnerTransferRequestId: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  powerConsumption?: InputMaybe<Scalars['Decimal']['input']>;
  powerConsumptionElectricCar?: InputMaybe<Scalars['Decimal']['input']>;
  reachabilityForContact?: InputMaybe<Scalars['Int']['input']>;
  roofAge?: InputMaybe<Scalars['Int']['input']>;
  roofAreaSize?: InputMaybe<Scalars['Decimal']['input']>;
  roofInclination?: InputMaybe<Scalars['Int']['input']>;
  roofMaterial?: InputMaybe<Scalars['Int']['input']>;
  roofOrientation?: InputMaybe<Scalars['Int']['input']>;
};

export type SolarPanelInstallationLeadInput = {
  allowedGoogleMaps?: InputMaybe<Scalars['Boolean']['input']>;
  buildingAge?: InputMaybe<Scalars['Int']['input']>;
  buildingType?: InputMaybe<BuildingType>;
  channel?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  contactAddress?: InputMaybe<NcsLeadAddressInput>;
  desiredTimeOfInstallation?: InputMaybe<DesiredTimeOfInstallation>;
  doiTimestamp?: InputMaybe<Scalars['DateTime']['input']>;
  electricCarExists?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  existingCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  experimentsUsed?: InputMaybe<Array<ExperimentInput>>;
  expertMode?: InputMaybe<ExpertModeInput>;
  externalLeadId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  heatedArea?: InputMaybe<Scalars['Decimal']['input']>;
  heatingConsumption?: InputMaybe<Scalars['Decimal']['input']>;
  heatingMethod?: InputMaybe<Scalars['Int']['input']>;
  heatingSystemAge?: InputMaybe<Scalars['Int']['input']>;
  highConsumptionTime?: InputMaybe<HighConsuptionTime>;
  houseType?: InputMaybe<HouseType>;
  installationAddress: NcsLeadAddressInput;
  insulations?: InputMaybe<Array<Scalars['Int']['input']>>;
  interestInHeatPump?: InputMaybe<Scalars['Boolean']['input']>;
  interestInWallBox?: InputMaybe<Scalars['Boolean']['input']>;
  isHouseOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isImportedLead?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Decimal']['input']>;
  legalText?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Decimal']['input']>;
  marketingCampaign?: InputMaybe<Scalars['String']['input']>;
  marketingLegalConsentText?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  powerConsumption?: InputMaybe<Scalars['Decimal']['input']>;
  powerConsumptionElectricCar?: InputMaybe<Scalars['Decimal']['input']>;
  reachabilityForContact?: InputMaybe<ReachabilityForContact>;
  referralInformation?: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['UUID']['input'];
  roofAge?: InputMaybe<RoofAge>;
  roofAreaSize?: InputMaybe<Scalars['Decimal']['input']>;
  roofInclination?: InputMaybe<RoofIndication>;
  roofMaterial?: InputMaybe<RoofMaterial>;
  roofOrientation?: InputMaybe<RoofOrientation>;
  solarDefaultValues?: InputMaybe<Scalars['String']['input']>;
  webCampaignId?: InputMaybe<Scalars['String']['input']>;
};

export type SolarPotentialInput = {
  averageRadationAmount?: InputMaybe<Scalars['Decimal']['input']>;
  powerOutput?: InputMaybe<Scalars['Decimal']['input']>;
  yield?: InputMaybe<Scalars['Decimal']['input']>;
};

export type SolarSystemCalculationInput = {
  /** Fee that customer should pay yearly for smart metering */
  annualSmartMeteringFee?: InputMaybe<Scalars['Decimal']['input']>;
  /** Average radiation on the roof */
  averageRadationOnRoof?: InputMaybe<Scalars['Decimal']['input']>;
  /** Electricity base price */
  basePrice?: InputMaybe<Scalars['Decimal']['input']>;
  /** Battery capacity */
  batteryCapacity: Scalars['Decimal']['input'];
  /** If the offer is indicative CrmRecordId = OpportunityId else CrmRecordId = QuoteId */
  crmRecordId: Scalars['String']['input'];
  /** Indicates if customer interested in Heatpump */
  heatpump: Scalars['Boolean']['input'];
  /** Indicates if customer interested smart meter */
  imSys: Scalars['Boolean']['input'];
  /**
   * Operational area
   * @deprecated Not used anymore. Use SolarPackage instead.
   */
  inSe?: InputMaybe<Scalars['String']['input']>;
  /** Additional hardware like Heatpump and Wallbox */
  include?: InputMaybe<Array<SolarHardwareComponentInput>>;
  /** Indicated if the customer is an existing LichtBlick customer */
  isLichtBlickCustomer: Scalars['Boolean']['input'];
  /** Latitude of the building */
  latitude: Scalars['Decimal']['input'];
  /** Longitude of the building */
  longitude: Scalars['Decimal']['input'];
  /** Number of solar modules for selected module id */
  panelModule: SolarHardwareComponentInput;
  /** PostalCode of the building */
  postalCode: Scalars['String']['input'];
  /** Household power consumption */
  powerConsumption: Scalars['Int']['input'];
  /** Power consumption of the Heatpump */
  powerConsumptionHeatpump?: InputMaybe<Scalars['Int']['input']>;
  /** Power consumption of the Wallbox */
  powerConsumptionWallbox?: InputMaybe<Scalars['Int']['input']>;
  /** Amount of energy system produces in peak(one module produce * amount of modules) */
  pvPower?: InputMaybe<Scalars['Decimal']['input']>;
  /** Amount of energy system produces yearly */
  pvYield?: InputMaybe<Scalars['Decimal']['input']>;
  /** The age of the roof */
  roofAge?: InputMaybe<Scalars['Int']['input']>;
  /** Roof area size for installing solar panels */
  roofAreaSize?: InputMaybe<Scalars['Decimal']['input']>;
  /** Roof inclination */
  roofInclination?: InputMaybe<Scalars['Int']['input']>;
  /** Roof orientation */
  roofOrientation?: InputMaybe<Scalars['Int']['input']>;
  /** Name of the sales partner */
  salesPartner?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if customer needs scaffolding */
  scaffold: Scalars['Boolean']['input'];
  /** The name of the packet in the price list */
  solarPackage?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if customer interested in Wallbox */
  wallbox: Scalars['Boolean']['input'];
  /** Electricity work price */
  workPrice?: InputMaybe<Scalars['Decimal']['input']>;
};

export enum SolarSystemOfferCalculationStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
}

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Standard {
  Ccs = 'CCS',
  ChAdeMo = 'CH_ADE_MO',
  Domestic = 'DOMESTIC',
  Other = 'OTHER',
  Type1 = 'TYPE1',
  Type2 = 'TYPE2',
  Type3 = 'TYPE3',
}

export type StandardOperationFilterInput = {
  eq?: InputMaybe<Standard>;
  in?: InputMaybe<Array<Standard>>;
  neq?: InputMaybe<Standard>;
  nin?: InputMaybe<Array<Standard>>;
};

export enum StartChargeErrorType {
  CardInactive = 'CARD_INACTIVE',
  None = 'NONE',
  NotAllowed = 'NOT_ALLOWED',
  NotFound = 'NOT_FOUND',
  RetryLater = 'RETRY_LATER',
  Unavailable = 'UNAVAILABLE',
}

export type StartChargingInput = {
  evseId: Scalars['String']['input'];
};

export enum StartEvseChargingErrorType {
  ConnectionError = 'CONNECTION_ERROR',
  Failed = 'FAILED',
  None = 'NONE',
  NotFound = 'NOT_FOUND',
}

export type StartEvseChargingInput = {
  evseId: Scalars['UUID']['input'];
};

export type StartSmartChargingInput = {
  energyWh?: InputMaybe<Scalars['Int']['input']>;
  evseId: Scalars['UUID']['input'];
  smartChargingEnd?: InputMaybe<Scalars['DateTime']['input']>;
  smartChargingStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StartSurveyInput = {
  deviceId: Scalars['String']['input'];
};

/** A station is a physical apparatus one or more plugs. */
export type StationFilterInput = {
  and?: InputMaybe<Array<StationFilterInput>>;
  capabilities?: InputMaybe<ListCapabilitiesOperationFilterInput>;
  /** The plug the station provides. */
  connector?: InputMaybe<ConnectorFilterInput>;
  connectorId?: InputMaybe<StringOperationFilterInput>;
  connectors?: InputMaybe<ListFilterInputTypeOfConnectorFilterInput>;
  /** EVSE Id. */
  evseId?: InputMaybe<StringOperationFilterInput>;
  evseIdInvariant?: InputMaybe<StringOperationFilterInput>;
  isFree?: InputMaybe<BooleanOperationFilterInput>;
  /** Last Update. */
  lastUpdated?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<StationFilterInput>>;
  /** Current status at the time of the last update. May be delayed in queries. */
  status?: InputMaybe<ConnectorStatusOperationFilterInput>;
};

export enum StopChargeErrorType {
  ChargingNotFound = 'CHARGING_NOT_FOUND',
  InactiveCard = 'INACTIVE_CARD',
  None = 'NONE',
  RemoteStop = 'REMOTE_STOP',
  Unavailable = 'UNAVAILABLE',
}

export type StopChargingErrorFeedbackInput = {
  chargeDetailRecordId: Scalars['String']['input'];
  feedbackType: StopChargingErrorFeedbackType;
};

export enum StopChargingErrorFeedbackType {
  ActiveOnStation = 'ACTIVE_ON_STATION',
  StoppedOnStation = 'STOPPED_ON_STATION',
}

export type StopChargingInput = {
  chargeId: Scalars['String']['input'];
};

export enum StopEvseChargingErrorType {
  ConnectionError = 'CONNECTION_ERROR',
  Failed = 'FAILED',
  None = 'NONE',
  NotFound = 'NOT_FOUND',
}

export type StopEvseChargingInput = {
  evseId: Scalars['UUID']['input'];
};

export type StoreOrderInput = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hideCoupon: Scalars['Boolean']['input'];
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  productDetails?: InputMaybe<Array<CustomerContractDetailInput>>;
  street?: InputMaybe<Scalars['String']['input']>;
  uvpCode?: InputMaybe<Scalars['String']['input']>;
  vpCode?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum StoreOrderStatus {
  Emailmissing = 'EMAILMISSING',
  Error = 'ERROR',
  Replaced = 'REPLACED',
  Success = 'SUCCESS',
}

export type StreetFilterInput = {
  and?: InputMaybe<Array<StreetFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StreetFilterInput>>;
};

export type StreetSortInput = {
  name?: InputMaybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export enum SubmitContactFormErrorType {
  FilesRetrievalFailed = 'FILES_RETRIEVAL_FAILED',
  None = 'NONE',
  SubmissionFailed = 'SUBMISSION_FAILED',
  TooManyFiles = 'TOO_MANY_FILES',
}

export type SubmitContactFormInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contractId?: InputMaybe<Scalars['Long']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  device?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  osVersion?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  platform: Platform;
  postCode?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['UUID']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  ticketRoutingKey: Scalars['String']['input'];
  title: Scalars['String']['input'];
  uploadedFiles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SuitableForEmobilityContractInput = {
  contractProducts: Array<SuitableForEmobilityContractProductInput>;
};

export enum SuitableForEmobilityContractProductCategories {
  Article = 'ARTICLE',
  Dynamictariff = 'DYNAMICTARIFF',
  Eaas = 'EAAS',
  Fahrstrom = 'FAHRSTROM',
  Gas = 'GAS',
  Heatpump = 'HEATPUMP',
  Power = 'POWER',
  Power2Car = 'POWER2CAR',
  Service = 'SERVICE',
  Spot = 'SPOT',
  Storageheater = 'STORAGEHEATER',
  Unknown = 'UNKNOWN',
}

export enum SuitableForEmobilityContractProductContractStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Cancellationpreviousprovider = 'CANCELLATIONPREVIOUSPROVIDER',
  Cancelled = 'CANCELLED',
  Deliverynet = 'DELIVERYNET',
  Expired = 'EXPIRED',
  Open = 'OPEN',
  Revoked = 'REVOKED',
  Terminated = 'TERMINATED',
  Unknown = 'UNKNOWN',
  Waiting = 'WAITING',
}

export type SuitableForEmobilityContractProductInput = {
  contractStatus: SuitableForEmobilityContractProductContractStatus;
  productCategory: SuitableForEmobilityContractProductCategories;
  productCode: Scalars['String']['input'];
};

export type TariffFilterInput = {
  activeFrom?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  activeUntil?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  and?: InputMaybe<Array<TariffFilterInput>>;
  basePrice?: InputMaybe<ComparableDecimalOperationFilterInput>;
  cancellationPeriod?: InputMaybe<ComparableInt32OperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  depositDivisor?: InputMaybe<ComparableInt32OperationFilterInput>;
  extensionTime?: InputMaybe<ComparableInt32OperationFilterInput>;
  fixedPriceDate?: InputMaybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  fixedPriceMonths?: InputMaybe<ComparableInt32OperationFilterInput>;
  fixedPriceType?: InputMaybe<FixedPriceTypeOperationFilterInput>;
  minimumRuntime?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<TariffFilterInput>>;
  productName?: InputMaybe<StringOperationFilterInput>;
  workingPrice?: InputMaybe<ComparableDecimalOperationFilterInput>;
  workingPriceNt?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
};

/** Tariff information that matches the existing stations on location level. */
export type TariffInformationFilterInput = {
  and?: InputMaybe<Array<TariffInformationFilterInput>>;
  /** Category to match station. */
  key?: InputMaybe<CategoryOperationFilterInput>;
  or?: InputMaybe<Array<TariffInformationFilterInput>>;
  /** Type of tariff to match contract / user settings. */
  type?: InputMaybe<EmobilityTariffTypeOperationFilterInput>;
  /** Value (price in €). */
  value?: InputMaybe<ComparableDecimalOperationFilterInput>;
};

export type TariffInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  cityId: Scalars['Int']['input'];
  energy: Energy;
  productCode?: InputMaybe<Scalars['String']['input']>;
  usage: Scalars['Int']['input'];
  usageNt?: InputMaybe<Scalars['Int']['input']>;
};

export type TariffOfferFilterInput = {
  agbLink?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<TariffOfferFilterInput>>;
  basePrice?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  basePriceNet?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  basePriceTax?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  bonus?: InputMaybe<BonusFilterInput>;
  bonusParagraph?: InputMaybe<ParagraphFilterInput>;
  bonuses?: InputMaybe<ListFilterInputTypeOfBonusFilterInput>;
  cityId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  consumptionType?: InputMaybe<ConsumptionTypeOperationFilterInput>;
  contractPeriod?: InputMaybe<PeriodFilterInput>;
  contractRenewalPeriod?: InputMaybe<PeriodFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  descriptionHtml?: InputMaybe<StringOperationFilterInput>;
  descriptionMd?: InputMaybe<StringOperationFilterInput>;
  instantBonus?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  invalidReasons?: InputMaybe<ListStringOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  legalParagraph?: InputMaybe<ParagraphFilterInput>;
  monthlyBasePrice?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  monthlyBasePriceNet?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  monthlyPriceWithBonus?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  monthlyRate?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  monthlyTaxRate?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  monthlyUsage?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  monthlyUsageHt?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  monthlyUsageNt?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TariffOfferFilterInput>>;
  priceCapData?: InputMaybe<PriceCapDataFilterInput>;
  priceGuarantee?: InputMaybe<PeriodFilterInput>;
  priceKey?: InputMaybe<StringOperationFilterInput>;
  priceType?: InputMaybe<PriceTypeOperationFilterInput>;
  productCategory?: InputMaybe<CategoriesOperationFilterInput>;
  productCode?: InputMaybe<StringOperationFilterInput>;
  subLine?: InputMaybe<StringOperationFilterInput>;
  totalPrice?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  totalPriceNet?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  totalPriceNoBonus?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  totalPriceTax?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  totalWorkPrice?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  totalWorkPriceNet?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  usage?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  usageHt?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  usageNt?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  workPrice?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  workPriceNet?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  workPriceNt?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  workPriceNtNet?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
};

export type TariffOfferRequestInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  cityId: Scalars['Int']['input'];
  energy: Energy;
  includeInstantBonus?: InputMaybe<Scalars['Boolean']['input']>;
  includeInvalid?: InputMaybe<Scalars['Boolean']['input']>;
  usage: Scalars['Int']['input'];
  usageNt?: InputMaybe<Scalars['Int']['input']>;
};

export type TariffOfferSortInput = {
  agbLink?: InputMaybe<SortEnumType>;
  basePrice?: InputMaybe<SortEnumType>;
  basePriceNet?: InputMaybe<SortEnumType>;
  basePriceTax?: InputMaybe<SortEnumType>;
  bonus?: InputMaybe<BonusSortInput>;
  bonusParagraph?: InputMaybe<ParagraphSortInput>;
  cityId?: InputMaybe<SortEnumType>;
  consumptionType?: InputMaybe<SortEnumType>;
  contractPeriod?: InputMaybe<PeriodSortInput>;
  contractRenewalPeriod?: InputMaybe<PeriodSortInput>;
  description?: InputMaybe<SortEnumType>;
  descriptionHtml?: InputMaybe<SortEnumType>;
  descriptionMd?: InputMaybe<SortEnumType>;
  instantBonus?: InputMaybe<SortEnumType>;
  isValid?: InputMaybe<SortEnumType>;
  legalParagraph?: InputMaybe<ParagraphSortInput>;
  monthlyBasePrice?: InputMaybe<SortEnumType>;
  monthlyBasePriceNet?: InputMaybe<SortEnumType>;
  monthlyPriceWithBonus?: InputMaybe<SortEnumType>;
  monthlyRate?: InputMaybe<SortEnumType>;
  monthlyTaxRate?: InputMaybe<SortEnumType>;
  monthlyUsage?: InputMaybe<SortEnumType>;
  monthlyUsageHt?: InputMaybe<SortEnumType>;
  monthlyUsageNt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  priceCapData?: InputMaybe<PriceCapDataSortInput>;
  priceGuarantee?: InputMaybe<PeriodSortInput>;
  priceKey?: InputMaybe<SortEnumType>;
  priceType?: InputMaybe<SortEnumType>;
  productCategory?: InputMaybe<SortEnumType>;
  productCode?: InputMaybe<SortEnumType>;
  subLine?: InputMaybe<SortEnumType>;
  totalPrice?: InputMaybe<SortEnumType>;
  totalPriceNet?: InputMaybe<SortEnumType>;
  totalPriceNoBonus?: InputMaybe<SortEnumType>;
  totalPriceTax?: InputMaybe<SortEnumType>;
  totalWorkPrice?: InputMaybe<SortEnumType>;
  totalWorkPriceNet?: InputMaybe<SortEnumType>;
  usage?: InputMaybe<SortEnumType>;
  usageHt?: InputMaybe<SortEnumType>;
  usageNt?: InputMaybe<SortEnumType>;
  workPrice?: InputMaybe<SortEnumType>;
  workPriceNet?: InputMaybe<SortEnumType>;
  workPriceNt?: InputMaybe<SortEnumType>;
  workPriceNtNet?: InputMaybe<SortEnumType>;
};

export type TariffSortInput = {
  activeFrom?: InputMaybe<SortEnumType>;
  activeUntil?: InputMaybe<SortEnumType>;
  basePrice?: InputMaybe<SortEnumType>;
  cancellationPeriod?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  depositDivisor?: InputMaybe<SortEnumType>;
  extensionTime?: InputMaybe<SortEnumType>;
  fixedPriceDate?: InputMaybe<SortEnumType>;
  fixedPriceMonths?: InputMaybe<SortEnumType>;
  fixedPriceType?: InputMaybe<SortEnumType>;
  minimumRuntime?: InputMaybe<SortEnumType>;
  productName?: InputMaybe<SortEnumType>;
  workingPrice?: InputMaybe<SortEnumType>;
  workingPriceNt?: InputMaybe<SortEnumType>;
};

export type TariffdetailsFilterInput = {
  agbLink?: InputMaybe<StringOperationFilterInput>;
  amountYear?: InputMaybe<ComparableSingleOperationFilterInput>;
  amountYearReduced?: InputMaybe<ComparableSingleOperationFilterInput>;
  and?: InputMaybe<Array<TariffdetailsFilterInput>>;
  descriptionHtml?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TariffdetailsFilterInput>>;
  productCode?: InputMaybe<StringOperationFilterInput>;
  productName?: InputMaybe<StringOperationFilterInput>;
  usageMonth?: InputMaybe<ComparableSingleOperationFilterInput>;
  usageYear?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type TariffdetailsSortInput = {
  agbLink?: InputMaybe<SortEnumType>;
  amountYear?: InputMaybe<SortEnumType>;
  amountYearReduced?: InputMaybe<SortEnumType>;
  descriptionHtml?: InputMaybe<SortEnumType>;
  productCode?: InputMaybe<SortEnumType>;
  productName?: InputMaybe<SortEnumType>;
  usageMonth?: InputMaybe<SortEnumType>;
  usageYear?: InputMaybe<SortEnumType>;
};

export enum TriggerChangePasswordStatus {
  Ok = 'OK',
}

export type TriggerForgotPasswordInput = {
  email: Scalars['EmailAddress']['input'];
};

export enum TriggerForgotPasswordStatus {
  Ok = 'OK',
}

export enum UpdateCustomerPayloadError {
  /** Unbekannter Fehler */
  Undefined = 'UNDEFINED',
}

export enum UpdateEvseModeErrorType {
  EvseNotFound = 'EVSE_NOT_FOUND',
  InvalidMode = 'INVALID_MODE',
  None = 'NONE',
  Unknown = 'UNKNOWN',
}

export type UpdateEvseModeInput = {
  evseId: Scalars['UUID']['input'];
  mode: EvseChargingMode;
};

export type UpdatePushNotificationRegistrationInput = {
  deviceId: Scalars['String']['input'];
  deviceToken: Scalars['String']['input'];
  isNotificationEnabled: Scalars['Boolean']['input'];
  platform: Platform;
};

export type UsageInput = {
  ht: Scalars['Int']['input'];
  nt?: InputMaybe<Scalars['Int']['input']>;
};

export type ValidateCampaignCodeInput = {
  contractId: Scalars['Int']['input'];
  offerCode: Scalars['String']['input'];
};

export type ValidateCodeForContractInput = {
  contractId: Scalars['Int']['input'];
  productOfferCode: Scalars['String']['input'];
};

export type ValidateRegisterCustomerInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  contractId: Scalars['Int']['input'];
  customerId: Scalars['Int']['input'];
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum ValidateRegisterCustomerStatus {
  Invalidparameters = 'INVALIDPARAMETERS',
  Isb2B = 'ISB2B',
  Notfound = 'NOTFOUND',
  Success = 'SUCCESS',
  Unexpected = 'UNEXPECTED',
}

export enum ValidateTariffChangeCodeError {
  /** Angebot wurde bereits angenommen */
  Alreadyaccepted = 'ALREADYACCEPTED',
  /** Eine Kündigung wurde ausgesprochen aber noch nicht durchgeführt */
  Contractcancellationrequested = 'CONTRACTCANCELLATIONREQUESTED',
  /** Vertrag wurde gekündigt */
  Contractcancelled = 'CONTRACTCANCELLED',
  /** Vertrag zum Kunden nicht vorhanden */
  Contractmissing = 'CONTRACTMISSING',
  /** Parameter hat ungültigen Wert */
  Invalidvalue = 'INVALIDVALUE',
  /** Angebot ist Mehrtarif */
  Multipletariffs = 'MULTIPLETARIFFS',
  /** Kunde nicht vorhanden */
  Notmigrated = 'NOTMIGRATED',
  /** Angebot ist nicht mehr gültig */
  Offerexpired = 'OFFEREXPIRED',
  /** Anderes Angebot wurde bereits angenommen */
  Otherofferaccepted = 'OTHEROFFERACCEPTED',
  /** Unbekannter Fehler */
  Undefined = 'UNDEFINED',
  /** Speichern fehlgeschlagen */
  Updatefailed = 'UPDATEFAILED',
  /** Wert fehlt */
  Valuenotset = 'VALUENOTSET',
}

export type ValidateZipCodeForSystemInstallationInput = {
  zipCode: Scalars['String']['input'];
};

export type SubmitCategorizedContactFormMutationVariables = Exact<{
  contactFormInput: CategorizedContactFormInput;
}>;

export type SubmitCategorizedContactFormMutation = {
  __typename?: 'Mutation';
  categorizedContactForm: { __typename?: 'CustomerContactFormResult'; status: CustomerContactFormStatus };
};

export type EaasCreateMarketingLeadMutationVariables = Exact<{
  input: CreateMarketingLeadInput;
}>;

export type EaasCreateMarketingLeadMutation = {
  __typename?: 'Mutation';
  eaasCreateMarketingLead: { __typename?: 'MarketingLeadPayload'; isSent: boolean };
};

export type GetCitiesQueryVariables = Exact<{
  postcode?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCitiesQuery = {
  __typename?: 'Query';
  citiesByPostcode: Array<{ __typename?: 'City'; name?: string | null; cityId: any }>;
};

export type GetContactFormReasonsQueryVariables = Exact<{
  token?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetContactFormReasonsQuery = {
  __typename?: 'Query';
  contactFormReasons: {
    __typename?: 'ContactFormReasonsResult';
    majaId: any;
    williId?: string | null;
    status: ContactFormReasonsStatus;
    contactFormReasons?: Array<{
      __typename?: 'ContactFormReason';
      id: any;
      key?: string | null;
      label?: string | null;
      applicableB2B: boolean;
      applicableB2C: boolean;
      applicableServiceView: boolean;
      applicableWebsite: boolean;
      applicableToWebsiteForLead: boolean;
    }> | null;
  };
};

export type NonCommodityProductPartFragment = {
  __typename?: 'NonCommodityProduct';
  productCode: string;
  prices: Array<{ __typename?: 'NonCommodityPrice'; minimalAmount: number; salesPriceGross: number; validFrom: Date }>;
  extendedDataGroups: Array<{
    __typename?: 'ExtendedDataGroup';
    name: string;
    technicalKey: string;
    values: Array<{ __typename?: 'ExtendedDataValue'; name: string; technicalKey: string; value: string }>;
  }>;
  addOns: Array<{
    __typename?: 'AddOn';
    productType: string;
    productCode: string;
    prices: Array<{
      __typename?: 'NonCommodityPrice';
      minimalAmount: number;
      salesPriceGross: number;
      validFrom: Date;
    }>;
  }>;
};

export type NonCommodityAddOnPartFragment = {
  __typename?: 'AddOn';
  productType: string;
  productCode: string;
  prices: Array<{ __typename?: 'NonCommodityPrice'; minimalAmount: number; salesPriceGross: number; validFrom: Date }>;
};

export type NonCommodityPricePartFragment = {
  __typename?: 'NonCommodityPrice';
  minimalAmount: number;
  salesPriceGross: number;
  validFrom: Date;
};

export type NonCommodityExtendedDataGroupPartFragment = {
  __typename?: 'ExtendedDataGroup';
  name: string;
  technicalKey: string;
  values: Array<{ __typename?: 'ExtendedDataValue'; name: string; technicalKey: string; value: string }>;
};

export type GetNonCommodityProductsQueryVariables = Exact<{
  campaign: Scalars['String']['input'];
  productCodes: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export type GetNonCommodityProductsQuery = {
  __typename?: 'Query';
  nonCommodityProductsByCampaign: Array<{
    __typename?: 'NonCommodityProduct';
    productCode: string;
    prices: Array<{
      __typename?: 'NonCommodityPrice';
      minimalAmount: number;
      salesPriceGross: number;
      validFrom: Date;
    }>;
    extendedDataGroups: Array<{
      __typename?: 'ExtendedDataGroup';
      name: string;
      technicalKey: string;
      values: Array<{ __typename?: 'ExtendedDataValue'; name: string; technicalKey: string; value: string }>;
    }>;
    addOns: Array<{
      __typename?: 'AddOn';
      productType: string;
      productCode: string;
      prices: Array<{
        __typename?: 'NonCommodityPrice';
        minimalAmount: number;
        salesPriceGross: number;
        validFrom: Date;
      }>;
    }>;
  }>;
};

export const NonCommodityPricePartFragmentDoc = gql`
  fragment NonCommodityPricePart on NonCommodityPrice {
    minimalAmount
    salesPriceGross
    validFrom
  }
`;
export const NonCommodityExtendedDataGroupPartFragmentDoc = gql`
  fragment NonCommodityExtendedDataGroupPart on ExtendedDataGroup {
    name
    technicalKey
    values {
      name
      technicalKey
      value
    }
  }
`;
export const NonCommodityAddOnPartFragmentDoc = gql`
  fragment NonCommodityAddOnPart on AddOn {
    productType
    productCode
    prices {
      ...NonCommodityPricePart
    }
  }
  ${NonCommodityPricePartFragmentDoc}
`;
export const NonCommodityProductPartFragmentDoc = gql`
  fragment NonCommodityProductPart on NonCommodityProduct {
    productCode
    prices {
      ...NonCommodityPricePart
    }
    extendedDataGroups {
      ...NonCommodityExtendedDataGroupPart
    }
    addOns {
      ...NonCommodityAddOnPart
    }
  }
  ${NonCommodityPricePartFragmentDoc}
  ${NonCommodityExtendedDataGroupPartFragmentDoc}
  ${NonCommodityAddOnPartFragmentDoc}
`;
export const SubmitCategorizedContactFormDocument = gql`
  mutation submitCategorizedContactForm($contactFormInput: CategorizedContactFormInput!) {
    categorizedContactForm(contactFormInput: $contactFormInput) {
      status
    }
  }
`;
export const EaasCreateMarketingLeadDocument = gql`
  mutation eaasCreateMarketingLead($input: CreateMarketingLeadInput!) {
    eaasCreateMarketingLead(input: $input) {
      isSent
    }
  }
`;
export const GetCitiesDocument = gql`
  query getCities($postcode: String) {
    citiesByPostcode(postcode: $postcode) {
      name
      cityId
    }
  }
`;
export const GetContactFormReasonsDocument = gql`
  query getContactFormReasons($token: String) {
    contactFormReasons(token: $token) {
      contactFormReasons {
        id
        key
        label
        applicableB2B
        applicableB2C
        applicableServiceView
        applicableWebsite
        applicableToWebsiteForLead
      }
      majaId
      williId
      status
    }
  }
`;
export const GetNonCommodityProductsDocument = gql`
  query getNonCommodityProducts($campaign: String!, $productCodes: [String]!) {
    nonCommodityProductsByCampaign(campaignIdentifier: $campaign, where: { productCode: { in: $productCodes } }) {
      ...NonCommodityProductPart
    }
  }
  ${NonCommodityProductPartFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    submitCategorizedContactForm(
      variables: SubmitCategorizedContactFormMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SubmitCategorizedContactFormMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubmitCategorizedContactFormMutation>(SubmitCategorizedContactFormDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'submitCategorizedContactForm',
        'mutation',
        variables,
      );
    },
    eaasCreateMarketingLead(
      variables: EaasCreateMarketingLeadMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EaasCreateMarketingLeadMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EaasCreateMarketingLeadMutation>(EaasCreateMarketingLeadDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'eaasCreateMarketingLead',
        'mutation',
        variables,
      );
    },
    getCities(
      variables?: GetCitiesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCitiesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCitiesQuery>(GetCitiesDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'getCities',
        'query',
        variables,
      );
    },
    getContactFormReasons(
      variables?: GetContactFormReasonsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetContactFormReasonsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetContactFormReasonsQuery>(GetContactFormReasonsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getContactFormReasons',
        'query',
        variables,
      );
    },
    getNonCommodityProducts(
      variables: GetNonCommodityProductsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetNonCommodityProductsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetNonCommodityProductsQuery>(GetNonCommodityProductsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getNonCommodityProducts',
        'query',
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
