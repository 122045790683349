import styled from '@lichtblick/styled';
import { MediaMediumAndAbove } from '@lichtblick/theme';

import { AImage } from '../AImage';

export const Image = styled(AImage)<{ $isBackground?: boolean }>`
  &,
  & > img {
    display: block;
    height: ${({ $isBackground }) => ($isBackground ? '100%' : 'auto')};
    object-fit: cover;
    width: 100%;
  }

  ${MediaMediumAndAbove} {
    display: none;
  }
`;

export const Video = styled.video<{ $isBackground?: boolean; $isVisible?: boolean }>`
  aspect-ratio: ${({ height, width }) => `${width} / ${height}`};
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  height: ${({ $isBackground }) => ($isBackground ? '100%' : 'auto')};
  object-fit: cover;
  width: 100%;
`;
