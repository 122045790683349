import { AButtonType, MultiLinkType } from '../types/storyblok';

export const buildUrl = (
  entry: Exclude<MultiLinkType, { linktype?: 'email' } | { linktype?: 'asset' }>,
  linkUrl?: string,
): string | undefined => {
  return `/${entry.cached_url}` ?? linkUrl;
};

export const getHref = ({ linkAsset, linkEntry, linkUrl }: AButtonType): string =>
  (linkEntry?.cached_url ? `/${linkEntry?.cached_url}${linkUrl ? linkUrl : ''}` : linkUrl) ?? linkAsset?.filename ?? '';
