import { FormEvent, useState } from 'react';

import { HeatingEnergyIconM } from '@lichtblick/icons/svg/heating-energy/heating-energy-m';
import { Colors } from '@lichtblick/theme';
import { useTracker } from '@lichtblick/tracker';
import { BoxCTA, RadioGroup, RadioInput } from '@lichtblick/ui-components';

import { CalculatorLayout } from './CalculatorLayout';
import { StyledCalculatorContent, StyledCalculatorInputRow, StyledRadioInputWrapper } from './CalculatorLayout.styles';
import { CityInput } from './CityInput';
import { BaseCalculatorProps } from './OAllInOneCalculator';
import { CalculatorTranslations } from './translations';
import { useVpCodes } from './useVpCodes';

import { buildUrl } from '../../helpers';
import { HeatingCalculatorType } from '../../types';

type FormValues = {
  city?: string;
  postcode?: string;
  sourceOfHeat?: string;
};

type HeatingCalculatorProps = BaseCalculatorProps & HeatingCalculatorType;

export const HeatingCalculator: React.FC<HeatingCalculatorProps> = ({
  _uid,
  checkoutUrl,
  chip,
  directSalesPartnerCode,
  headline,
  isActionTriggerDisabled,
  powerCloudCampaignId,
  sourceOfHeat,
  useDirectSalesPartnerCodeSitewide,
}) => {
  const { trackCustomEvent } = useTracker();

  const { computeVpCodes } = useVpCodes();
  const [values, setValues] = useState<FormValues>({
    sourceOfHeat: sourceOfHeat,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    trackCustomEvent({
      eventName: 'tariff_calculator_show_tariffs',
      customProps: {
        product: 'Wärmepumpe',
        product_variant: values.sourceOfHeat === 'storage_heater' ? 'Wärmpeumpe' : 'Nachtspeicherheizung',
        zip_code: values.postcode,
      },
    });

    const vpCodes = computeVpCodes(directSalesPartnerCode, useDirectSalesPartnerCodeSitewide);

    const params = new URLSearchParams({
      ...(values?.city && { ort: values.city }),
      ...(values?.postcode && { plz: values.postcode }),
      ...(values?.sourceOfHeat && { heizquelle: values.sourceOfHeat }),
      ...(powerCloudCampaignId && { cam: powerCloudCampaignId }),
      ...(vpCodes?.vp && { vp: vpCodes.vp }),
      ...(vpCodes?.uvp && { uvp: vpCodes.uvp }),
    });

    location.assign(`${buildUrl(checkoutUrl) ?? '/waermestrom-checkout/'}?${params.toString()}`);
  };

  return (
    <CalculatorLayout
      chip={chip}
      headerColor={Colors.LightBerry}
      headline={headline}
      icon={<HeatingEnergyIconM />}
      id={_uid}
      isActionTriggerDisabled={isActionTriggerDisabled}
    >
      <StyledCalculatorContent method="get" onSubmit={handleSubmit}>
        <RadioGroup label="Womit heizt du?">
          <StyledCalculatorInputRow>
            <StyledRadioInputWrapper>
              <RadioInput
                defaultChecked={values.sourceOfHeat === 'heat_pump'}
                label="Wärmepumpe"
                name="sourceOfHeat"
                onChange={(event) => setValues((prev) => ({ ...prev, sourceOfHeat: event.target.value }))}
                onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
                required
                value="heat_pump"
              />
            </StyledRadioInputWrapper>
            <StyledRadioInputWrapper>
              <RadioInput
                defaultChecked={values.sourceOfHeat === 'storage_heater'}
                label="Nachtspeicherheizung"
                name="sourceOfHeat"
                onChange={(event) => setValues((prev) => ({ ...prev, sourceOfHeat: event.target.value }))}
                onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
                required
                value="storage_heater"
              />
            </StyledRadioInputWrapper>
          </StyledCalculatorInputRow>
        </RadioGroup>
        <CityInput onChange={(city, postcode) => setValues((prev) => ({ ...prev, city: city, postcode: postcode }))} />
        <BoxCTA as="button" data-testid="calc-submit" isWide type="submit">
          {CalculatorTranslations.heatSubmitLabel}
        </BoxCTA>
      </StyledCalculatorContent>
    </CalculatorLayout>
  );
};
