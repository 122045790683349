import { AppProps } from 'next/app';
import { withRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import { ConsentScript, ConsentState, DATADOG_RUM_NAME, getConsentState, useConsentState } from '@lichtblick/consent';
import { initReactComponents } from '@lichtblick/contentful';
import { SettingsContext } from '@lichtblick/contentful/helpers/settingsContext';
import { ArrowRightUpIconS } from '@lichtblick/icons/svg/arrow-right-up/arrow-right-up-s';
import { logger } from '@lichtblick/logger';
import { TrackerProvider, TrackingScript } from '@lichtblick/tracker';
import { BoxCTA, HugoAndAppRoutesContext } from '@lichtblick/ui-components';

import { type StaticProps } from './[[...slug]]';

import hugoAndAppRoutes from '../.tmp/hugoAndAppRedirectRoutes.json';
import { PageHead } from '../components';
import { ReactComponentTracking } from '../helpers/ReactComponentTracking';
import { UrlParamsInitializer } from '../helpers/UrlParamsInitializer';

const CONSENT_BLACKLIST = ['/impressum', '/datenschutz'];

type MyAppProps = AppProps<StaticProps>;

const initStoryBlokBridge = () => {
  if ('StoryblokBridge' in window) {
    const storyblokInstance = new (window.StoryblokBridge as any)();

    storyblokInstance.on(['published', 'change'], () => {
      window.location.reload();
    });
  }
};

const MyApp = ({ Component, pageProps }: MyAppProps) => {
  const slug = pageProps.slug;
  const [datadogRumState] = useConsentState(DATADOG_RUM_NAME);

  useEffect(() => {
    initReactComponents();
  }, [slug]);

  useEffect(() => {
    const applicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
    const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;

    if (datadogRumState !== ConsentState.Given) {
      return;
    }

    import('@datadog/browser-rum').then(({ datadogRum }) => {
      try {
        if (!datadogRum.getInitConfiguration() && applicationId && clientToken) {
          datadogRum.init({
            applicationId,
            clientToken,
            site: 'datadoghq.eu',
            service: process.env.NEXT_PUBLIC_DATADOG_SERVICE_NAME || 'frontend-next',
            env: process.env.NEXT_PUBLIC_STAGE,
            version: process.env.COMMIT_HASH,
            sessionReplaySampleRate: 100,
            startSessionReplayRecordingManually: true,
            sessionSampleRate: 100,
            storeContextsAcrossPages: true,
            trackUserInteractions: true,
            trackResources: true,
            allowedTracingUrls: [/https:\/\/graph(tst|dev|)\.lichtblick\.de.*/],
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            proxy: (options) => `https://logging.lichtblick.app/proxy${options.path}?${options.parameters}`,
            beforeSend: (event) => {
              if (getConsentState(DATADOG_RUM_NAME) !== ConsentState.Given && event.type !== 'view') {
                return false;
              }

              //Filter out load static props error until fixed to reduce noise
              if (event.type === 'error' && event.error.message === 'Error: Failed to load static props') {
                return false;
              }

              return true;
            },
          });
        }
      } catch (error) {
        logger.error('datadog RUM error', { error });
      }
    });
  }, [datadogRumState]);

  const isFrameContent = pageProps.story?.content.component === 't-frame-content';

  return (
    <>
      {pageProps.isPreview && (
        <Script
          onLoad={initStoryBlokBridge}
          src="//app.storyblok.com/f/storyblok-v2-latest.js"
          type="text/javascript"
        />
      )}
      <Script
        async
        data-testid="optimizely-script"
        data-usercentrics="Optimizely"
        src="https://cdn.optimizely.com/js/27307200257.js"
        type="text/plain"
      />
      <Script
        async
        data-usercentrics="HubSpot"
        defer
        id="hs-script-loader"
        src="//js-eu1.hs-scripts.com/143379264.js"
        type="text/plain"
      />
      <SettingsContext.Provider value={pageProps.settings}>
        <HugoAndAppRoutesContext.Provider value={hugoAndAppRoutes}>
          {(slug && CONSENT_BLACKLIST.includes(slug)) || isFrameContent ? null : (
            <>
              <ConsentScript />
              <TrackingScript />
            </>
          )}
          <PageHead story={pageProps.story?.content} />
          <TrackerProvider>
            <UrlParamsInitializer />
            <ReactComponentTracking path={slug ?? ''} />
            <Component {...pageProps} />
            {Boolean(pageProps.isPreview && pageProps.previewReleaseId) && (
              <div style={{ position: 'fixed', left: '1rem', bottom: '1rem' }}>
                <BoxCTA
                  as="button"
                  icon={<ArrowRightUpIconS />}
                  isSlim
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://preview.lichtblick.de/api/preview${slug}?_storyblok_release=${pageProps.previewReleaseId}`,
                    );
                  }}
                  type="button"
                  variant="secondary"
                >
                  Copy preview link
                </BoxCTA>
              </div>
            )}
          </TrackerProvider>
        </HugoAndAppRoutesContext.Provider>
      </SettingsContext.Provider>
    </>
  );
};

export default withRouter(MyApp);
