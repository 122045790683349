import { FC } from 'react';

import { HeaderNavigation, HomeLink, Root, Section } from './Header.styles';
import { HeaderMetaNavigationItem } from './MetaNavigationItem';
import { HeaderTopNavigationItem } from './TopNavigationItem';

import { Logo } from '../../atoms/Logo/Logo';
import { NavigationItemProps } from '../../types';

export type HeaderProps = {
  metaNavigation: NavigationItemProps[];
  primaryNavigation: NavigationItemProps[];
};

export const Header: FC<HeaderProps> = ({ metaNavigation, primaryNavigation }) => (
  <Root>
    <Section>
      <HomeLink href="/">
        <Logo />
      </HomeLink>
      <HeaderNavigation $isTopNavigation>
        {primaryNavigation.map((item) => (
          <HeaderTopNavigationItem
            href={item.href}
            icon={item.icon}
            isActive={item.isActive}
            items={item.items}
            key={item.text}
            onClick={item.onClick}
            setIsFlyoutActive={item.setIsFlyoutActive}
            shouldOpenInNewTab={item.shouldOpenInNewTab}
            text={item.text}
            tracking={item.tracking}
          />
        ))}
      </HeaderNavigation>
      <HeaderNavigation>
        {metaNavigation.map((item) => (
          <HeaderMetaNavigationItem
            href={item.href}
            icon={item.icon}
            isActive={item.isActive}
            items={item.items}
            key={item.text}
            onClick={item.onClick}
            setIsFlyoutActive={item.setIsFlyoutActive}
            shouldOpenInNewTab={item.shouldOpenInNewTab}
            text={item.text}
            tracking={item.tracking}
          />
        ))}
      </HeaderNavigation>
    </Section>
  </Root>
);
