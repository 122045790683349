import Head from 'next/head';
import { FC, useEffect } from 'react';

import { ConsentState, useConsentState } from '@lichtblick/consent';

import { componentMapper } from '../../helpers/componentMapper';
import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, TNotificationType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { OStandoutText } from '../OStandoutText';

const EEC_PURCHASE_SESSION_STORAGE_KEYS = ['EECpurchase', 'GA4EECpurchase', 'GA4EMobilityContactForm'];

export const TNotification: FC<WithMetaData<StoryblokStoryType<TNotificationType>>> = ({
  content: { breadcrumbTitle, footnotes, metaTitle, partials, standoutText, trackingPageArea, trackingPageType },
  parentPages,
  slug,
}) => {
  useTemplateTracker({
    pageArea: trackingPageArea ?? '',
    pageType: trackingPageType?.substring(2) ?? '',
    pageTitle: metaTitle ?? '',
  });

  const [consent] = useConsentState('Google Analytics');
  const isTrackingEnabled = consent === ConsentState.Given;

  useEffect(() => {
    const interval = setInterval(() => {
      const firedEvents = window.dataLayer.map((event) => event.event);

      if (!['consent_status', 'virtPath', 'Page Meta'].every((event) => firedEvents.includes(event))) {
        return;
      }

      clearInterval(interval);

      // due to timing issues the old checkouts store certain events in session storage
      // and rely on the order confirmation page to send them to the data layer
      EEC_PURCHASE_SESSION_STORAGE_KEYS.forEach((storageKey) => {
        const eventJson = sessionStorage.getItem(storageKey);

        if (isTrackingEnabled || !eventJson) {
          return;
        }

        try {
          const trackingEvent = JSON.parse(eventJson);

          if (trackingEvent && window.dataLayer) {
            window.dataLayer.push(trackingEvent);
            sessionStorage.removeItem(storageKey);
          }
        } catch (e) {
          //
        }
      });
    }, 100);
  }, [isTrackingEnabled]);

  return (
    <>
      <Head>
        <meta content="noindex, nofollow" name="robots" />
      </Head>
      <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
        {standoutText?.[0] && <OStandoutText {...standoutText[0]} />}
        {partials?.map((item) => item && componentMapper(item, item._uid))}
        {footnotes?.map((item) => item && componentMapper(item, item._uid))}
      </Layout>
    </>
  );
};
