import styled, { css } from '@lichtblick/styled';
import { BorderRadius, Colors, DurationAndEasing, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Headline, focusRing } from '@lichtblick/ui-components';
import { Link } from '@lichtblick/ui-components';

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing.Xxs};
`;

export const TeaserListItem = styled(Link)<{ $themeColor?: string }>`
  border-radius: ${BorderRadius.S};
  display: block;
  padding: ${Spacing.Xs};
  position: relative;

  ${MediaMediumAndAbove} {
    padding: ${Spacing.Xs};
  }

  &::before,
  &::after {
    border-radius: ${BorderRadius.S};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::after {
    border: 2px solid;
    border-color: ${Colors.Gray};
    border-width: 1px;
  }

  &::before {
    ${({ $themeColor }) =>
      $themeColor === 'white' &&
      css`
        background-color: ${Colors.FadedGray};
      `}
    ${({ $themeColor }) =>
      $themeColor === 'faded-gray' &&
      css`
        background-color: ${Colors.White};
      `}
    opacity: 0;
    pointer-events: none;
    transform: translate(-0.22222em, 0.22222em);
    transition:
      transform ${DurationAndEasing.SmallIntro},
      opacity ${DurationAndEasing.SmallIntro};
  }

  &:active::before,
  &:hover::before {
    opacity: 1;
  }

  &:focus-visible {
    outline: none;

    &::after {
      ${focusRing}
    }
  }

  &:active {
    &::after {
      outline: none;
    }

    &::before {
      transform: translate(0, 0);
    }
  }
`;

export const Content = styled.div`
  position: relative;
`;

export const Meta = styled.div<{ $isExternalLink: boolean }>`
  color: ${Colors.DarkGray};
  display: block;
  font-size: 0.75rem;
  line-height: 1.7;

  ${({ $isExternalLink }) =>
    $isExternalLink &&
    css`
      &::after {
        content: '\\2197';
        position: absolute;
        right: 0;
        color: ${Colors.DarkGray};
      }
    `}

  width: 100%;
`;

export const MetaValue = styled.span`
  &::before {
    content: '|';
    margin-inline: ${Spacing.Xs};
  }

  &:first-of-type::before {
    content: none;
  }
`;
