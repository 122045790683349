import { reloadOnWithdrawal } from './reloadOnWithdrawal';
import { CONSENT_STATUS_CHANGE, UC_UI_INITIALIZED, UsercentricsSingleton } from './UsercentricsSingleton.types';

const UC_UI_INITIALIZATION_TIMEOUT = 5 * 1000;

export const buildUsercentricsInitializationPromise = (): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    if (window.UC_UI) {
      window.addEventListener(CONSENT_STATUS_CHANGE, reloadOnWithdrawal);

      resolve();

      return;
    }

    // eslint-disable-next-line prefer-const
    let onInitializedCallback: () => void; // no `const` because of circular dependency below

    const timeout = setTimeout(() => {
      window.removeEventListener(UC_UI_INITIALIZED, onInitializedCallback);
      reject(new Error('failed to load usercentrics UI'));
    }, UC_UI_INITIALIZATION_TIMEOUT);

    onInitializedCallback = (): void => {
      if (window.UC_UI) {
        clearTimeout(timeout);
        window.removeEventListener(UC_UI_INITIALIZED, onInitializedCallback);
        window.addEventListener(CONSENT_STATUS_CHANGE, reloadOnWithdrawal);
        resolve();
      }
    };

    window.addEventListener(UC_UI_INITIALIZED, onInitializedCallback);
  });

export class UcUiImplementation implements UsercentricsSingleton {
  initializationPromise?: Promise<void>;
  getConsentStatus(serviceName: string, changeEvent?: CustomEvent): boolean | undefined {
    const consentStatus = changeEvent?.detail[serviceName];

    if (typeof consentStatus === 'boolean') {
      return consentStatus;
    }

    const service = window.UC_UI?.getServicesBaseInfo().find(({ name }) => name === serviceName);

    return service?.consent.status;
  }
  getControllerId(): string | undefined {
    return window.UC_UI?.getControllerId();
  }
  async init(): Promise<void> {
    if (!this.initializationPromise) {
      this.initializationPromise = buildUsercentricsInitializationPromise();
    }

    return this.initializationPromise;
  }

  isInitialized(): boolean {
    return Boolean(window.UC_UI && window.UC_UI.isInitialized());
  }

  updateConsentStatus(serviceName: string, isAccepted: boolean): Promise<void> {
    if (!window.UC_UI) {
      return Promise.reject(new Error(`${serviceName} Consent update failed: usercentrics not initialized`));
    }

    const serviceId = window.UC_UI.getServicesBaseInfo().find(({ name }) => name === serviceName)?.id;

    if (!serviceId) {
      return Promise.reject(new Error(`${serviceName} Consent update failed: Service not found`));
    }

    return (isAccepted ? window.UC_UI.acceptService : window.UC_UI.rejectService)(serviceId);
  }
}
