'use client';

import { useState, useEffect } from 'react';

import { UC_UI_INITIALIZED, getControllerId } from '../UsercentricsSingleton';

export const useUsercentricsControllerId = (): string | undefined => {
  const [controllerId, setControllerId] = useState(getControllerId());

  useEffect(() => {
    const onInitializedCallback = (): void => {
      const initializedControllerId = getControllerId();

      setControllerId(initializedControllerId);
    };

    window.addEventListener(UC_UI_INITIALIZED, onInitializedCallback);

    return () => window.removeEventListener(UC_UI_INITIALIZED, onInitializedCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return controllerId;
};
