'use client';

import { useState, useEffect } from 'react';

import {
  ConsentState,
  CONSENT_STATUS_CHANGE,
  getConsentState,
  setConsent,
  UserCentricsServiceName,
} from '../UsercentricsSingleton';

export const useConsentState = (
  serviceName: UserCentricsServiceName,
): [ConsentState, (status: ConsentState.Given | ConsentState.Denied) => Promise<void>] => {
  const [consentState, setConsentState] = useState(getConsentState(serviceName));

  const setServiceConsent = (state: ConsentState.Given | ConsentState.Denied): Promise<void> => {
    setConsentState(state);

    return setConsent(serviceName, state);
  };

  useEffect(() => {
    const handleConsentChange = (event: any): void => {
      const currentState = getConsentState(serviceName, event);

      setConsentState(currentState);
    };

    window.addEventListener(CONSENT_STATUS_CHANGE, handleConsentChange);

    return () => window.removeEventListener(CONSENT_STATUS_CHANGE, handleConsentChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceName]);

  return [consentState, setServiceConsent];
};
