import { MouseEventHandler, useEffect } from 'react';

import { AKLAMIO_NAME, ConsentState, useConsentState } from '@lichtblick/consent';
import { LegacyButton, Loader } from '@lichtblick/ui-components';

import { Privacy, StyledHeadline, StyledText, Wrapper } from './OReferral.styles';

import { AButtonType } from '../../types/storyblok';
import { AButton } from '../AButton';

const SCRIPT_URL = 'https://api.aklamio.com/assets/embed/1.latest/embed.min.js';
const AKLAMIO_UID = 'b0ced78f5f228b0fc1f15252249a188d';

export type OReferralProps = {
  privacyButton: string | null | undefined;
  privacyHeadline: string | null | undefined;
  privacyLink: AButtonType | null | undefined;
  privacyText: string | null | undefined;
};

export const OReferral = ({ privacyButton, privacyHeadline, privacyLink, privacyText }: OReferralProps) => {
  const [consentState] = useConsentState(AKLAMIO_NAME);

  const openUsercentricsSecondLayer: MouseEventHandler<Element> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.UC_UI?.showSecondLayer();
  };

  useEffect(() => {
    if (consentState === ConsentState.Given) {
      const script = document.createElement('script');

      script.src = SCRIPT_URL;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }, [consentState]);

  const referral = (
    <Wrapper data-aklamio-embed="true" data-uid={AKLAMIO_UID}>
      <Loader />
    </Wrapper>
  );

  const privacyLayer = (
    <Privacy>
      {privacyHeadline && (
        <StyledHeadline renderAs="h2" size="S">
          {privacyHeadline}
        </StyledHeadline>
      )}
      {privacyText && <StyledText renderAs="p"> {privacyText} </StyledText>}
      {privacyButton && (
        <LegacyButton onClick={openUsercentricsSecondLayer} variant="secondary">
          {privacyButton}
        </LegacyButton>
      )}
      {privacyLink && <AButton {...privacyLink} isSlim variant="link" />}
    </Privacy>
  );

  return consentState !== ConsentState.Unknown && consentState === ConsentState.Given ? referral : privacyLayer;
};
