import { FC, MouseEventHandler } from 'react';

import '@lichtblick/consent';
import {
  FooterBrandText,
  Footer as FooterComponent,
  FooterTeaser,
  NavigationItemProps,
  Text,
} from '@lichtblick/ui-components';

import { StyledLink, StyledText } from './Footer.styles';

import { richTextMapper } from '../../helpers';
import { Settings } from '../../helpers/settingsContext';
import { useNavigationItems } from '../../hooks';
import { MTeaserItem } from '../MTeaserItem';

const openUsercentricsSecondLayer: MouseEventHandler<HTMLAnchorElement> = (event) => {
  event.preventDefault();
  event.stopPropagation();
  window.UC_UI?.showSecondLayer();
};

const attachUsercentricsClickHandler = (item: NavigationItemProps): NavigationItemProps => ({
  ...item,
  ...(item.href === '#uc-central-modal-show' ? { onClick: openUsercentricsSecondLayer } : {}),
  items: item.items?.map(attachUsercentricsClickHandler),
});

export type FooterProps = Settings & { hasLogo?: boolean; isPrimaryNavigationHidden?: boolean };

export const Footer: FC<FooterProps> = ({
  footerBrandText,
  footerContactContactForm,
  footerContactEmail,
  footerContactHeadline,
  footerContactOpeningHours,
  footerContactPhone,
  footerContactText,
  footerPrimaryNavigation,
  footerSecondaryNavigation,
  footerTeaser,
  hasLogo,
  isPrimaryNavigationHidden,
}) => {
  const primaryNavigation = useNavigationItems(footerPrimaryNavigation ?? []);
  const secondaryNavigation = useNavigationItems(footerSecondaryNavigation ?? []);
  const secondaryNavigationWithUCClickHandler = secondaryNavigation.map(attachUsercentricsClickHandler);

  return (
    <FooterComponent
      contact={{
        headline: footerContactHeadline ?? undefined,
        text: footerContactText ?? undefined,
        formUrl: footerContactContactForm ?? undefined,
        email: footerContactEmail ?? undefined,
        phone: footerContactPhone ?? undefined,
        openingHours: footerContactOpeningHours ?? undefined,
      }}
      hasLogo={hasLogo}
      isPrimaryNavigationHidden={isPrimaryNavigationHidden}
      primaryNavigation={primaryNavigation}
      secondaryNavigation={secondaryNavigationWithUCClickHandler}
    >
      <FooterBrandText>
        {footerBrandText &&
          richTextMapper(footerBrandText, {
            p: ({ children }) => <StyledText>{children}</StyledText>,
            a: ({ children, href }) => (
              <Text size="M">
                <StyledLink href={href ?? '#'}>{children}</StyledLink>
              </Text>
            ),
          })}
      </FooterBrandText>
      <FooterTeaser>{footerTeaser?.[0] && <MTeaserItem {...footerTeaser[0]} accentColor="dark-moss" />}</FooterTeaser>
    </FooterComponent>
  );
};
