import styled, { css } from '@lichtblick/styled';
import { MediaMediumAndAbove, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { StyledTextIntro } from '@lichtblick/ui-components';

import { getColumnWidth } from '../shared';

export const GridItem = styled.div<{ $index: number }>`
  margin-bottom: 3.5rem;

  ${MediaSmallAndAbove} {
    width: calc(100% - ${Spacing.Xxs});

    ${({ $index }) => {
      switch ($index) {
        case 0:
          return css`
            padding-left: ${getColumnWidth(2)};
          `;
        case 1:
          return css`
            padding: ${getColumnWidth(2)} calc(${getColumnWidth(2)} - ${Spacing.Xs}) 0
              calc(${getColumnWidth(2)} + ${Spacing.Xs});
          `;
        case 2:
          return css`
            padding-left: ${getColumnWidth(4)};
          `;
      }
    }}
  }
`;

export const StyledOFactGrid = styled.div`
  ${StyledTextIntro} {
    margin-bottom: ${Spacing.M};

    ${MediaMediumAndAbove} {
      margin-bottom: ${Spacing.L};
    }
  }
`;
