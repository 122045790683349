import { Icon } from '@lichtblick/icons';
import styled, { css } from '@lichtblick/styled';
import { BorderRadius, Colors, DurationAndEasing, Spacing } from '@lichtblick/theme';
import { AccentText } from '@lichtblick/ui-components';

const cover = css`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const StyledIcon = styled(Icon)<{ $color: Colors; $isToggled: boolean }>`
  color: ${({ $color }) => $color};

  ${({ $isToggled }) =>
    $isToggled &&
    css`
      transform: rotate(45deg);
    `}

  position: absolute;
  right: 1rem;
  top: 1rem;
  transition:
    transform ${DurationAndEasing.LargeOutro},
    opacity ${DurationAndEasing.LargeOutro};
  z-index: 2;
`;

export const Card = styled.div`
  display: inline-block;
  margin-bottom: ${Spacing.Xs};
  position: relative;

  @media (hover: hover) {
    &:hover > ${StyledIcon} {
      opacity: 0;
    }
  }
`;

export const Media = styled.div<{ $isToggled: boolean }>`
  border-radius: ${BorderRadius.S};
  overflow: hidden;
  pointer-events: none;
  transform: ${({ $isToggled }) => ($isToggled ? 'translate(0, 0)' : 'translate(-0.5rem, 0.5rem)')};
  transition: transform ${DurationAndEasing.LargeOutro};

  @media (hover: hover) {
    ${Card}:hover > & {
      transform: translate(0, 0);
    }
  }
`;

export const Description = styled.div<{ $backgroundColor?: Colors; $color: Colors; $isToggled: boolean }>`
  align-items: center;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${BorderRadius.S};
  color: ${({ $color }) => $color};
  display: flex;
  ${cover};
  opacity: ${({ $isToggled }) => ($isToggled ? 1 : 0)};
  padding: ${Spacing.M};
  transition: opacity ${DurationAndEasing.LargeOutro};
  z-index: 1;

  @media (hover: hover) {
    ${Card}:hover > & {
      opacity: 1;
    }
  }
`;

export const Frame = styled.div<{ $color: Colors }>`
  border: 2px solid;
  border-radius: ${BorderRadius.S};
  color: ${({ $color }) => $color};
  ${cover};
`;

export const Toggle = styled.div`
  margin: 0;
  ${cover};
  opacity: 0;
  z-index: 2;

  @media (hover: hover) {
    display: none;
  }
`;

export const Caption = styled(AccentText)`
  display: block;
`;
