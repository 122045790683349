import styled from '@lichtblick/styled';
import { Colors, MediaMediumAndAbove, Spacing, BorderRadius, FontWeights } from '@lichtblick/theme';
import { StyledHeadline } from '@lichtblick/ui-components';

import { AText } from '../AText';
import { StyledCard } from '../MEAutoTariffCard/MEAutoTariffCard.styles';
import { GridColumn, GridContainer, GridRow } from '../shared';

export const GridRowExtend = styled(GridRow)`
  margin: 0 0 0 3rem;
`;

export const GridContainerCards = styled(GridContainer)`
  max-width: 100% !important;
  width: 100%;
  margin: 0;
`;

export const Banner = styled(GridRow)`
  margin: 0;
  background-color: ${Colors.Moss};
  justify-content: center;
  padding: ${Spacing.M} ${Spacing.S};
  padding-bottom: ${Spacing['3Xl']};

  ${MediaMediumAndAbove} {
    padding: ${Spacing.M};
    padding-bottom: ${Spacing['3Xl']};
  }
`;

export const StyledColumn = styled(GridColumn)`
  display: flex;
  flex-flow: column nowrap;
  gap: ${Spacing.Xs};
  align-items: center;
`;

export const Badge = styled(GridRow)`
  background-color: ${Colors.FadedMoss};
  width: min-content;
  white-space: nowrap;
  border-radius: ${BorderRadius.Xs};
  color: ${Colors.DarkMoss};
  font-weight: ${FontWeights.Bold};
  font-size: 12px;
  line-height: 20px;
  padding: 0 4px;
`;

export const Headline = styled(StyledHeadline)`
  color: ${Colors.White};
  text-align: center;
`;

export const Description = styled(AText)`
  color: ${Colors.White};
  text-align: center;
`;

export const ComparisonCards = styled(GridRow)`
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: ${Spacing.M};
  background-color: ${Colors.BackgroundGray};
  padding-bottom: ${Spacing.M};

  ${MediaMediumAndAbove} {
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;
    gap: ${Spacing.Xxl};
  }

  & > ${StyledCard} {
    margin-top: -${Spacing.Xl};
  }
`;
