import styled from '@lichtblick/styled';
import { BorderRadius, Colors, MediaDesktop, Spacing } from '@lichtblick/theme';

import { MessageType } from './StatusMessage.types';

export const StyledStatusMessage = styled.div<{ $backgroundColor: Colors; $color: Colors; type: MessageType }>`
  align-items: center;
  display: flex;
  gap: ${Spacing.Xxs};

  svg {
    align-self: flex-start;
    margin-top: 0.125rem;
    flex-shrink: 0;
    ${MediaDesktop} {
      margin-top: ${Spacing['3Xs']};
    }
  }

  & > span:only-child {
    margin: auto;
    text-align: center;
  }

  ${({ $backgroundColor, $color }) => `
      background-color: ${$backgroundColor};
      border-color: ${$color};
      color: ${$color};
    `}
  ${({ type }) => {
    switch (type) {
      case 'outline':
        return `
          padding: ${Spacing.Xxs};
          background-color: transparent;
          border: 2px solid;
          border-radius: ${BorderRadius.S};
          padding: ${Spacing.Xxs};
        `;
      case 'solid':
        return `
          padding: ${Spacing.Xxs};
          border-radius: ${BorderRadius.S};
        `;
      case 'plain':
      default:
        return `
          background-color: transparent;
        `;
    }
  }}
`;
