import { FC } from 'react';

import { Spacing } from '@lichtblick/theme/constants/spacing';
import { Box } from '@lichtblick/ui-components';

import { ArchiveRow, DownloadItem, StyledMasonryGrid, TextColumn } from './ODownload.styles';

import { MDownloadItemType, ODownloadType } from '../../types/storyblok';
import { getColor, getSpacing } from '../../utils';
import { AButton } from '../AButton';
import { MTextIntro } from '../MTextIntro';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

const checkIfImageFile = (file: MDownloadItemType) =>
  file?.asset?.length === 1 && ['jpg', 'tif', 'png'].includes(file.asset[0].ext ?? '');

export const ODownload: FC<ODownloadType> = ({
  anchorId,
  archive,
  downloadItems,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
  textIntro,
  theme,
}) => {
  const hasOnlyOneItem = downloadItems.length <= 1;
  const archiveDownloadUrl = archive?.[0]?.downloadUrl;
  const gridPush = hasOnlyOneItem ? 2 : undefined;
  const gridWidth = hasOnlyOneItem ? 8 : 12;
  const imageItems: JSX.Element[] = [];
  let fileItems: JSX.Element[] = [];

  downloadItems.forEach((item) => {
    if (item) {
      const collection = checkIfImageFile(item) ? imageItems : fileItems;

      collection.push(<DownloadItem {...item} key={item.headline} theme={theme} />);
    }
  });

  if (imageItems.length % 2 === 1 && fileItems.length > 1) {
    fileItems = [<div key={fileItems.length}>{fileItems.slice(0, 3)}</div>, ...fileItems.slice(3)];
  }

  const items = [...imageItems, ...fileItems];

  return (
    <Box
      backgroundColor={getColor(theme)}
      data-testid="download"
      pbd={getSpacing(paddingBottomDesktop) ?? Spacing.M}
      pbm={getSpacing(paddingBottomMobile) ?? Spacing.Xs}
      ptd={getSpacing(paddingTopDesktop) ?? Spacing.M}
      ptm={getSpacing(paddingTopMobile) ?? Spacing.Xs}
      width="100%"
    >
      <GridContainer>
        {anchorId && <AnchorMark anchorId={anchorId} />}
        {textIntro?.length === 1 && (
          <GridRow>
            <TextColumn $push={gridPush} $width={gridWidth}>
              {/* TODO: CHECK is this change here correct or did we really want to map to a ODownload Item */}
              {<MTextIntro {...textIntro[0]} buttonVariant={'link'} isCenteredDesktop isCenteredMobile />}
            </TextColumn>
          </GridRow>
        )}
        <GridRow>
          <GridColumn $push={gridPush} $width={gridWidth}>
            <StyledMasonryGrid $hasOnlyOneItem={hasOnlyOneItem} columnCount={hasOnlyOneItem ? 1 : 2} items={items} />
          </GridColumn>
        </GridRow>
        {archiveDownloadUrl && (
          <ArchiveRow>
            <GridColumn $push={gridPush} $width={gridWidth}>
              {/* TODO: Check if this change was correct */}
              <AButton
                _uid="archive-button-static-id"
                component="a-button"
                isDownloadLink={true}
                linkUrl={archiveDownloadUrl as string}
                openAsDownload={true}
                text={'Alle Dateien herunterladen'}
                variant="link"
              />
            </GridColumn>
          </ArchiveRow>
        )}
      </GridContainer>
    </Box>
  );
};
