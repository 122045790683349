import { ChevronDownIconXs } from '@lichtblick/icons/svg/chevron-down/chevron-down-xs';
import styled, { css } from '@lichtblick/styled';
import { Colors, MediaSmallAndAbove, Opacities, Spacing, ZIndex } from '@lichtblick/theme';

import { AccentText } from '../../atoms/AccentText/AccentText';
import { Container } from '../../atoms/Container/Container';

const durationAndEasing = `0.5s cubic-bezier(0.4, 0, 0.2, 1)`;

export const Root = styled.div`
  background-color: ${Colors.Black};
  position: sticky;
  top: 0;
  z-index: ${ZIndex.SecondaryNavigation};
`;

export const Navigation = styled.menu<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  left: 0;
  list-style: none;
  max-height: ${({ $isOpen }) => ($isOpen ? '50vh' : '0')};
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: max-height ${durationAndEasing};
  width: 100%;

  ${MediaSmallAndAbove} {
    flex-direction: row;
    margin: 0 0 0 auto;
    max-height: none;
    overflow: visible;
    position: static;
    width: auto;
  }
`;

export const Section = styled(Container)`
  align-items: center;
  background-color: ${Colors.Black};
  color: ${Colors.White};
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const ButtonWrapper = styled.div<{
  $isButtonIntroOnDesktop: boolean;
  $isVisible: boolean;
}>`
  background-color: ${Colors.White};
  color: ${Colors.Black};
  left: 0;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  padding: ${Spacing.Xs} ${Spacing.Xs} calc(${Spacing.Xs} + env(safe-area-inset-bottom));
  position: fixed;
  top: 100%;
  transform: ${({ $isVisible }) => ($isVisible ? 'translateY(-100%)' : 'unset')};
  transition:
    opacity 0.1s ease-out,
    transform ${durationAndEasing};
  white-space: nowrap;
  width: 100%;

  ${MediaSmallAndAbove} {
    background: ${Colors.Black};
    color: ${Colors.White};
    /* stylelint-disable-next-line meowtec/no-px */
    margin: -3px 0 3px ${Spacing.M};

    ${({ $isButtonIntroOnDesktop }) =>
      $isButtonIntroOnDesktop &&
      css`
        margin-left: 0;
        max-width: 0;
        transition:
          opacity 0.1s 0s ease-out,
          max-width ${durationAndEasing};
      `}

    ${({ $isButtonIntroOnDesktop, $isVisible }) =>
      $isVisible &&
      $isButtonIntroOnDesktop &&
      css`
        margin-left: 2rem;
        max-width: 300px;
        transition:
          opacity 0.1s 0.3s ease-in,
          max-width ${durationAndEasing};
      `}

    ${({ $isButtonIntroOnDesktop }) =>
      !$isButtonIntroOnDesktop &&
      css`
        transition: none;
      `}

    opacity: ${({ $isButtonIntroOnDesktop, $isVisible }) => (!$isButtonIntroOnDesktop || $isVisible ? 1 : 0)};
    padding: 0;
    position: relative;
    top: unset;
    transform: none;
    width: unset;
  }
`;

export const Title = styled(AccentText)`
  margin: 0.375rem 0;

  ${MediaSmallAndAbove} {
    margin: 0.875rem 0;
  }
`;

export const NavigationToggle = styled.label.attrs({ tabIndex: -1 })`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  outline: none !important;
  position: absolute;
  top: 0;
  width: 100%;

  ${MediaSmallAndAbove} {
    display: none;
  }
`;

export const Icon = styled(ChevronDownIconXs)<{ $isToggled: boolean }>`
  height: 1.5rem;
  margin-right: 0.375rem;

  ${({ $isToggled }) =>
    $isToggled &&
    css`
      transform-origin: center;
      transform: rotate(-180deg);
    `};

  position: absolute;
  transition: transform ${durationAndEasing};
  width: 1.5rem;

  path {
    fill: ${Colors.White};
  }
`;

export const Overlay = styled.div<{ $isVisible: boolean }>`
  background-color: ${Colors.Black};
  height: 100%;
  left: 0;
  opacity: ${({ $isVisible }) => ($isVisible ? Opacities.Overlay : '0')};
  pointer-events: ${({ $isVisible }) => ($isVisible ? 'auto' : 'none')};
  position: fixed;
  top: 0;
  transition: opacity 0.5s 0.15s ease-out;
  width: 100%;
  z-index: -1;

  ${MediaSmallAndAbove} {
    display: none;
  }
`;
