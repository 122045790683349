import { UsercentricsSingleton, CONSENT_STATUS_CHANGE } from './UsercentricsSingleton.types';

export class FallbackImplementation implements UsercentricsSingleton {
  private consent: Record<string, boolean | undefined> = {};

  getConsentStatus(serviceName: string, changeEvent?: CustomEvent): boolean | undefined {
    const consentStatus = changeEvent?.detail[serviceName];

    if (typeof consentStatus === 'boolean') {
      return consentStatus;
    }

    return this.consent[serviceName] ?? false;
  }
  getControllerId(): string | undefined {
    return undefined;
  }
  async init(): Promise<void> {
    return this.sendConsentUpdateEvent();
  }

  isInitialized(): boolean {
    return true;
  }

  updateConsentStatus(serviceName: string, isAccepted: boolean): Promise<void> {
    this.consent[serviceName] = isAccepted;

    return this.sendConsentUpdateEvent();
  }
  private sendConsentUpdateEvent(): Promise<void> {
    window.dispatchEvent(new CustomEvent(CONSENT_STATUS_CHANGE, { detail: this.consent }));

    return Promise.resolve();
  }
}
