import { FC, useMemo } from 'react';

import { MTeaserItemType, OTeaserGridArticlesType, RichTextDocType } from '../../types/storyblok';
import { MTeaserItemProps } from '../MTeaserItem';
import { OTeaserGrid } from '../OTeaserGrid/OTeaserGrid';

export type UnmappedMTeaserItemProps = Omit<MTeaserItemProps, 'text'> & {
  text: MTeaserItemType['text'] | RichTextDocType;
};

export type TeaserGridArticlesProps = OTeaserGridArticlesType & {
  articles?: UnmappedMTeaserItemProps[];
  currentPageId?: string | null;
};

export const OTeaserGridArticles: FC<TeaserGridArticlesProps> = ({
  _uid,
  accent,
  anchorId,
  articles,
  currentPageId,
  limit,
  textIntro,
  theme,
}) => {
  const filteredArticles = useMemo(() => {
    if (!articles) {
      return [];
    }

    let filtered = articles.filter((article) => article._uid !== currentPageId);

    if (limit) {
      filtered = filtered.slice(0, parseInt(limit, 10));
    }

    return filtered;
  }, [articles, currentPageId, limit]);

  if (!articles) {
    return null;
  }

  return (
    <OTeaserGrid
      _uid={_uid}
      accent={accent}
      anchorId={anchorId}
      columns={filteredArticles.length > 2 ? '3' : '2'}
      component="o-teaser-grid"
      items={filteredArticles}
      textIntro={textIntro}
      theme={theme}
    />
  );
};
