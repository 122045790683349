import { FC } from 'react';

import { Header as HeaderComponent } from '@lichtblick/ui-components';

import { Settings } from '../../helpers/settingsContext';
import { useNavigationItems } from '../../hooks';

export const Header: FC<Settings> = ({ metaNavigation, primaryNavigation }) => {
  const memoedPrimaryNavigation = useNavigationItems(primaryNavigation ?? []);
  const memoedMetaNavigation = useNavigationItems(metaNavigation ?? []);

  return <HeaderComponent metaNavigation={memoedMetaNavigation} primaryNavigation={memoedPrimaryNavigation} />;
};
