import { FC } from 'react';

import { Colors } from '@lichtblick/theme';

import { Background, Stage, StyledAppBadges, StyledGridContainer, StyledMTextIntro } from './OStageFull.styles';

import { componentMapper } from '../../helpers/componentMapper';
import { OStageFullType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { AImage } from '../AImage';
import { AVideo } from '../AVideo';
import { GridColumn, GridRow } from '../shared';

export const OStageFull: FC<OStageFullType> = ({
  accent,
  appStoreUrl,
  defineSubheadlineAsH1: hasSublineAsH1,
  embed,
  media,
  playStoreUrl,
  textIntro,
  theme,
}) => {
  const isButtonDisabled = Boolean(appStoreUrl || playStoreUrl || embed?.length);
  const accentColor = getColor(accent) ?? Colors.Black;
  const mediaItem = media?.[0];

  return (
    <div data-testid="stage-full">
      <Stage $isEmbed={Boolean(embed?.[0])} backgroundColor={getColor(theme)}>
        {mediaItem && (
          <Background $isEmbed={Boolean(embed?.[0])} data-testid="background">
            {mediaItem.component === 'a-video' ? (
              <AVideo {...mediaItem} shouldPlayInBackground />
            ) : (
              <AImage {...mediaItem} isBackground loading="eager" maxWidth={1920} />
            )}
          </Background>
        )}
        <StyledGridContainer>
          <GridRow>
            <GridColumn $width={8}>
              {textIntro?.[0] && (
                <StyledMTextIntro
                  {...textIntro[0]}
                  $isEmbed={Boolean(embed?.length)}
                  accentColor={accentColor}
                  button={!isButtonDisabled ? textIntro[0].button : undefined}
                  buttonVariant="primary"
                  headlineProps={{
                    size: 'L',
                    renderAs: hasSublineAsH1 ? 'div' : 'h1',
                  }}
                  textProps={{
                    renderAs: 'div',
                    align: 'left',
                  }}
                />
              )}
              {!embed?.length && (appStoreUrl || playStoreUrl) && (
                <StyledAppBadges appStoreUrl={appStoreUrl ?? undefined} playStoreUrl={playStoreUrl ?? undefined} />
              )}
            </GridColumn>
          </GridRow>
        </StyledGridContainer>
      </Stage>
      {embed?.[0] && componentMapper(embed[0])}
    </div>
  );
};
