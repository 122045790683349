import { FC, ReactNode, useState } from 'react';

import { DownloadIconS } from '@lichtblick/icons/svg/download/download-s';
import { DownloadIconXs } from '@lichtblick/icons/svg/download/download-xs';
import { InfoIconS } from '@lichtblick/icons/svg/info/info-s';
import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { InvoiceIconS } from '@lichtblick/icons/svg/invoice/invoice-s';
import { InvoiceIconXs } from '@lichtblick/icons/svg/invoice/invoice-xs';
import { PhoneIconS } from '@lichtblick/icons/svg/phone/phone-s';
import { PhoneIconXs } from '@lichtblick/icons/svg/phone/phone-xs';
import { useTracker } from '@lichtblick/tracker';
import { BoxCTA, BoxCTAProps, LegacyButtonProps, TextCTA } from '@lichtblick/ui-components';

import { getHref } from '../../helpers';
import { AButtonType } from '../../types/storyblok';
import { OInfoDialog } from '../OInfoDialog';

export const contentfulToButtonProps = (button: AButtonType, options?: LegacyButtonProps): LegacyButtonProps => ({
  children: button.text,
  href: getHref(button),
  isDownloadLink: Boolean(button.isDownloadLink),
  shouldOpenInNewTab: Boolean(button.openInNewTab),
  ...options,
});

export type AButtonProps = AButtonType & {
  hasNoIcon?: boolean;
  isSlim?: BoxCTAProps['isSlim'];
  isWide?: BoxCTAProps['isWide'];
  variant?: BoxCTAProps['variant'] | 'link';
};

export const AButton: FC<AButtonProps> = (props) => {
  const {
    hasNoIcon,
    isDownloadLink,
    isSlim,
    isWide,
    linkAsset,
    linkDialog,
    linkEntry,
    linkUrl,
    openAsPhoneLink: isPhoneLink,
    openInNewTab: shouldOpenInNewTab,
    text,
    trackingOption,
    variant,
  } = props;

  const { trackCustomEvent } = useTracker();

  const href = !linkAsset?.filename
    ? linkEntry?.cached_url
      ? `/${linkEntry?.cached_url}${linkUrl ? linkUrl : ''}`
      : linkUrl
    : linkAsset.filename;
  const hasInfoDialog = linkDialog?.length === 1;

  const target = shouldOpenInNewTab || Boolean(linkAsset?.filename) ? '_blank' : undefined;
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  let button: ReactNode = undefined;

  const getIcon = () => {
    if (hasNoIcon) {
      return;
    }

    if (hasInfoDialog) {
      return isSlim ? <InfoIconXs /> : <InfoIconS />;
    } else if (isPhoneLink) {
      return isSlim ? <PhoneIconXs /> : <PhoneIconS />;
    } else if (isDownloadLink && shouldOpenInNewTab) {
      return isSlim ? <InvoiceIconXs /> : <InvoiceIconS />;
    } else if (isDownloadLink) {
      return isSlim ? <DownloadIconXs /> : <DownloadIconS />;
    }
  };

  const handleEvent = () => {
    if (hasInfoDialog) {
      setIsInfoDialogOpen(true);
    }

    if (trackingOption?.[0] && trackingOption[0].eventName) {
      trackCustomEvent({
        eventName: trackingOption[0].eventName,
        customProps: trackingOption[0].customProps && JSON.parse(trackingOption[0].customProps),
      });
    }
  };

  if (variant === 'link' || isPhoneLink || isDownloadLink) {
    button =
      href && !hasInfoDialog ? (
        <TextCTA
          as="a"
          href={href}
          icon={getIcon()}
          isDownload={!!isDownloadLink}
          isSlim={isSlim}
          onClick={handleEvent}
          target={target}
        >
          {text}
        </TextCTA>
      ) : (
        <TextCTA as="button" icon={getIcon()} isSlim={isSlim} onClick={handleEvent}>
          {text}
        </TextCTA>
      );
  } else {
    button =
      href && !hasInfoDialog ? (
        <BoxCTA
          as="a"
          href={href}
          icon={getIcon()}
          isDownload={!!isDownloadLink}
          isSlim={isSlim}
          isWide={isWide}
          onClick={handleEvent}
          target={target}
          variant={variant}
        >
          {text}
        </BoxCTA>
      ) : (
        <BoxCTA as="button" icon={getIcon()} isSlim={isSlim} isWide={isWide} onClick={handleEvent} variant={variant}>
          {text}
        </BoxCTA>
      );
  }

  return (
    <>
      {button}
      {hasInfoDialog && (
        <OInfoDialog
          {...linkDialog[0]}
          isOpen={isInfoDialogOpen}
          onSecondaryButtonClick={() => setIsInfoDialogOpen(false)}
        />
      )}
    </>
  );
};
