import { Icon as IconBase } from '@lichtblick/icons';
import styled from '@lichtblick/styled';
import {
  BorderRadius,
  BorderWidth,
  Colors,
  Duration,
  DurationAndEasing,
  Spacing,
  StateColors,
} from '@lichtblick/theme';
import { AccentText, AccordionContent, AccordionItem, AccordionTrigger } from '@lichtblick/ui-components';

export const HeaderContent = styled.div`
  border-radius: ${BorderRadius.S} ${BorderRadius.S};
  display: flex;
  flex-direction: row;
  padding: ${Spacing.Xs} 0 ${Spacing.Xs} 2.5rem;
  position: relative;
  text-align: left;
`;

export const Icon = styled(IconBase)`
  ${AccordionTrigger}[data-state='open'] & {
    transform: rotate(45deg);
  }

  position: relative;
  transition: transform ${DurationAndEasing.LargeOutro};
`;

export const IconWrapper = styled.div`
  left: 0;

  &::before {
    background-color: ${Colors.Orange};
    border-radius: 0.75rem;
    box-sizing: border-box;
    content: '';
    height: 1.5rem;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(-0.25rem, 0.25rem);
    transition: transform ${DurationAndEasing.SmallOutro};
    width: 1.5rem;
  }

  ${AccordionTrigger}[data-state='open'] & {
    &::before {
      transform: translate(0, 0);
    }
  }

  ${AccordionTrigger}[data-state='closed'] ${HeaderContent}:hover &::before {
    transform: translate(-0.125rem, 0.125rem);
  }

  position: absolute;
  top: 1rem;
`;

export const CollapsibleContent = styled.div`
  padding: 0 0 ${BorderRadius.M} 2.5rem;
`;

export const Root = styled(AccordionItem)<{ $isThemeFadedGray: boolean }>`
  border-color: ${({ $isThemeFadedGray }) => ($isThemeFadedGray ? Colors.White : Colors.FadedGray)};
  border-style: solid;
  border-width: 0;
  border-top-width: ${BorderWidth.Input};
  overflow: visible;

  &:last-child {
    border-bottom-width: ${BorderWidth.Input};
  }
`;

export const Title = styled(AccentText)`
  ${AccordionTrigger}:focus-visible & {
    outline: dotted 1px ${StateColors.AlternativeFocus};
  }

  display: block;
`;

export const Content = styled(AccordionContent)`
  &[data-state='open'],
  &[data-state='closed'] {
    animation-duration: ${Duration.SmallOutro}ms;
    animation-fill-mode: forwards;
  }

  &[data-state='open'] {
    animation-timing-function: ease-in;
  }
  &[data-state='closed'] {
    animation-timing-function: ease-out;
  }
`;
