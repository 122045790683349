import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box, MasonryGrid } from '@lichtblick/ui-components';

import { GridItem, StyledOFactGrid } from './OFactGrid.styles';

import { OFactGridType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { MFactItem } from '../MFactItem';
import { MTextIntro } from '../MTextIntro';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

export const OFactGrid: FC<OFactGridType> = ({ accent, anchorId, items, textIntro, theme }) => {
  const factItems = items.filter(Boolean).map((item, index) => (
    <GridItem $index={index} key={item._uid}>
      {/* TODO: check if that fallback is what we want */}
      <MFactItem {...item} backgroundColor={accent ?? 'white'} />
    </GridItem>
  ));

  return (
    <StyledOFactGrid>
      <Box backgroundColor={getColor(theme)} data-testid="fact-grid" pbd={Spacing.Xxs} ptd={Spacing.Xl} ptm={Spacing.L}>
        {anchorId && <AnchorMark anchorId={anchorId} />}

        <GridContainer>
          {textIntro?.length === 1 && (
            <GridRow>
              <GridColumn $push={2} $width={8}>
                <MTextIntro {...textIntro[0]} buttonVariant="link" isCenteredDesktop={true} isCenteredMobile={true} />
              </GridColumn>
            </GridRow>
          )}

          <GridRow>
            <GridColumn $width={12}>{items && <MasonryGrid columnCount={2} items={factItems} />}</GridColumn>
          </GridRow>
        </GridContainer>
      </Box>
    </StyledOFactGrid>
  );
};
