import styled, { PickTransient, css, keyframes } from '@lichtblick/styled';
import { BorderWidth, Colors, DurationAndEasing, MediaMediumAndAbove, Opacities, Spacing } from '@lichtblick/theme';

import { LegacyButtonProps } from './LegacyButton';

import { focusRing, linkUnderline, linkUnderlineHover } from '../shared';

export const IconWrapper = styled.span`
  margin-right: ${Spacing.Xxs};
  position: relative;

  & > * {
    vertical-align: middle;
  }
`;

const loadingKeyframes = keyframes`
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
`;

export const Label = styled.span<PickTransient<LegacyButtonProps, 'isLoading'>>`
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      &::after {
        animation: ${loadingKeyframes} 1.5s linear infinite;
        content: '';
        display: inline-block;
        text-align: left;
        width: 1rem;
      }
    `}

  position: relative;
`;

const BaseButton = styled.button<PickTransient<LegacyButtonProps, 'isSlim' | 'isWide'>>`
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: ${({ $isSlim }) => ($isSlim ? 0.75 : 1)}rem;
  font-weight: 600;
  line-height: 1.7;
  position: relative;
  text-align: center;
  width: ${({ $isWide }) => ($isWide ? '100%' : 'auto')};

  ${MediaMediumAndAbove} {
    font-size: ${({ $isSlim }) => ($isSlim ? 0.875 : 1.125)}rem;
  }

  &:disabled,
  &[disabled] {
    opacity: ${Opacities.DisabledCustomIcon};
    pointer-events: none;

    &::before {
      filter: grayscale(100%);
      transform: translate(0, 0);
    }
  }
`;

const Button = styled(BaseButton)`
  outline: none !important;
  padding: ${({ $isSlim }) => ($isSlim ? '0.25rem 1rem' : '0.5rem 1rem')};

  ${MediaMediumAndAbove} {
    padding: ${({ $isSlim }) => ($isSlim ? '0.25rem 1rem' : '0.5rem 1.5rem')};
  }

  &::before,
  &::after {
    border-radius: 0.5rem;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::before {
    background-color: currentColor;
    pointer-events: none;
    transform: translate(-0.44444em, 0.44444em);
    transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &::after {
    border: ${BorderWidth.Input} solid;
    box-sizing: border-box;
  }

  &:focus-visible::after {
    ${focusRing};
  }

  &:hover::before {
    transform: translate(-0.22222em, 0.22222em);
  }

  &:active {
    &::after {
      outline: none;
    }

    &::before {
      transform: translate(0, 0);
    }
  }
`;

export const Primary = styled(Button)`
  &::before {
    background-color: ${Colors.Orange};
  }
`;

export const Secondary = styled(Button)`
  &::before {
    opacity: 0;
    transition:
      transform ${DurationAndEasing.SmallIntro},
      opacity ${DurationAndEasing.SmallIntro};
  }

  &:active::before,
  &:hover::before {
    opacity: 0.2;
  }
`;

export const Destructive = styled(Button)`
  &::before {
    background-color: ${Colors.Red};
  }
`;

export const Link = styled(BaseButton)`
  padding: 0;
  text-align: inherit;
  word-break: break-word;

  & > ${Label} {
    ${linkUnderline};
    padding: 2px 0 1px;

    ${MediaMediumAndAbove} {
      padding: 2px 0;
    }
  }

  &:hover > ${Label} {
    ${linkUnderlineHover};
  }

  &:focus-visible {
    ${focusRing};
  }
`;
