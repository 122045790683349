import NextLink from 'next/link';
import { AnchorHTMLAttributes, FC, PropsWithChildren, useContext } from 'react';

import { HugoAndAppRoutesContext } from './hugoAndAppRoutesContext';

type LinkProps = PropsWithChildren &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & { className?: string; href: string };

export const Link: FC<LinkProps> = ({ children, href, ...props }) => {
  const hugoAndAppRoutes = useContext(HugoAndAppRoutesContext);

  if (
    !hugoAndAppRoutes?.includes(
      href
        .split('?')[0]
        .replace(/\/$/, '')
        .replace(/^https:\/\/www\.lichtblick\.de/, ''),
    ) &&
    !hugoAndAppRoutes?.includes(
      href
        .split('#')[0]
        .replace(/\/$/, '')
        .replace(/^https:\/\/www\.lichtblick\.de/, ''),
    )
  ) {
    return (
      <NextLink {...props} href={href}>
        {children}
      </NextLink>
    );
  }

  return (
    <a {...props} href={href}>
      {children}
    </a>
  );
};
