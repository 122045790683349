import { FC } from 'react';

import { CheckIconS } from '@lichtblick/icons/svg/check/check-s';

import {
  Root,
  SellingPointsContainer,
  SellingPointList,
  SellingPointItem,
  SellingPointsSection,
} from './SolarStage.styles';

import { richTextMapper } from '../../../helpers';
import { OStageFullType, RichTextDocType, SolarStageType } from '../../../types';
import { ColorKey, getColor } from '../../../utils';
import { OStageFull } from '../../OStageFull';

export type SolarStageProps = OStageFullType & {
  followingSectionTheme?: ColorKey;
  sellingPoints: RichTextDocType[];
};

export const SolarStage: FC<SolarStageType> = ({ followingSectionTheme, sellingPoints, ...rest }) => (
  <Root>
    <OStageFull {...rest} component="o-stage-full" />
    <SellingPointsSection $backgroundColor={getColor(followingSectionTheme)}>
      <SellingPointsContainer>
        {sellingPoints &&
          richTextMapper(sellingPoints, {
            ul: ({ children }) => <SellingPointList>{children}</SellingPointList>,
            li: ({ children }) => (
              <SellingPointItem>
                <CheckIconS />
                {children}
              </SellingPointItem>
            ),
          })}
      </SellingPointsContainer>
    </SellingPointsSection>
  </Root>
);
