import { ConsentHistory, ServiceBaseInfo } from './UsercentricsSingleton.types';

const isHistoryOfJustWithdrawnService = (history: ConsentHistory[]) =>
  Boolean(history[history.length - 2]?.status && !history[history.length - 1].status);

export const hasConsentJustBeenWithdrawn = (servicesBaseInfo: ServiceBaseInfo[]) =>
  Boolean(
    servicesBaseInfo
      .filter(({ isEssential }) => !isEssential)
      .find(({ consent }) => isHistoryOfJustWithdrawnService(consent.history)),
  );

export const reloadOnWithdrawal = () => {
  const servicesBaseInfo = window.UC_UI?.getServicesBaseInfo();

  if (servicesBaseInfo && hasConsentJustBeenWithdrawn(servicesBaseInfo)) {
    location.reload();
  }
};
