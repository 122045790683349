import { FC } from 'react';

import { Box } from '@lichtblick/ui-components';

import { ButtonWrapper, TeaserList, TeaserListItemWrapper, TextIntroWrapper } from './OTeaserList.styles';

import { OTeaserListType, TPressDetailType } from '../../types/storyblok';
import { getButtonVariant, getColor } from '../../utils';
import { AButton } from '../AButton';
import { MTeaserListItem } from '../MTeaserListItem';
import { MTeaserListItemProps } from '../MTeaserListItem/MTeaserListItem';
import { MTextIntro } from '../MTextIntro';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

export type TeaserListProps = OTeaserListType & {
  filterByCategory?: TPressDetailType['category'];
  listItems?: Omit<MTeaserListItemProps, 'theme'>[];
};

export const OTeaserList: FC<TeaserListProps> = ({
  anchorId,
  button,
  filterByCategory,
  listItems,
  textIntro,
  theme,
}) => {
  const items = listItems?.filter((item) => {
    if (item?.component !== 't-press-detail-list-item') {
      return true;
    }

    const itemCategory = item.meta?.[1];

    return !filterByCategory || filterByCategory === itemCategory;
  });

  return (
    <Box backgroundColor={getColor(theme)} data-testid="teaser-list">
      {anchorId && <AnchorMark anchorId={anchorId} />}
      <GridContainer>
        <TeaserList>
          {textIntro?.[0] && (
            <GridRow>
              <GridColumn $push={2} $width={8}>
                <TextIntroWrapper>
                  <MTextIntro {...textIntro[0]} buttonVariant={getButtonVariant('link')} />
                </TextIntroWrapper>
              </GridColumn>
            </GridRow>
          )}
          {Boolean(items?.length) && (
            <GridRow>
              <GridColumn $push={2} $width={8}>
                <TeaserListItemWrapper>
                  {items.map((item) => Boolean(item) && <MTeaserListItem {...item} key={item._uid} theme={theme} />)}
                </TeaserListItemWrapper>
              </GridColumn>
            </GridRow>
          )}
          {button?.[0] && (
            <ButtonWrapper>
              <AButton {...button[0]} variant="link" />
            </ButtonWrapper>
          )}
        </TeaserList>
      </GridContainer>
    </Box>
  );
};
