import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import styled, { FlattenSimpleInterpolation, PickTransient, css } from '@lichtblick/styled';
import {
  BorderRadius,
  BorderWidth,
  Colors,
  DurationAndEasing,
  FontWeights,
  Spacing,
  StateColors,
  alpha,
} from '@lichtblick/theme';

import { InputElementProps, TextfieldProps } from './Textfield';

import { Text } from '../../atoms/Text/Text';
import { TooltipTrigger } from '../../organisms/Tooltip/Tooltip.styles';
import { FieldFooterType } from '../FieldFooter';

// TODO: let's move shared styles somewhere more prominent, maybe even add stories for them
export const sharedWrapperStyles = css`
  display: flex;
  flex-direction: column;
  gap: ${Spacing['3Xs']};
`;

export const Wrapper = styled.div`
  ${sharedWrapperStyles}
`;

export const sharedInputRowStyles: Partial<Record<FieldFooterType | 'base' | 'disabled', FlattenSimpleInterpolation>> =
  {
    base: css`
      border-color: currentColor;
      border-radius: ${BorderRadius.S};
      border-style: solid;
      border-width: ${BorderWidth.Input};
      display: flex;
      height: 2.6875rem;

      &:focus-within {
        border-color: ${StateColors.Focus};
      }
    `,
    success: css`
      border-color: ${StateColors.Success};

      &:focus-within {
        border-color: ${StateColors.Success};
      }
    `,
    error: css`
      border-color: ${StateColors.Error};

      &:focus-within {
        border-color: ${StateColors.Error};
      }
    `,
    disabled: css`
      background-color: ${StateColors.InactiveLight};
      border-color: transparent;
    `,
  };

export type SharedInputRowProps = PickTransient<TextfieldProps, 'isDisabled' | 'statusType'>;

export const getSharedInputRowStyles = ({ $isDisabled, $statusType = 'default' }: SharedInputRowProps) => css`
  ${sharedInputRowStyles.base}
  ${$isDisabled && sharedInputRowStyles.disabled}
  ${sharedInputRowStyles[$statusType]}
`;

export const InputRow = styled.label<SharedInputRowProps>`
  ${getSharedInputRowStyles}
`;

export const sharedInputStyles = {
  base: css`
    background-color: transparent;
    border: none;
    border-radius: ${BorderRadius.S};
    color: inherit;
    flex: 1;
    font-family: inherit;
    font-feature-settings:
      'zero' on,
      'ss01' on,
      'cv05' on;
    font-size: 1rem;
    height: 100%;
    min-width: 0;
    outline: none !important;
    padding-left: ${Spacing.Xs};
    padding-right: ${Spacing.Xs};
    width: 100%; // w/o this there somehow is an implicit minimum width

    &:disabled {
      color: ${alpha(Colors.Black, 0.5)};
    }

    &::placeholder {
      color: ${Colors.Gray};
      opacity: 1;
    }
  `,
  hasRightSlot: css`
    padding-right: ${Spacing['3Xs']};
  `,
  hasLeftSlot: css`
    padding-left: ${Spacing['3Xs']};
  `,
};

export const Input = styled.input<
  PickTransient<InputElementProps, 'isBold' | 'isCentered'> & { $hasLeftSlot?: boolean; $hasRightSlot?: boolean }
>`
  ${sharedInputStyles.base};

  &[type='date'] {
    text-transform: uppercase;
  }

  ${({ $hasRightSlot }) => $hasRightSlot && sharedInputStyles.hasRightSlot}
  ${({ $hasLeftSlot }) => $hasLeftSlot && sharedInputStyles.hasLeftSlot}

  ${({ $isBold }) =>
    $isBold &&
    css`
      font-weight: ${FontWeights.Bold};
    `};
  ${({ $isCentered }) =>
    $isCentered &&
    css`
      text-align: center;
    `};

  // not done via ´rightSlot´ as the default icon in Firefox currently can't be hidden
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 1;
    transition: opacity ${DurationAndEasing.SmallIntro};

    &:hover {
      opacity: 0.8;
    }
  }

  &:not([type='time'])::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none"><path fill="currentColor" fill-rule="evenodd" d="M5.65 1.5a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1ZM3 4.5a.5.5 0 0 0-.5.5v1.833h12V5a.5.5 0 0 0-.5-.5h-.25a1 1 0 1 1 0-2H14A2.5 2.5 0 0 1 16.5 5v8a2.5 2.5 0 0 1-2.5 2.5H3A2.5 2.5 0 0 1 .5 13V5A2.5 2.5 0 0 1 3 2.5h.133a1 1 0 1 1 0 2H3Zm11.5 4.334h-12V13a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V8.834ZM7.25 3.5a1 1 0 0 1 1-1h.5a1 1 0 0 1 0 2h-.5a1 1 0 0 1-1-1Zm3.95 2a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1Z" clip-rule="evenodd"/></svg>');
  }
`;

export const sharedLabelWrapperStyles = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div`
  ${sharedLabelWrapperStyles}

  ${TooltipTrigger} {
    display: grid;
  }
`;

export const slotStyles = css`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
`;

const slotPseudoStyles = css`
  background-color: inherit;
  content: '';
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: ${Spacing.Xs};
  z-index: 2;
`;

export const sharedSlotStyles = css`
  border-bottom-right-radius: ${BorderRadius.S};
  border-top-right-radius: ${BorderRadius.S};
  ${slotStyles}
  padding-right: ${Spacing['Xs']};

  &::after {
    left: calc(${Spacing.Xs} * -1);
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1));
    ${slotPseudoStyles};
  }
`;

export const Slot = styled.div`
  ${sharedSlotStyles}
`;

export const LeftSlot = styled.div`
  border-bottom-left-radius: ${BorderRadius.S};
  border-top-left-radius: ${BorderRadius.S};
  ${slotStyles}
  padding-left: ${Spacing['Xs']};

  &::before {
    mask-image: linear-gradient(270deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1));
    ${slotPseudoStyles}
    right: calc(${Spacing.Xs} * -1);
  }
`;

export const RightSlot = Slot;

export const InfoIcon = styled(InfoIconXs)`
  cursor: pointer;
`;

export const SuffixText = styled(Text)`
  color: ${Colors.Gray};
`;

export const StyledSubLabel = styled(Text).attrs({ size: 'Xs' })`
  color: ${Colors.Gray};
`;
