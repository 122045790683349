import styled, { FlattenSimpleInterpolation, css, keyframes } from '@lichtblick/styled';
import { BorderWidth, Colors, DurationAndEasing, MediaMediumAndAbove, Opacities, Spacing } from '@lichtblick/theme';

import { Link } from '../../helpers/Link';
import { focusRing } from '../shared';

export type BoxCTAVariant = 'primary' | 'secondary' | 'destructive';

type StyledBoxCTAProps = {
  $isSlim?: boolean;
  $isWide?: boolean;
  $variant: BoxCTAVariant;
};

const PrimaryBoxCTAStyles = css`
  &::before {
    background-color: ${Colors.Orange};
  }
`;

const SecondaryBoxCTAStyles = css`
  &::before {
    opacity: 0;
    transition:
      transform ${DurationAndEasing.SmallIntro},
      opacity ${DurationAndEasing.SmallIntro};
  }

  &:active::before,
  &:hover::before {
    opacity: 0.2;
  }
`;

const DestructiveBoxCTAStyles = css`
  &::before {
    background-color: ${Colors.Red};
  }
`;

const BoxCTAStyleMap: { [key in BoxCTAVariant]: FlattenSimpleInterpolation } = {
  primary: PrimaryBoxCTAStyles,
  secondary: SecondaryBoxCTAStyles,
  destructive: DestructiveBoxCTAStyles,
};

export const StyledBoxCTA = styled(Link)<StyledBoxCTAProps>`
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: ${({ $isSlim }) => ($isSlim ? 0.75 : 1)}rem;
  font-weight: 600;
  flex-grow: 0;
  line-height: 1.7;
  position: relative;
  text-align: center;
  width: ${({ $isWide }) => ($isWide ? '100%' : 'auto')};

  ${MediaMediumAndAbove} {
    font-size: ${({ $isSlim }) => ($isSlim ? 0.875 : 1.125)}rem;
  }

  &::before {
    background-color: currentColor;
    pointer-events: none;
    transform: translate(-0.44444em, 0.44444em);
    transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &::before,
  &::after {
    border-radius: 0.5rem;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:disabled,
  &[disabled] {
    opacity: ${Opacities.DisabledCustomIcon};
    pointer-events: none;

    &::before {
      filter: grayscale(100%);
      transform: translate(0, 0);
    }
  }

  outline: none !important;
  padding: ${({ $isSlim }) => ($isSlim ? '0.25rem 1rem' : '0.5rem 1rem')};

  ${MediaMediumAndAbove} {
    padding: ${({ $isSlim }) => ($isSlim ? '0.25rem 1rem' : '0.5rem 1.5rem')};
  }

  &::after {
    border: ${BorderWidth.Input} solid;
    box-sizing: border-box;
  }

  &:focus-visible::after {
    ${focusRing};
  }

  &:hover::before {
    transform: translate(-0.22222em, 0.22222em);
  }

  &:active {
    &::after {
      outline: none;
    }

    &::before {
      transform: translate(0, 0);
    }
  }

  ${({ $variant }) => BoxCTAStyleMap[$variant]}
`;

export const StyledBoxCTAIconWrapper = styled.span`
  position: relative;

  & > * {
    vertical-align: middle;
  }
`;

const loadingKeyframes = keyframes`
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
`;

export const StyledBoxCTAText = styled.span<{ $isLoading?: boolean }>`
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      &::after {
        animation: ${loadingKeyframes} 1.5s linear infinite;
        content: '';
        display: inline-block;
        text-align: left;
        width: 1rem;
      }
    `}

  position: relative;

  ${StyledBoxCTAIconWrapper} + & {
    margin-left: ${Spacing.Xxs};
  }
`;
