import { FC, PropsWithChildren } from 'react';

import { MailIconXs } from '@lichtblick/icons/svg/mail/mail-xs';
import { PhoneIconXs } from '@lichtblick/icons/svg/phone/phone-xs';
import { localize } from '@lichtblick/localization';

import {
  Contact,
  ContactLink,
  ContactOpeningHours,
  FooterCopyright,
  FooterHeadline,
  FooterNavigation,
  FooterSection,
  HomeLink,
  Root,
  StyledSecondaryNavigation,
} from './Footer.styles';
import { texts } from './Footer.texts';
import { PrimaryNavigation } from './PrimaryNavigation';
import { SecondaryNavigation } from './SecondaryNavigation';

import { Logo } from '../../atoms/Logo/Logo';
import { Text } from '../../atoms/Text/Text';
import { TextCTA } from '../../atoms/TextCTA/TextCTA';
import { NavigationItemProps } from '../../types';

export type FooterProps = PropsWithChildren<{
  contact: {
    email?: string;
    formUrl?: string;
    headline?: string;
    openingHours?: string;
    phone?: string;
    text?: string;
  };
  hasLogo?: boolean;
  isPrimaryNavigationHidden?: boolean;
  primaryNavigation: NavigationItemProps[];
  secondaryNavigation: NavigationItemProps[];
}>;

export const Footer: FC<FooterProps> = ({
  children,
  contact,
  hasLogo,
  isPrimaryNavigationHidden,
  primaryNavigation,
  secondaryNavigation,
}) => {
  return (
    <>
      {!isPrimaryNavigationHidden && (
        <Root $hasPaddingTop>
          <FooterSection>
            {/* brand text & teaser */}
            {children}
            <Contact>
              {contact.headline && <FooterHeadline>{contact.headline}</FooterHeadline>}
              {contact.text && <Text>{contact.text}</Text>}
              {/* TODO: feature toggle for contact from */}
              {contact.formUrl && (
                <ContactLink>
                  <MailIconXs />
                  <TextCTA as="a" href={contact.formUrl} isInlineLink>
                    {localize(texts.contact.links.contactForm.label)}
                  </TextCTA>
                </ContactLink>
              )}
              {contact.email && (
                <ContactLink>
                  <MailIconXs />
                  <TextCTA as="a" href={`mailto:${contact.email}`} isInlineLink>
                    {contact.email}
                  </TextCTA>
                </ContactLink>
              )}
              {contact.phone && (
                <ContactLink>
                  <PhoneIconXs />
                  <TextCTA as="a" href={`tel:${contact.phone?.replace(/^0/, '+49')}`} isInlineLink>
                    {contact.phone}
                  </TextCTA>
                </ContactLink>
              )}
              {contact.openingHours && <ContactOpeningHours>{contact.openingHours}</ContactOpeningHours>}
            </Contact>
            {Boolean(primaryNavigation.length) && (
              <FooterNavigation>
                {primaryNavigation.map((item) => (
                  <PrimaryNavigation
                    href={item.href}
                    icon={item.icon}
                    isActive={item.isActive}
                    items={item.items}
                    key={item.text}
                    onClick={item.onClick}
                    setIsFlyoutActive={item.setIsFlyoutActive}
                    shouldOpenInNewTab={item.shouldOpenInNewTab}
                    text={item.text}
                    tracking={item.tracking}
                  />
                ))}
              </FooterNavigation>
            )}
          </FooterSection>
        </Root>
      )}
      <Root>
        <FooterSection $isSecondary>
          {Boolean(secondaryNavigation) && (
            <FooterNavigation $isSecondary>
              <StyledSecondaryNavigation>
                {secondaryNavigation[0]?.items?.map((item) => (
                  <SecondaryNavigation
                    href={item.href}
                    icon={item.icon}
                    isActive={item.isActive}
                    items={item.items}
                    key={item.text}
                    onClick={item.onClick}
                    setIsFlyoutActive={item.setIsFlyoutActive}
                    shouldOpenInNewTab={item.shouldOpenInNewTab}
                    text={item.text}
                    tracking={item.tracking}
                  />
                ))}
              </StyledSecondaryNavigation>
            </FooterNavigation>
          )}
          {hasLogo && (
            <HomeLink href="/">
              <Logo />
            </HomeLink>
          )}
          <FooterCopyright>{localize(texts.legal.copyright, { year: new Date().getFullYear() })}</FooterCopyright>
        </FooterSection>
      </Root>
    </>
  );
};
