import { FC, ReactNode } from 'react';

import { TeaserItem } from '@lichtblick/ui-components';

import { getHref } from '../../helpers';
import { componentMapper } from '../../helpers/componentMapper';
import { MTeaserItemType } from '../../types/storyblok';
import { ColorKey, colorMap, shadeColorMap } from '../../utils';
import { AButton } from '../AButton/AButton';

export type MTeaserItemProps = Omit<MTeaserItemType, 'text'> & {
  accentColor?: ColorKey;
  className?: string;
  isTextOutside?: boolean;
  text?: MTeaserItemType['text'] | ReactNode;
};

export const MTeaserItem: FC<MTeaserItemProps> = ({
  accentColor,
  button,
  chip,
  className,
  headline,
  isTextOutside = false,
  media,
  text,
  trackingIdentifier,
}) => {
  const href = getHref(button[0]);
  const tracking = trackingIdentifier ? { eventName: trackingIdentifier } : undefined;

  return (
    <TeaserItem
      backgroundColor={accentColor ? colorMap[accentColor] : undefined}
      chip={chip}
      className={className}
      frameColor={accentColor ? shadeColorMap[accentColor] : undefined}
      headline={headline}
      isTextOutside={isTextOutside}
      link={{
        href,
        isDownloadLink: button[0].isDownloadLink ?? false,
        shouldOpenInNewTab: button[0].openInNewTab ?? false,
      }}
      media={media?.length ? componentMapper(media[0]) : undefined}
      text={text}
      tracking={tracking}
    >
      {
        // These properties need to be set explicitely, otherwise the button would render an a tag inside of the teaser, which is an a tag already.
        <AButton {...{ ...button[0], linkAsset: undefined, linkEntry: undefined, linkUrl: undefined }} variant="link" />
      }
    </TeaserItem>
  );
};
