import Script from 'next/script';
import { useEffect } from 'react';

import { Container } from '@lichtblick/ui-components';

import { FormContainer } from './OHubSpotForm.styles';

export const OHubSpotForm = () => {
  useEffect(() => {
    sessionStorage.setItem(
      'GA4EMobilityContactForm',
      JSON.stringify({ event: 'NewgaEvent', event_name: 'form_submit_contactform_emob_b2b' }),
    );
  }, []);

  return (
    <Container>
      <FormContainer id="hubspot-contact-form" />

      <Script
        id="hubspot-contact-form-script"
        onLoad={() => {
          (window as any).hbspt.forms.create({
            region: 'eu1',
            portalId: '143379264',
            formId: 'ebf80417-cba2-44f7-a01e-2c17c6bad7da',
            target: '#hubspot-contact-form',
          });
        }}
        src="//js-eu1.hsforms.net/forms/embed/v2.js"
        type="text/javascript"
      />
    </Container>
  );
};
