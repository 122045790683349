import { FC, useState } from 'react';

import { Colors, darkColors } from '@lichtblick/theme';
import { AccentText, Text } from '@lichtblick/ui-components';

import { Caption, Card, Description, Frame, Media, StyledIcon, Toggle } from './MFactItem.styles';

import { componentMapper } from '../../helpers/componentMapper';
import { richTextMapper } from '../../helpers/richTextMapper';
import { MFactItemType } from '../../types/storyblok';
import { ColorKey, colorMap, getShadeColor } from '../../utils';

export const MFactItem: FC<MFactItemType & { backgroundColor?: ColorKey }> = ({
  backgroundColor,
  caption,
  description,
  media,
}) => {
  const [isToggled, setIsToggled] = useState(false);
  const shadeColor = getShadeColor(backgroundColor) ?? Colors.Black;

  return (
    <div data-testid="fact-item">
      <Card>
        <Toggle aria-label="Beschreibung anzeigen" onClick={() => setIsToggled(!isToggled)} role="button" />
        <StyledIcon $color={shadeColor} $isToggled={isToggled} name="OpenIconS" />
        <Media $isToggled={isToggled}>{media?.length === 1 && componentMapper(media[0])}</Media>
        <Description
          $backgroundColor={backgroundColor && colorMap[backgroundColor]}
          $color={backgroundColor && darkColors.includes(colorMap[backgroundColor]) ? Colors.White : Colors.Black}
          $isToggled={isToggled}
        >
          <Text>{description}</Text>
        </Description>
        <Frame $color={shadeColor} />
      </Card>
      <Caption size="M">
        {richTextMapper(caption, {
          p: ({ children }) => <span>{children}</span>,
          b: ({ children }) => <AccentText size="L">{children}</AccentText>,
        })}
      </Caption>
    </div>
  );
};
