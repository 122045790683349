import { FC, PropsWithChildren, useContext } from 'react';

import { Header, Footer } from '@lichtblick/contentful';
import { ThemeProvider } from '@lichtblick/styled';
import { light } from '@lichtblick/theme';
import { PageContainer } from '@lichtblick/ui-components';

import { ContactInfo } from './ContactInfo';
import { GlobalStyle } from './GlobalStyle';

import { SettingsContext } from '../../helpers/settingsContext';
import { ParentPage } from '../../helpers/templateMapper';
import { ANavigationItemType } from '../../types/storyblok';
import { MBreadcrumb } from '../MBreadcrumb';

export type LayoutProps = PropsWithChildren & {
  breadcrumbTitle?: string | null;
  footerCustomContactPhone?: string | null;
  footerCustomContactSmallText?: string | null;
  footerCustomContactText?: string | null;
  footerLayout?: 'none' | 'small' | 'small-no-contact' | 'regular';
  footerSecondaryNavigation?: ANavigationItemType[] | null;
  hasNoHeader?: boolean;
  parentPages?: ParentPage[];
  slug?: string | null;
};

export const Layout: FC<LayoutProps> = ({
  breadcrumbTitle,
  children,
  footerCustomContactPhone,
  footerCustomContactSmallText,
  footerCustomContactText,
  footerLayout = 'regular',
  hasNoHeader,
  parentPages,
  slug,
}) => {
  const hasBreadcrumb = footerLayout === 'regular' && breadcrumbTitle && slug;
  const hasContactInfo = footerLayout === 'small' && footerCustomContactPhone;
  const hasFooter = footerLayout !== 'none';
  const isSmallFooterLayout = ['small', 'small-no-contact'].includes(footerLayout);

  const settings = useContext(SettingsContext);

  return (
    <ThemeProvider theme={light}>
      <GlobalStyle />
      {hasNoHeader ? null : <Header {...settings} />}
      <PageContainer>
        {children}

        {hasBreadcrumb && (
          <MBreadcrumb
            breadcrumbTitle={breadcrumbTitle}
            mode="footer"
            parentPages={[{ slug: '', breadcrumbTitle: 'LichtBlick' }, ...(parentPages ?? [])]}
            slug={slug}
          />
        )}

        {hasContactInfo && (
          <ContactInfo
            phone={footerCustomContactPhone}
            smallText={footerCustomContactSmallText}
            text={footerCustomContactText}
          />
        )}

        {hasFooter && (
          <Footer {...settings} hasLogo={isSmallFooterLayout} isPrimaryNavigationHidden={isSmallFooterLayout} />
        )}
      </PageContainer>
    </ThemeProvider>
  );
};
