import styled, { css } from '@lichtblick/styled';
import { BorderRadius, Colors, DurationAndEasing, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

import { Chip } from '../../atoms/Chip/Chip';
import { Headline } from '../../atoms/Headline/Headline';
import { focusRing, linkUnderlineHover } from '../../atoms/shared';
import { Text } from '../../atoms/Text/Text';
import { TextCTA } from '../../atoms/TextCTA/TextCTA';
import { StyledTextCTA } from '../../atoms/TextCTA/TextCTA.styles';
import { Link } from '../../helpers/Link';

export const StyledLink = styled(Link).attrs({ tabIndex: 0 })`
  display: block;
  outline: none;
  position: relative;
`;

export const Frame = styled.div<{ $color?: Colors }>`
  border: 2px solid;
  border-radius: ${BorderRadius.S};
  color: ${({ $color }) => $color ?? 'inherit'};
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  ${StyledLink}:focus-visible & {
    ${focusRing};
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const Background = styled.div<{ $backgroundColor?: Colors }>`
  border-radius: ${BorderRadius.S};
  height: 100%;
  left: 0;

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};

  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-0.5rem, 0.5rem);
  transition: transform ${DurationAndEasing.LargeOutro};
  width: 100%;

  ${StyledLink}:hover &, ${StyledLink}:focus-visible & {
    transform: translate(-0.25rem, 0.25rem);
  }

  ${StyledLink}:active & {
    transform: translate(0, 0);
  }
`;

export const Media = styled.div<{ $isTextOutside: boolean }>`
  border-radius: ${({ $isTextOutside }) =>
    $isTextOutside ? BorderRadius.S : `${BorderRadius.S} ${BorderRadius.S} 0 0`};
  overflow: hidden;
  transform: translate(-0.5rem, 0.5rem);
  transition: transform ${DurationAndEasing.LargeOutro};
  width: 100%;

  img {
    width: 100%;
  }

  ${StyledLink}:hover &, ${StyledLink}:focus-visible & {
    transform: translate(-0.25rem, 0.25rem);
  }

  ${StyledLink}:active & {
    transform: translate(0, 0);
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing.Xxs};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.Xs};
  }
`;

export const Content = styled.div<{ $color: Colors; $isTextOutside: boolean }>`
  align-items: flex-start;
  color: ${({ $color, $isTextOutside }) => (!$isTextOutside ? $color : 'inherit')};
  display: flex;
  flex-direction: column;
  margin-top: ${({ $isTextOutside }) => ($isTextOutside ? Spacing.S : '0')};
  padding: ${({ $isTextOutside }) => ($isTextOutside ? 0 : `${Spacing.M} ${Spacing.Xs} ${Spacing.S}`)};

  ${StyledTextCTA} {
    ${StyledLink}:hover & > span, ${StyledLink}:focus-visible & > span {
      ${linkUnderlineHover};
    }
  }
`;

export const StyledChip = styled(Chip)`
  margin-bottom: ${Spacing.Xs};
`;

export const StyledText = styled(Text)`
  &:not(:last-child) {
    margin-bottom: ${Spacing.Xs};
  }
`;

export const StyledButton = styled(TextCTA)``;
