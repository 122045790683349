import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { ExtendedTemplates } from '@lichtblick/contentful/components/templates';

import manifest from '../public/manifest.json';

export const BASE_URL = 'https://www.lichtblick.de';

type PageHeadProps = {
  story?: ExtendedTemplates;
};

export const PageHead: FC<PageHeadProps> = ({ story }) => {
  const { asPath } = useRouter();
  const canonical = BASE_URL + asPath;
  const title = `${story?.metaTitle || manifest?.name || 'LichtBlick'} | LichtBlick`;
  const author = story?.component === 't-article' ? story.stage?.[0].author?.[0]?.content : undefined;

  return (
    <Head>
      <title>{title}</title>
      {author && <meta content={author.name} name="author" />}
      {story && 'metaDescription' in story && <meta content={story.metaDescription} name="description" />}
      {story && 'metaRobots' in story && <meta content={story.metaRobots} name="robots" />}
      <meta content={title} property="og:title" />
      <meta content={manifest.name} property="og:site_name" />
      <meta content="website" property="og:type" />
      <meta content={canonical} property="og:url" />
      {story && 'metaDescription' in story && <meta content={story.metaDescription} property="og:description" />}
      {story && 'sharingImage' in story && (
        <>
          <meta content={story.sharingImage?.filename} property="og:image" />
          <meta content={story.sharingImage?.filename} property="og:image:secure_url" />
        </>
      )}
      <meta content="width=device-width,minimum-scale=1,initial-scale=1" name="viewport" />
      <meta content={manifest.theme_color} name="theme-color" />
      <meta content={manifest.background_color} name="msapplication-TileColor" />
      <meta content={process.env.COMMIT_HASH} name="site-tag" />
      <meta content="telephone=no, date=no, email=no, address=no" name="format-detection" />
      <link crossOrigin="" href="//www.googletagmanager.com" rel="preconnect" />
      <link crossOrigin="" href="//az416426.vo.msecnd.net" rel="preconnect" />
      <link crossOrigin="" href="//api.usercentrics.eu" rel="preconnect" />
      <link href="//api.usercentrics.eu" rel="preconnect" />
      <link as="script" href="//app.usercentrics.eu/browser-ui/latest/loader.js" rel="preload" />
      <link crossOrigin="" href="//a.storyblok.com" rel="preconnect" />
      <link crossOrigin="" href="//fast.fonts.net" rel="preconnect" />
      <link crossOrigin="use-credentials" href="/manifest.json" rel="manifest" />
      <link href={canonical} rel="canonical" />
      <link href="/assets/images/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
      <link href="/assets/images/favicon.svg" rel="icon" type="image/svg+xml" />
      <link href="/assets/images/apple-touch-icon.png" rel="apple-touch-icon" sizes="180x180" />
    </Head>
  );
};
